import React, { type FC, type PropsWithChildren, useCallback, useState } from 'react'

import { PageNumberIndication } from '@component/PageNumberIndication'
import { getPageAndSection } from '@pages/Landing/types'
import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types'

import { LandingContainerWithImageStyled } from './styled'
import { type LandingContainerWithImageProps } from './types'

export const LandingContainerWithImage: FC<PropsWithChildren<LandingContainerWithImageProps>> = (
  {
    children,
    step,
    description,
    headerText,
    imageSource,
    previewSource,
  }) => {
  const [loading, setLoading] = useState(true)
  const [previewLoading, setPreviewLoading] = useState(true)
  const onImageLoad = useCallback(() => {
    setLoading(false)
  }, [])
  const onPreviewLoad = useCallback(() => {
    setPreviewLoading(false)
  }, [])
  return (
    <LandingContainerWithImageStyled.Main
      direction={FlexDirection.COLUMN}
      alignItems={FlexAlignItems.CENTER}
    >
      <LandingContainerWithImageStyled.Body
        direction={FlexDirection.COLUMN}
        alignItems={FlexAlignItems.CENTER}
        justifyContent={FlexJustifyContent.START}
      >
        <LandingContainerWithImageStyled.Header>
          {headerText}
        </LandingContainerWithImageStyled.Header>
        {description && (
          <LandingContainerWithImageStyled.Description>
            {description}
          </LandingContainerWithImageStyled.Description>
        )}
        <LandingContainerWithImageStyled.ImageWrapper>
          <LandingContainerWithImageStyled.Image
            alt="LandingPagePreviewImage"
            src={previewSource}
            onLoad={onPreviewLoad}
            $show={loading}
          />
          {!previewLoading &&
            <LandingContainerWithImageStyled.Image
              alt="LandingPageImage"
              src={imageSource}
              onLoad={onImageLoad}
              $show={!loading}
            />
          }
        </LandingContainerWithImageStyled.ImageWrapper>
        {children && children}
      </LandingContainerWithImageStyled.Body>
      <PageNumberIndication
        currentPage={getPageAndSection(step)?.page}
        totalPages={getPageAndSection(step)?.totalPages}
      />
    </LandingContainerWithImageStyled.Main>
  )
}
