import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { Chat } from '@pages/Chat'
import { ChatMock } from '@pages/Chat/pages'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const chatRoutes: RouteObject[] = [
  {
    path: ROUTES.CHAT.ROOT,
    element: (
      <ProtectedRoute>
        <Chat />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.CHAT.MOCK,
    element: (
      <ProtectedRoute>
        <ChatMock />
      </ProtectedRoute>
    ),
  },
]
