import React, { type FC, useEffect, useState } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useAPI } from '@hooks/useAPI'
import { ErrorId } from '@hooks/useAPI/types'
import { useGoBack } from '@hooks/useGoBack'
import { useInterval } from '@hooks/useInterval'
import { generalProfileInfo } from '@lib/api/rest/profile'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import { Spacer, Text } from '@uiKit'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'
import { useTheme } from 'styled-components'

import { StyledSubsetNotProcessed } from './styled'

export const SubsetNotProcessed: FC = () => {
  const { goBack } = useGoBack()

  const theme = useTheme()
  const [processedTracks, setProcessedTracks] = useState(0)
  const [maxTracks, setMaxTracks] = useState(0)
  const {
    runRequest: requestBestMatches,
    data,
    isError,
    errorData,
    errorId,
  } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo,
    ignoredErrors: [
      ErrorId.TRACKS_MOOD_NOT_READY_YET,
      ErrorId.TRACKS_NOT_READY_YET,
    ],
  }, {
    runOnMount: true
  })

  interface BestMatchesErrorResponse {
    [key: string]: any
    tracks_processed?: number
    tracks_mood_processed?: number
    max_tracks_mood?: number
    max_tracks?: number
  }

  useInterval(() => { requestBestMatches() }, 5000)

  useEffect(() => {
    if (isError) {
      if (
          errorId === ErrorId.TRACKS_MOOD_NOT_READY_YET ||
          errorId === ErrorId.TRACKS_NOT_READY_YET
      ) {
        const errorDataCasted = errorData as BestMatchesErrorResponse
        setProcessedTracks(errorDataCasted.tracks_processed ??
          errorDataCasted.tracks_mood_processed ?? 0)
        setMaxTracks(errorDataCasted.max_tracks ??
          errorDataCasted.max_tracks_mood ?? 0)
      }
    } else if (data) {
      goBack()
    }
  }, [isError, errorData, data])

  return (
    <AppLayout
      header={<LogoHeader showSecondButton />}
      navbar={<Navbar />}
    >
      <StyledSubsetNotProcessed.Card showBackground padding={24} fullHeight>
        <StyledSubsetNotProcessed.CircularProgressbarWrapper>
          <StyledSubsetNotProcessed.CircularProgressbar
            value={processedTracks}
            strokeWidth={12}
            maxValue={maxTracks}
          >
            <Text size={TextSize.TITLE3_20} color={theme.colors.base.main}>
              {processedTracks}/{maxTracks}
            </Text>
          </StyledSubsetNotProcessed.CircularProgressbar>
        </StyledSubsetNotProcessed.CircularProgressbarWrapper>
        <Spacer space={theme.spacings.x8} />
        <Text
          fontWeight={700}
          size={TextSize.TITLE2_22}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.85)'}
        >
          Your riffs are being processed...
        </Text>
        <Spacer space={theme.spacings.x8} />
        <Text
          size={TextSize.SUBHEADLINE_15}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.65)'}
        >
          It will take a few seconds
        </Text>
      </StyledSubsetNotProcessed.Card>
    </AppLayout>
  )
}
