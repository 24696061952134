import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { NoReflection } from '@pages/Error/pages/NoReflection'
import { NoUsersForMatching } from '@pages/Error/pages/NoUsersForMatching'
import { Page404 } from '@pages/Error/pages/Page404'
import { Page500 } from '@pages/Error/pages/Page500'
import { SubsetNotComplete } from '@pages/Error/pages/SubsetNotFull'
import { SubsetNotProcessed } from '@pages/Error/pages/SubsetNotProcessed'
import { TrackWasDeleted } from '@pages/Error/pages/TrackWasDeleted'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const errorRoutes: RouteObject[] = [
  {
    path: ROUTES.ERROR.PAGE404,
    element: (
      <ProtectedRoute>
        <Page404 />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.SUBSET_NOT_COMPLETE,
    element: (
      <ProtectedRoute>
        <SubsetNotComplete />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.SUBSET_NOT_PROCESSED,
    element: (
      <ProtectedRoute>
        <SubsetNotProcessed />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.PAGE500,
    element: (
      <ProtectedRoute>
        <Page500 />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.TRACK_WAS_DELETED,
    element: (
      <ProtectedRoute>
        <TrackWasDeleted />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.NO_REFLECTION,
    element: (
      <ProtectedRoute>
        <NoReflection />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR.NO_USERS_FOR_MATCHING,
    element: (
      <ProtectedRoute>
        <NoUsersForMatching />
      </ProtectedRoute>
    ),
  },
]
