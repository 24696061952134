export enum TrackStatus {
  EMPTY,
  PLAY,
  PAUSE,
}

export interface AudioCardImageProps {
  src?: string
  alt?: string
  trackStatus: TrackStatus
  margin?: number
}
