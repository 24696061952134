import React, { useState, type FC } from 'react'

import { Header } from '@component/Header'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Spacer, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { TracksType } from '../types'

import { SearchMusicCard } from './components'

export const SearchTracksMood: FC = () => {
  const { redirect } = useRedirect()
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }
  const [isSubsetFull, setIsSubsetFull] = useState(false)
  return (
    <AppLayout
      header={
        <Header
          title={'Mood in Music'}
          showLeftButton
          leftButton={{
            icon: Images.INFO,
            onClick: () => { redirect(ROUTES.TRACKS.SEARCH.INFO_MOOD) },
          }}
          showRightButton
          rightButton={{
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => {
              if (isSubsetFull) {
                redirect(ROUTES.PROFILE.ROOT)
              } else {
                setOpen(prev => !prev)
              }
            },
          }}
        />
      }
    >
      <StyledPopup.Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        position="right center"
      >
        <PopupInner
          title="Quit?"
          closeModal={closeModal}
          onButtonClick={() => { redirect(ROUTES.PROFILE.ROOT) }}
          showButton
        >
          <Spacer space={16} />
          <Text size={TextSize.SUBHEADLINE_15} color={'#5D5D5D'}>
            Without choosing 3 Mood riffs you will not be able to match by Mood.
            <br /><br />
            Already chosen riffs will not be removed.
          </Text>
          <Spacer space={16} />
        </PopupInner>
      </StyledPopup.Popup>
      <SearchMusicCard
        isSubsetFull={isSubsetFull}
        setIsSubsetFull={setIsSubsetFull}
        tracksType={TracksType.MOOD}
        title={'I feel like: ♪️'}
      />
    </AppLayout>
  )
}
