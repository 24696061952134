import { createContext } from 'react'

import { type AudioListContextData } from './types'

export const AudioListContext = createContext<AudioListContextData>({
  onPause: () => null,
  onPlay: () => null,
  pauseAllAudios: () => null,
  resetAllAudios: () => null,
})
