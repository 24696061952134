import React, { type FC, useEffect, useState, useCallback } from 'react'

import { FilterPopup } from '@component/FilterPopup'
import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { bestMatches, match } from '@lib/api/rest/matching'
import {
  type BestMatchesRequest,
  type BestMatchesResponse
} from '@lib/api/rest/matching/bestMatches/types'
import { getCRT } from '@lib/api/rest/matching/CRT'
import { type CRTRequest, type CRTResponse } from '@lib/api/rest/matching/CRT/types'
import { like } from '@lib/api/rest/matching/like'
import { type LikeRequest, type LikeResponse } from '@lib/api/rest/matching/like/types'
import { type MatchRequest, type MatchResponse, } from '@lib/api/rest/matching/match/types'
import { TracksType } from '@pages/Tracks/pages/types'
import { ROUTES } from '@router/constants'
import { Images } from '@uiKit/Icon/constants/images'
import { AppLayout } from 'layouts/AppLayout'
import { useCollapse } from 'react-collapsed'
import { useSearchParams } from 'react-router-dom'

import { LikeToolbar } from './components'
import { MatchingCard } from './components/MatchingCard'

export const Matching: FC = () => {
  const { redirect } = useRedirect()
  const setSearchParams = useSearchParams()[1]
  const [partnerIndex, setPartnerIndex] = useState(-1)

  const [isFilterLuscher, setIsFilterLuscher] = useState(false)
  const [isFilterBig5, setIsFilterBig5] = useState(false)
  const [isFilterReflection, setIsFilterReflection] = useState(false)
  const [isFilterTracksMood, setIsFilterTracksMood] = useState(false)
  const [isMatchByMood, setIsMatchByMood] = useState(TracksType.CHARACTER)

  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }

  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded
  })
  const { getCollapseProps: getCollapsePropsWhenCollapsed } = useCollapse({
    isExpanded: !isExpanded
  })

  const {
    data: dataBestMatches,
    runRequest: runRequestBestMatches,
  } = useAPI<BestMatchesRequest, BestMatchesResponse>({
    apiService: bestMatches,
    requestData: {
      sort_by: isMatchByMood,
      filter_by_luscher_test: isFilterLuscher,
      filter_by_big5_test: isFilterBig5,
      filter_by_reflection: isFilterReflection,
      filter_by_tracks_mood: isFilterTracksMood,
    },
  })

  const {
    data: dataCRT,
    isLoading: isLoadingCRT,
    runRequest: runRequestCRT,
  } = useAPI<CRTRequest, CRTResponse>({
    apiService: getCRT
  })

  const {
    data: dataMatch,
    isLoading: isLoadingMatch,
    runRequest: runRequestMatch,
  } = useAPI<MatchRequest, MatchResponse>({
    apiService: match
  })

  const {
    data: dataLike,
    isLoading: isLoadingLike,
    runRequest: runRequestLikeCandidate,
  } = useAPI<LikeRequest, LikeResponse>({
    apiService: like
  })

  useEffect(() => {
    if (dataBestMatches) {
      if (dataBestMatches.candidates_ids.length === 0) {
        redirect(ROUTES.ERROR.NO_USERS_FOR_MATCHING)
      }
      updateCandidateIndex(0)
    }
  }, [dataBestMatches])

  useEffect(() => {
    if (dataLike && dataBestMatches) {
      updateCandidateIndex(partnerIndex + 1)
      setIsExpanded(false)
    }
  }, [dataLike])

  const onDislike = useCallback(() => {
    if (dataBestMatches) {
      updateCandidateIndex(partnerIndex + 1)
      setIsExpanded(false)
    }
  }, [dataBestMatches, partnerIndex])

  const onLike = useCallback(() => {
    runRequestLikeCandidate({
      candidate_id: dataBestMatches?.candidates_ids[partnerIndex] ?? -1,
    })
    setIsExpanded(false)
  }, [dataBestMatches, partnerIndex])

  const onCollapse = useCallback(() => {
    if (!isExpanded) {
      runRequestCRT({
        candidate_id: dataBestMatches?.candidates_ids[partnerIndex] ?? -1,
      })
    }
    setIsExpanded((prev) => !prev)
  }, [partnerIndex, dataBestMatches])

  function updateCandidateIndex(nextPartnerIndex: number) {
    if (!dataBestMatches) return

    setPartnerIndex(nextPartnerIndex)

    if (nextPartnerIndex === dataBestMatches.candidates_ids.length) {
      runRequestBestMatches({
        sort_by: isMatchByMood,
        filter_by_luscher_test: isFilterLuscher,
        filter_by_big5_test: isFilterBig5,
        filter_by_reflection: isFilterReflection,
        filter_by_tracks_mood: isFilterTracksMood,
      })
      return
    }
    setSearchParams({ candidate_id: dataBestMatches.candidates_ids[nextPartnerIndex].toString() })
    runRequestMatch({
      candidate_id: dataBestMatches?.candidates_ids[nextPartnerIndex] ?? -1,
    })
  }

  return (
    <AppLayout
      header={
        <LogoHeader showSecondButton
          secondButton={{
            icon: Images.FILTER,
            color: '#565656',
            onClick: () => { setOpen(prev => !prev) }
          }}
        />
      }
      navbar={
        !isExpanded ? <Navbar /> : (
          <LikeToolbar
            onDislike={onDislike}
            onLike={onLike}
            getToggleProps={getToggleProps}
            onCollapse={onCollapse}
            isExpanded={isExpanded}
          />
        )
      }
    >
      <FilterPopup
        onDataTestsRequestEnd={(
          sortBy: TracksType,
          isFilterLuscher: boolean,
          isFilterBig5: boolean,
          isFilterReflection: boolean,
          isFilterByTracksMood: boolean,
        ) => {
          runRequestBestMatches({
            sort_by: sortBy,
            filter_by_luscher_test: isFilterLuscher,
            filter_by_big5_test: isFilterBig5,
            filter_by_reflection: isFilterReflection,
            filter_by_tracks_mood: isFilterByTracksMood,
          })
        }}

        isFilterLuscher={isFilterLuscher}
        isFilterBig5={isFilterBig5}
        isFilterReflection={isFilterReflection}
        sortBy={isFilterTracksMood}
        isMatchByMood={isMatchByMood}

        setIsFilterLuscher={setIsFilterLuscher}
        setIsFilterBig5={setIsFilterBig5}
        setIsFilterReflection={setIsFilterReflection}
        setIsFilterTracksMood={setIsFilterTracksMood}
        setSortBy={setIsMatchByMood}

        open={open}
        onClose={() => {
          runRequestBestMatches({
            sort_by: isMatchByMood,
            filter_by_luscher_test: isFilterLuscher,
            filter_by_big5_test: isFilterBig5,
            filter_by_reflection: isFilterReflection,
            filter_by_tracks_mood: isFilterTracksMood,
          })
          closeModal()
        }}
        closeModal={closeModal}
      />
      <MatchingCard
        onCollapse={onCollapse}
        onLike={onLike}
        onDislike={onDislike}

        dataMatch={dataMatch}
        dataCRT={dataCRT}

        isLoadingMatch={isLoadingMatch}
        isLoadingCRT={isLoadingCRT}
        isLoadingLike={isLoadingLike}

        isExpanded={isExpanded}
        getCollapsePropsWhenCollapsed={getCollapsePropsWhenCollapsed}
        getCollapseProps={getCollapseProps}
        getToggleProps={getToggleProps}
      />
    </AppLayout>
  )
}
