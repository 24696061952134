import React, { type FC } from 'react'

import { Border } from '@component/Border'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { generalProfileInfo } from '@lib/api/rest/profile'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import { ROUTES } from '@router/constants'
import { Button, Flex, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexJustifyContent } from '@uiKit/Flex/types'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

import { StyledReflectionInfo } from './styled'

export const ReflectionInfo: FC = () => {
  const theme = useTheme()
  const {redirect} = useRedirect()

  const { data, isLoading } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo,
  }, {
    runOnMount: true
  })
  const isLuscherTestPassed = (data?.luscher_test_expires_in_seconds ?? 0) > 0
  const isBig5TestPassed = data?.is_big5_done
  const areTracksMoodFull = data?.are_tracks_mood_full

  return (
    <AppLayout
      header={
        <Header
          title={'Reflection'}
          showLeftButton
          showRightButton
          rightButton={{
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => { redirect(ROUTES.PROFILE.ROOT) }
          }}
        />
      }
      contentStyles={{paddingBottom: 90}}
    >
      <Card showBackground padding={24}>
        <Text size={TextSize.TITLE1_28} fontWeight={700} color={theme.colors.base.main}>
          Reflection
        </Text>
        <Spacer space={16} />
        <Text size={TextSize.FOOTNOTE_13} fontWeight={600} color={'#9B9B9B'}>
          Your Reflection is based on the following components.<br />
          Also, the Matching process is fundamentally driven by riffs, with tests to enhance it.
        </Text>
        <Spacer space={16} />

        <Border text={'Character'} >
          <StyledReflectionInfo.TestCard>
            <StyledReflectionInfo.InfoWrapper>
              <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                <StyledReflectionInfo.TestCardHeading
                  size={TextSize.BODY_17}
                  fontWeight={600}
                >
                  Riffs
                </StyledReflectionInfo.TestCardHeading>
                <StyledReflectionInfo.Label $isPassed={true}>
                  Done
                </StyledReflectionInfo.Label>
              </Flex>
              <Spacer space={8} />
              <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
                Based on your favorite riffs, our algorithms define your personality.
              </Text>
            </StyledReflectionInfo.InfoWrapper>
            <Spacer space={16} />
          </StyledReflectionInfo.TestCard>
          <Spacer space={8} />

          <StyledReflectionInfo.TestCard>
            <StyledReflectionInfo.InfoWrapper>
              <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                <StyledReflectionInfo.TestCardHeading
                  size={TextSize.BODY_17}
                  fontWeight={600}
                >
                  Big 5
                </StyledReflectionInfo.TestCardHeading>
                {isLoading && (<Skeleton width={65} height={24} />)}
                {!isLoading && (
                  <StyledReflectionInfo.Label $isPassed={isBig5TestPassed ?? false}>
                    {isBig5TestPassed ? 'Done' : 'Optional'}
                  </StyledReflectionInfo.Label>
                )}
              </Flex>
              <Spacer space={8} />
              <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
                This test evaluates your personality.
              </Text>
            </StyledReflectionInfo.InfoWrapper>
            <StyledReflectionInfo.ButtonWrapper $isVisible={!isLoading && !isBig5TestPassed}>
              <Spacer space={8} />
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => { redirect(ROUTES.BIG5.TEST) }}
              >
                Take test
              </Button>
            </StyledReflectionInfo.ButtonWrapper>
          </StyledReflectionInfo.TestCard>
        </Border>

        <Spacer space={24} />

        <Border text={'Mood'} >
          <StyledReflectionInfo.TestCard>
            <StyledReflectionInfo.InfoWrapper>
              <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                <StyledReflectionInfo.TestCardHeading
                  size={TextSize.BODY_17}
                  fontWeight={600}
                >
                  Riffs
                </StyledReflectionInfo.TestCardHeading>
                {isLoading && (<Skeleton width={65} height={24} />)}
                {!isLoading && (
                  <StyledReflectionInfo.Label $isPassed={areTracksMoodFull ?? false}>
                    {areTracksMoodFull ? 'Done' : 'Optional'}
                  </StyledReflectionInfo.Label>
                )}
              </Flex>
              <Spacer space={8} />
              <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
                Based on your recent riffs, our algorithms define your current mood.
              </Text>
            </StyledReflectionInfo.InfoWrapper>
            <StyledReflectionInfo.ButtonWrapper $isVisible={!isLoading && !areTracksMoodFull}>
              <Spacer space={8} />
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => { redirect(ROUTES.TRACKS.SEARCH.INFO_MOOD) }}
              >
                Complete riffs
              </Button>
            </StyledReflectionInfo.ButtonWrapper>
          </StyledReflectionInfo.TestCard>
          <Spacer space={8} />

          <StyledReflectionInfo.TestCard>
            <StyledReflectionInfo.InfoWrapper>
              <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
                <StyledReflectionInfo.TestCardHeading
                  size={TextSize.BODY_17}
                  fontWeight={600}
                >
                  Luscher
                </StyledReflectionInfo.TestCardHeading>
                {isLoading && (<Skeleton width={65} height={24} />)}
                {!isLoading && (
                  <StyledReflectionInfo.Label $isPassed={isLuscherTestPassed ?? false}>
                    {isLuscherTestPassed ? 'Done' : 'Optional'}
                  </StyledReflectionInfo.Label>
                )}
              </Flex>
              <Spacer space={8} />
              <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
                This test evaluates your current mood.
              </Text>
            </StyledReflectionInfo.InfoWrapper>
            <StyledReflectionInfo.ButtonWrapper $isVisible={!isLoading && !isLuscherTestPassed}>
              <Spacer space={8} />
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={() => { redirect(ROUTES.LUSCHER.TEST) }}
              >
                Take test
              </Button>
            </StyledReflectionInfo.ButtonWrapper>
          </StyledReflectionInfo.TestCard>
        </Border>

        <Spacer space={16} />
        <Button
          role="link"
          onClick={() => { redirect(ROUTES.REFLECTION.ROOT) }}
          variant={ButtonVariant.PRIMARY}
        >
          Continue
        </Button>
      </Card>
    </AppLayout>
  )
}
