import { Card as CardBase } from '@component/Card'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { styled } from 'styled-components'

const CircularProgressbarWrapper = styled.div`
  position: relative;
  width: 78px;
  padding: 22px;
  margin: 0 auto;
`

const CircularProgressbar = styled(CircularProgressbarWithChildren)`
  .CircularProgressbar-path {
    stroke: ${({ theme }) => theme.colors.base.main};
  }
  .CircularProgressbar-trail {
    stroke: ${({ theme }) => theme.colors.base.main_disabled}};
  }
`

const Card = styled(CardBase)`
  justify-content: center;
`

export const StyledSubsetNotProcessed = {
  CircularProgressbarWrapper,
  CircularProgressbar,
  Card,
}
