import { styled } from 'styled-components'

const Card = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: 8px;
`

export const StyledChatCard = {
  Card,
}
