import { type HTMLAttributes, type ReactNode } from 'react'

export enum FlexAlignItems {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  BASELINE = 'baseline',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
  SPACE_AROUND = 'space-around',
}

export enum FlexAlignContent {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  BASELINE = 'baseline',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
  SPACE_AROUND = 'space-around',
}

export enum FlexJustifyItems {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  BASELINE = 'baseline',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
  SPACE_AROUND = 'space-around',
}

export enum FlexJustifyContent {
  START = 'start',
  END = 'end',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  FLEX_START = 'flex-start',
  BASELINE = 'baseline',
  SPACE_BETWEEN = 'space-between',
  SPACE_EVENLY = 'space-evenly',
  SPACE_AROUND = 'space-around',
}

export enum FlexDirection {
  COLUMN = 'column',
  ROW = 'row',
}

export interface FlexProps extends HTMLAttributes<HTMLDivElement> {
  fullWidth?: boolean
  fullHeight?: boolean
  direction?: FlexDirection
  alignItems?: FlexAlignItems
  alignContent?: FlexAlignContent
  justifyItems?: FlexJustifyItems
  justifyContent?: FlexJustifyContent
  flex?: string
  children?: ReactNode
  gap?: number
}
