import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { AudioListContext } from './context'
import { type AudioListProviderData, TrackStatus } from './types'

export const AudioListProvider: FC<PropsWithChildren<AudioListProviderData>> = ({
    audios,
    resetAllOnPlay = true,
    volume = 0.5,
    children
}) => {
  const [audioObject, setAudioObject] =
    useState<Record<string, { trackStatus: TrackStatus }>>()

  useEffect(() => {
    if (audios) {
      const audioToObject = audios.reduce((prev, current) => {
        const src = current.src

        return {
          ...prev,
          [src]: { trackStatus: TrackStatus.EMPTY }
        }
      }, {})

      setAudioObject(audioToObject)
    }
  }, [audios])

  useEffect(() => {
    if (volume) {
      audios.forEach((audio) => {
        audio.volume = volume
      })
    }
  }, [audios, volume])

  const changePlaying = (src: string, trackStatus: TrackStatus) => {
    setAudioObject((prev) => {
      if (prev) {
        return {
          ...prev,
          [src]: {
            ...prev[src],
            trackStatus,
          },
        }
      }
    })
  }

  const onPlay = useCallback(
    (src: string) => {
      if (resetAllOnPlay) {
        resetAllAudios()
      }

      const foundAudio = audios.find((value) => value.src === src)

      if (foundAudio) {
        foundAudio.play()?.then(() => {
          changePlaying(src, TrackStatus.PLAY)
        }).catch(() => {})
      }
    },
    [audios]
  )

  const onPause = useCallback(
    (src: string, status: TrackStatus = TrackStatus.PAUSE) => {
      changePlaying(src, status)

      audios.find((value) => value.src === src)?.pause()
    },
    [audios]
  )

  const pauseAllAudios = useCallback(() => {
    audios.forEach((audio) => {
      onPause(audio.src, TrackStatus.EMPTY)
    })
  }, [audios, onPause])

  const resetAllAudios = useCallback(() => {
    audios.forEach((audio) => {
      audio.currentTime = 0
      onPause(audio.src, TrackStatus.EMPTY)
    })
  }, [audios, onPause])

  useEffect(() => {
    return () => {
      resetAllAudios()
    }
  }, [resetAllAudios])

  useEffect(() => {
    const onEnd = (src: string) => (e: Event) => {
      changePlaying(src, TrackStatus.PAUSE)
    }

    const onStart = (src: string) => (e: Event) => {
      changePlaying(src, TrackStatus.PLAY)
    }

    audios.forEach((audio) => {
      audio.addEventListener('ended', onEnd(audio.src))
      audio.addEventListener('play', onStart(audio.src))
      audio.addEventListener('onPause', onEnd(audio.src))
    })
    return () => {
      audios.forEach((audio) => {
        audio.removeEventListener('ended', onEnd(audio.src))
        audio.removeEventListener('play', onStart(audio.src))
        audio.removeEventListener('pause', onEnd(audio.src))
      })
    }
  }, [])

  const value = useMemo(
    () => ({
      onPlay,
      onPause,
      pauseAllAudios,
      resetAllAudios,
      audioObject
    }),
    [onPlay, onPause, pauseAllAudios, resetAllAudios, audioObject]
  )

  return (
    <AudioListContext.Provider value={value}>
      {children}
    </AudioListContext.Provider>
  )
}
