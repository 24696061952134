import { type FormEvent, useState, useMemo, useEffect } from 'react'

import { shuffle } from '@lib/shuffleArray'
import { LUSCHER_COLORS } from '@pages/Luscher/constants'

import { type UseLuscherTestStepData } from './types'

export const useLuscherTestStep = ({ onSubmit }: UseLuscherTestStepData) => {
  const [colors, setSelectedColors] = useState<
    Array<{ delta: number; color: number; timeStamp: number }>
  >([])

  const onChangeColor = (e: FormEvent<HTMLDivElement>, color: number) => {
    const delta =
      colors.length === 0
        ? Math.trunc(e.timeStamp)
        : Math.trunc(e.timeStamp) - colors[colors.length - 1].timeStamp

    setSelectedColors((prev) => [
      ...prev,
      { color, delta, timeStamp: Math.trunc(e.timeStamp) },
    ])
  }

  const shuffledColors = useMemo(() => {
    return shuffle(LUSCHER_COLORS)
  }, [])

  useEffect(() => {
    if (colors.length === 8) {
      onSubmit(colors.map(({ color, delta, ...rest }) => ({ color, delta })))
    }
  }, [colors])

  return { onChangeColor, shuffledColors }
}
