import { type HtmlHTMLAttributes } from 'react'

import { getTextSizeCSS } from '@uiKit/Text/styled'
import { type TextSize } from '@uiKit/Text/types'
import styled, { css } from 'styled-components'

import { type InputProps } from './types'

const Label = styled.label<{ $fullWidth?: boolean }>`
  display: inline-block;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
`

const Wrapper = styled.div<{ $fullWidth?: boolean }>`
  display: inline-flex;
  position: relative;
  min-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '200px')};
`

const IconWrapper = styled.div<{
  $dir: HtmlHTMLAttributes<HTMLDivElement>['dir']
}>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  ${({ $dir }) =>
    $dir === 'rtl'
      ? css`
        right: 0;
        padding-right: ${({ theme }) => `${theme.spacings.x20}`}px;
      `
      : css`
        padding-left: ${({ theme }) => `${theme.spacings.x20}`}px;
      `}
`

const Field = styled.input<{
  $textSize?: TextSize
  $fullWidth?: InputProps['fullWidth']
  $hasIcon?: boolean
  $dir?: HtmlHTMLAttributes<HTMLDivElement>['dir']
  $isError?: InputProps['isError']
}>`
  flex: 1;
  color: ${({ theme }) => theme.colors.base.dark};
  outline: none;
  padding: ${({$hasIcon, $dir}) =>
    $hasIcon
      ? $dir === 'rtl'
        ? '0 40px 0 20px'
        : '0 20px 0 40px'
      : '12px 36px 12px 24px'};
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.base.dark};
  ${({ $textSize }) => $textSize && getTextSizeCSS[$textSize]};
  min-height: 44px;
  border-radius: ${({ theme }) => theme.radiuses.lg}px;
  
  &:focus-visible {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.focus};
  }
  
  &::placeholder {
    color: #B7B7B7;
  }
  &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

const HelperText = styled.div`
  padding-left: 24px;
`

export const StyledInput = {
  Field,
  Wrapper,
  IconWrapper,
  Label,
  HelperText,
}
