import styled, { css } from 'styled-components'

import { type TextProps, TextSize } from './types'

export const getTextSizeCSS = {
  [TextSize.S1]: css`
    font-size: 0.75rem;
  `,
  [TextSize.S2]: css`
    font-size: 0.875rem;
  `,
  [TextSize.S3]: css`
    font-size: 1rem;
  `,
  [TextSize.M1]: css`
    font-size: 1.25rem;
  `,
  [TextSize.M2]: css`
    font-size: 1.5rem;
  `,
  [TextSize.M3]: css`
    font-size: 1.75rem;
  `,
  [TextSize.L1]: css`
    font-size: 2rem;
  `,
  [TextSize.L2]: css`
    font-size: 2.5rem;
  `,
  [TextSize.L3]: css`
    font-size: 3rem;
  `,
  [TextSize.LARGE_TITLE_34]: css`
    font-size: 34px;
    letter-spacing: 0.37px;
    line-height: 41px;
  `,
  [TextSize.TITLE1_28]: css`
    font-size: 28px;
    letter-spacing: 0.36px;
    line-height: 34px;
  `,
  [TextSize.TITLE2_22]: css`
    font-size: 22px;
    letter-spacing: 0.35px;
    line-height: 28px;
  `,
  [TextSize.TITLE3_20]: css`
    font-size: 20px;
    letter-spacing: 0.38px;
    line-height: 24px;
  `,
  [TextSize.BODY_17]: css`
    font-size: 17px;
    letter-spacing: -0.41px;
    line-height: 22px;
  `,
  [TextSize.CALLOUT_16]: css`
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  `,
  [TextSize.SUBHEADLINE_15]: css`
    font-size: 15px;
    letter-spacing: -0.24px;
    line-height: 20px;
  `,
  [TextSize.FOOTNOTE_13]: css`
    font-size: 13px;
    letter-spacing: -0.08px;
    line-height: 18px;
  `,
  [TextSize.CAPTION1_12]: css`
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  `,
  [TextSize.CAPTION2_11]: css`
    font-size: 11px;
    letter-spacing: 0.07px;
    line-height: 13px;
  `,
}

const Main = styled.p<{
  $size?: TextProps['size']
  $fontWeight?: TextProps['fontWeight']
  $color?: TextProps['color']
  $align?: TextProps['align']
}>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: 0;
  color: ${({ $color }) => $color && $color};
  text-align: ${({ $align }) => $align && $align};
  ${({ $size }) => $size && getTextSizeCSS[$size]};
`

export const StyleText = {
  Main,
}
