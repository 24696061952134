export enum Images {
  SEARCH,
  ADD,
  DELETE,
  ARROW_DOWN,
  BACK,
  CHAT,
  NEXT,
  PAUSE,
  RELOAD,
  PLAY,
  SETTINGS,
  USERS,
  DISLIKE,
  LIKE,
  GEAR,
  MENU,
  MATCHING,
  TRACK_MENU,
  SEARCH_SIMILAR_SONGS,
  SPOTIFY_MUSIC,
  APPLE_MUSIC,
  YT_MUSIC,
  LINKPAGE,
  INFO,
  ARROW_DOWN_COLLAPSE_MENU,
  ARROW_UP_COLLAPSE_MENU,
  SPOTIFY_MUSIC_GREEN,
  CROSS,
  ARROW_RIGHT,
  ARROW_RIGHT_SYMBOL,
  ARROW_LEFT_SYMBOL,
  SWIPING,
  NOTE,
  COLORS,
  BRAIN,
  PROFILE,
  SQUIRCLE,
  EDIT,
  MENU2,
  EXIT,
  PICTURE,
  FIREWORKS,
  HEART_WITH_ARROW,
  SPEECH,
  WAVING_HAND,
  ARROW_DOWN_FUSE,
  CROSS_BOLD,
  ARROW_UP,
  WARNING,
  EYE,
  CROSS_SEMIBOLD,
  ARROW_RELOAD,
  ARIES,
  TAURUS,
  GEMINI,
  CANCER,
  LEO,
  VIRGO,
  LIBRA,
  SCORPIO,
  SAGITTARIUS,
  CAPRICORN,
  AQUARIUS,
  PISCES,
  QUESTION,
  CHECK_CIRCLE,
  TRASH,
  HOME,
  FILTER,
  CROSSED_EYE,
}
