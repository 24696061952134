import { type HTMLAttributes, type ReactNode } from 'react'

export enum TextAlign {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

export enum TextSize {
  S1 = 1,
  S2 = 2,
  S3 = 3,
  M1 = 4,
  M2 = 5,
  M3 = 6,
  L1 = 7,
  L2 = 8,
  L3 = 9,
  LARGE_TITLE_34,
  TITLE1_28,
  TITLE2_22,
  TITLE3_20,
  BODY_17,
  CALLOUT_16,
  SUBHEADLINE_15,
  FOOTNOTE_13,
  CAPTION1_12,
  CAPTION2_11,
}

export interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  size?: TextSize
  color?: string
  fontWeight?: number
  children: ReactNode
  align?: TextAlign
}
