import React, { type PropsWithChildren, type FC } from 'react'

import { type TextProps } from '@uiKit/Text/types'

import { StyledTextOVerflow } from './styled'
import { type TextOverflowProps } from './types'

export const TextOverflow: FC<
  PropsWithChildren<TextOverflowProps & TextProps>
> = ({ children, width, ...props }) => {
  return (
    <StyledTextOVerflow.Container $width={width} {...props}>
      {children}
    </StyledTextOVerflow.Container>
  )
}
