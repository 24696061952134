import React, { forwardRef, useMemo } from 'react'

import { type SpacerProps } from './types'

export const Spacer = forwardRef<HTMLDivElement, SpacerProps>(({ space }, ref) => {
    const style = useMemo(
      () => ({
        height: space,
        width: space,
        minWidth: space,
        minHeight: space,
      }),
      [space]
    )

    return <div ref={ref} aria-hidden="true" style={style} />
  }
)

Spacer.displayName = 'Spacer'
