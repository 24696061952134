import React, { type FC } from 'react'

import { StyledBig5Slider } from './styled'
import { type Big5SliderProps } from './types'

export const Big5Slider: FC<Big5SliderProps> = (
  {
    point1,
    point2,
  }) => {
  point1.value = point1.value * 100
  if (point2) {
    point2.value = point2.value * 100
  }

  if (point2 && point1.value > point2.value) {
    const temp = point1
    point1 = point2
    point2 = temp
  }
  if (point2 && point1.value === point2.value) {
    if (point1.value < 10) {
      point1.value += 10
    } else {
      point1.value -= 10
    }
  }
  if (point1.value === 100) {
    point1.value -= 5
  }
  if (point2 && point2.value === 100) {
    point2.value -= 5
  }
  return (
    <StyledBig5Slider.Wrapper>
      {point2 ? (
        <>
          <StyledBig5Slider.Cover $left={0} $width={point1.value} />
          <StyledBig5Slider.Point
            $left={point1.value}
            src={point1.img ? `data:image/png;base64,${point1.img}` : undefined}
          />
          <StyledBig5Slider.Point
            $left={point2.value}
            src={point2.img ? `data:image/png;base64,${point2.img}` : undefined}
          />
          <StyledBig5Slider.Cover $left={point2.value} $width={100 - point2.value} />
        </>
      ) : (
        <>
          <StyledBig5Slider.Point
            $left={point1.value}
            src={point1.img ? `data:image/png;base64,${point1.img}` : undefined}
          />
          <StyledBig5Slider.Cover $left={point1.value} $width={100 - point1.value + 1} />
        </>
      )}
    </StyledBig5Slider.Wrapper>
  )
}
