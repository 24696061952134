import React, { forwardRef } from 'react'

import { useTheme } from 'styled-components'

import { Spacer } from '../Spacer'
import { Text } from '../Text'
import { TextSize } from '../Text/types'

import { StyledTextArea } from './styled'
import { type InputProps } from './types'

export const TextArea = forwardRef<HTMLLabelElement, InputProps>(({
    icon,
    label,
    fullWidth = true,
    placeholder,
    dir = 'ltr',
    value,
    isError = false,
    helperText,
    ...props
}, ref) => {
  const theme = useTheme()

  const iconComponent = (
    <StyledTextArea.IconWrapper $dir={dir}>{icon}</StyledTextArea.IconWrapper>
  )

  return (
    <StyledTextArea.Label ref={ref} $fullWidth={fullWidth}>
      {label && (
        <>
          <Text size={TextSize.TITLE3_20} fontWeight={500}>
            {label}
          </Text>
          <Spacer space={4} />
        </>
      )}
      <StyledTextArea.Wrapper $fullWidth={fullWidth}>
        {icon && iconComponent}
        <StyledTextArea.Field
          value={value}
          $hasIcon={icon != null}
          placeholder={placeholder}
          $textSize={TextSize.SUBHEADLINE_15}
          $dir={dir}
          $fullWidth={fullWidth}
          $isError={isError}
          {...props}
        />
      </StyledTextArea.Wrapper>
      {helperText && (
        <StyledTextArea.HelperText>
          <Spacer space={4} />
          <Text
            size={TextSize.CAPTION1_12}
            color={
              isError ? theme.colors.base.danger : theme.colors.base.dark
            }
          >
            {helperText}
          </Text>
        </StyledTextArea.HelperText>
      )}
    </StyledTextArea.Label>
  )
})

TextArea.displayName = 'TextArea'
