import React, { type FC } from 'react'

import { type LogoProps } from '@component/Logo/types'

import { StyledLogo } from './styled'

export const Logo: FC<LogoProps> = ({
  alternativeText,
  logoPath,
  width,
  height,
}) => {
  return (
    <StyledLogo.Image
      $width={width}
      $height={height}
      alt={alternativeText && alternativeText}
      src={logoPath && logoPath}
    />
  )
}
