import React, { type FC, useEffect, useCallback } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { AppLayout } from '@layouts/AppLayout'
import { match } from '@lib/api/rest/matching'
import { getCRT } from '@lib/api/rest/matching/CRT'
import { type CRTRequest, type CRTResponse } from '@lib/api/rest/matching/CRT/types'
import { like } from '@lib/api/rest/matching/like'
import { type LikeRequest, type LikeResponse } from '@lib/api/rest/matching/like/types'
import { type MatchRequest, type MatchResponse, } from '@lib/api/rest/matching/match/types'
import { LikeToolbar, MatchingCandidateCard } from '@pages/Matching/components'
import { ROUTES } from '@router/constants'
import { Images } from '@uiKit/Icon/constants/images'
import { useSearchParams } from 'react-router-dom'

export const MatchingCandidate: FC = () => {
  const { redirect } = useRedirect()
  const [searchParams] = useSearchParams()
  const partnerId = parseInt(searchParams.get('candidate_id') ?? '-1')

  const {
    data: dataMatch,
    isLoading: isLoadingMatch,
  } = useAPI<MatchRequest, MatchResponse>({
    apiService: match,
    requestData: { candidate_id: partnerId }
  }, {
    runOnMount: true
  })

  const {
    data: dataCRT,
    isLoading: isLoadingCRT,
    runRequest: runRequestCRT,
  } = useAPI<CRTRequest, CRTResponse>({
    apiService: getCRT
  })

  const {
    data: dataLike,
    runRequest: runLikeCandidateRequest,
  } = useAPI<LikeRequest, LikeResponse>({
    apiService: like
  })

  useEffect(() => {
    if (dataMatch) {
      runRequestCRT({ candidate_id: partnerId })
    }
  }, [dataMatch])

  useEffect(() => {
    if (dataLike) {
      redirect(ROUTES.CHAT.ROOT)
    }
  }, [dataLike])

  const onLike = useCallback(() => {
    runLikeCandidateRequest({
      candidate_id: partnerId,
    })
  }, [partnerId])

  return (
    <AppLayout
      header={
        <LogoHeader
          showSecondButton
          secondButton={{
            icon: Images.CROSS_SEMIBOLD,
            color: '#B2B2B2',
            onClick: () => { redirect(ROUTES.CHAT.ROOT) }
          }}
        />
      }
      navbar={
        (!dataMatch?.is_liked && !isLoadingMatch) ? (
          <LikeToolbar
            onDislike={() => { redirect(ROUTES.CHAT.ROOT) }}
            dislikeButtonText={'Back'}
            showCollapseButton={false}
            onLike={onLike}
          />
        ) : undefined
      }
    >
      <MatchingCandidateCard
        dataMatch={dataMatch}
        dataCRT={dataCRT}
        isLoadingMatch={isLoadingMatch}
        isLoadingCRT={isLoadingCRT}
      />
    </AppLayout>
  )
}
