import { theme } from '@lib/theme'
import { Icon as IconBase, Text as TextBase } from '@uiKit'
import { StyleText } from '@uiKit/Text/styled'
import { css, styled } from 'styled-components'

const Item = styled.div<{ $isLast?: boolean }>`
  display: flex;
  gap: 8px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.base.light};
  ${({ $isLast }) => (
    $isLast
      ? css`
          border-radius: 0 0 24px 24px;
          border-bottom: 1px solid ${({ theme }) => theme.colors.base.dark};
          border-right: 1px solid ${({ theme }) => theme.colors.base.dark};
          border-left: 1px solid ${({ theme }) => theme.colors.base.dark};
        `
      : css`
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 0;
          border-right: 1px solid ${({ theme }) => theme.colors.base.dark};
          border-left: 1px solid ${({ theme }) => theme.colors.base.dark};
        `
  )};
`

const Icon = styled(IconBase).attrs({
  color: theme.colors.base.dark
})``

const Text = styled(TextBase).attrs({
  color: theme.colors.base.dark
})``

const Heading = styled.div<{ $isExpanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 24px;
  transition: 0.5s;

  ${({ $isExpanded }) => (
    $isExpanded
      ? css`
          background: ${({ theme }) => theme.colors.base.main};
          border-radius: 24px 24px 0 0;
          color: ${({ theme }) => theme.colors.base.light};
          border: 1px solid ${({ theme }) => theme.colors.base.dark};
          border-bottom: none;
        `
      : css`
          background: ${({ theme }) => theme.colors.base.light};
          border-radius: 24px;
          border: 1px solid ${({ theme }) => theme.colors.base.dark};
          color: #A2A2A2;
        `
  )};
  ${StyleText.Main} {
    flex: 1;
  }
`

export const StyledZodiac = {
  Item,
  Icon,
  Text,
  Heading,
}
