import { Text } from '@uiKit'
import { css, styled } from 'styled-components'
import { contentOverflowStyles } from 'styles/contentOverflow'

import { type TextOverflowProps } from './types'

const Container = styled(Text)<{ $width: TextOverflowProps['width'] }>`
  ${contentOverflowStyles}

  ${({ $width }) =>
    $width &&
    css`
    width: {$width}px;
  `}
`

export const StyledTextOVerflow = {
  Container,
}
