import { Flex } from '@uiKit'
import { styled } from 'styled-components'

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding-right: 8px;
  border-radius: ${({ theme }) => theme.radiuses.df}px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  position: relative;
`

const ContentWrapper = styled(Flex)`
  overflow: hidden;
`

export const StyledAudioCard = {
  Card,
  ContentWrapper,
}
