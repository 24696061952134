import { IconButton } from '@uiKit'
import { styled } from 'styled-components'

const Header = styled.header<{ $showSecondButton: boolean }>`
  display: flex;
  justify-content: ${({ $showSecondButton }) => ($showSecondButton ? 'space-between' : 'center')};
  position: relative;
  height: 97px;
  padding: 0 16px;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  box-sizing: border-box;
  margin: 0 auto;
`

const SecondButton = styled(IconButton)`
  padding: 12px;
  border-radius: 47px;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.90) 1.15%,
    rgba(255, 255, 255, 0.79) 98.91%
  );
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

export const StyledLogoHeader = {
  Header,
  SecondButton,
}
