import React, { type FC, type PropsWithChildren } from 'react'

import { PageNumberIndication } from '@component/PageNumberIndication'
import { SPACINGS } from '@lib/theme/constants'
import { getPageAndSection } from '@pages/Landing/types'
import { Spacer } from '@uiKit'
import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types'

import { LandingContainerWithTextStyled } from './styled'
import { type LandingContainerWithTextProps } from './types'

export const LandingContainerWithText: FC<PropsWithChildren<LandingContainerWithTextProps>> = (
  {
    children,
    step,
    headerText,
  }) => {
  return (
    <LandingContainerWithTextStyled.Main
      direction={FlexDirection.COLUMN}
      alignItems={FlexAlignItems.CENTER}
      justifyContent={FlexJustifyContent.START}
    >
      <LandingContainerWithTextStyled.Header>{headerText}</LandingContainerWithTextStyled.Header>
      <Spacer space={SPACINGS.X16} />
      {children && children}
      <PageNumberIndication
        currentPage={getPageAndSection(step)?.page}
        totalPages={getPageAndSection(step)?.totalPages}
      />
    </LandingContainerWithTextStyled.Main>
  )
}
