import { Button as ButtonBase, Flex } from '@uiKit'
import { styled } from 'styled-components'

const Button = styled(ButtonBase)`
  background: unset;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  height: 49px;
  letter-spacing: -0.24px;
  flex: 1 0 0;
  margin-top: 2px;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
  
  &:disabled {
    background: unset;
    color: transparent;
    cursor: unset;
  }
`

const ButtonBack = styled(Button)`
  color: ${({ theme }) => theme.colors.landing.inactive};
`

const ButtonNext = styled(Button)`
  color: ${({ theme }) => theme.colors.base.light};
  z-index: 2;
`

const Header = styled(Flex)`
  width: 100%;
  min-height: 52px;
`

const Logo = styled.img<{
  $center: boolean
}>`
  margin: ${({$center}) =>
          $center ? 'auto' : '14px 0 14px 16px'
  };
  height: 24px;
`

// const LogoText = styled(Text)<{
//   $center: boolean
// }>`
//   margin: ${({$center}) =>
//           $center ? 'auto' : '10px 0 18px 16px'
//   };
//   height: 27px;
//   font-family: 'Baumans Regular', sans-serif;
//   color: white;
//
//   text-shadow: 1px 1px 2px rgba(50, 32, 165, 0.94);
//   font-size: 27px;
//   font-style: normal;
//   font-weight: 400;
//   letter-spacing: 0.37px;
//   text-align: center;
// `

export const StyledLanding = {
  Button,
  ButtonBack,
  ButtonNext,
  Header,
  Logo,
}
