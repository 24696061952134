import { COLORS, RADIUSES, SIZES, SPACINGS, SHADOWS } from './constants'

export const theme = {
  colors: {
    base: {
      primary: COLORS.BASE_COLORS.PRIMARY,
      secondary: COLORS.BASE_COLORS.SECONDARY,
      brandPurple: COLORS.BASE_COLORS.BRAND_PURPLE,
      main: COLORS.BASE_COLORS.MAIN_BLUE,
      main_disabled: COLORS.BASE_COLORS.MAIN_BLUE_DISABLED,
      blueGrey: COLORS.BASE_COLORS.BLUE_GREY,
      grey: {
        100: COLORS.BASE_COLORS.GREY[100],
        200: COLORS.BASE_COLORS.GREY[200],
        300: COLORS.BASE_COLORS.GREY[300],
        350: COLORS.BASE_COLORS.GREY[350],
        400: COLORS.BASE_COLORS.GREY[400],
        500: COLORS.BASE_COLORS.GREY[500],
        700: COLORS.BASE_COLORS.GREY[700],
        750: COLORS.BASE_COLORS.GREY[750],
        800: COLORS.BASE_COLORS.GREY[800],
      },
      light: COLORS.BASE_COLORS.LIGHT,
      dark: COLORS.BASE_COLORS.DARK,
      brandPink: COLORS.BASE_COLORS.BRAND_PINK,
      danger: COLORS.BASE_COLORS.DANGER,
      purple: COLORS.BASE_COLORS.PURPLE,
      softPink: COLORS.BASE_COLORS.SOFT_PINK,
      darkWhite: COLORS.BASE_COLORS.DARK_WHITE,
      whiteGrey: COLORS.BASE_COLORS.WHITE_GREY,
      green: COLORS.BASE_COLORS.GREEN,
      red: COLORS.BASE_COLORS.RED,
      darkBlue: COLORS.BASE_COLORS.DARK_BLUE,
      background_gradient_1: COLORS.BASE_COLORS.BACKGROUND_GRADIENT_1,
      background_gradient_main: COLORS.BASE_COLORS.BACKGROUND_GRADIENT_MAIN,
      red2: COLORS.BASE_COLORS.RED2,
    },
    landing: {
      white_header: COLORS.LANDING_COLORS.WHITE_HEADER,
      white_descr: COLORS.LANDING_COLORS.WHITE_DESCR,
      dark: COLORS.LANDING_COLORS.DARK,
      inactive: COLORS.LANDING_COLORS.INACTIVE,
      inactive_section: COLORS.LANDING_COLORS.INACTIVE_SECTION,
    },
    additional: {
      dark_ocean: COLORS.ADDITIONAL_COLORS.DARK_OCEAN,
      ocean: COLORS.ADDITIONAL_COLORS.OCEAN,
      background_gradient: COLORS.ADDITIONAL_COLORS.BACKGROUND_GRADIENT,
    },
    chart: {
      blue: COLORS.CHART_COLORS.BLUE,
      green: COLORS.CHART_COLORS.GREEN,
      yellow: COLORS.CHART_COLORS.YELLOW,
    },
    focus: COLORS.ADDITIONAL_COLORS.FOCUS,
    background: {},
  },
  spacings: {
    x4: SPACINGS.X4,
    x8: SPACINGS.X8,
    x12: SPACINGS.X12,
    x14: SPACINGS.X14,
    x16: SPACINGS.X16,
    x20: SPACINGS.X20,
    x24: SPACINGS.X24,
    x28: SPACINGS.X28,
    x30: SPACINGS.X30,
    x32: SPACINGS.X32,
    x36: SPACINGS.X36,
    x40: SPACINGS.X40,
    x44: SPACINGS.X44,
    x48: SPACINGS.X48,
    x60: SPACINGS.X60,
    x128: SPACINGS.X128,
  },
  radiuses: {
    df: RADIUSES.DEFAULT,
    md: RADIUSES.MEDIUM,
    lg: RADIUSES.LARGE,
  },
  sizes: {
    maxAppWidth: SIZES.MAX_APP_WIDTH,
  },
  shadows: {
    evenShadow8: SHADOWS.EVEN_SHADOW_8,
    evenShadow8Bold: SHADOWS.EVEN_SHADOW_8_BOLD,
    evenShadow4: SHADOWS.EVEN_SHADOW_4,
  },
}
