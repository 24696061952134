import React, { type FC, useState } from 'react'

import { useInterval } from '@hooks/useInterval'

import { type TimerProps } from './types'

export const Timer: FC<TimerProps> = ({ expiredDate }) => {
  const [msToEnd, setMsTimeToEnd] = useState<number>(
    expiredDate.getTime() - Date.now()
  )

  useInterval(() => {
    setMsTimeToEnd(expiredDate.getTime() - Date.now())
  }, 1000)

  const minutes = Math.trunc((msToEnd / (1000 * 60)) % 60)
  const hours = Math.trunc((msToEnd / (1000 * 60 * 60)) % 24)

  return (
    <time>
      {
        'expires in ' +
        (
          hours >= 1
            ? hours + 'h'
            : minutes >= 1
              ? minutes + 'm'
              : minutes === 0 && msToEnd > 0
                ? '<1m'
                : '0m'
        )
      }
    </time>
  )
}
