import { Button, Flex, Text } from '@uiKit'
import { styled } from 'styled-components'

const Main = styled(Flex)`
  height: 100%;
  padding: 0 24px 34px 24px;
`

const Portrait = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-39%, -37%);
  width: auto;
  height: 85vh;
  aspect-ratio: auto;
`

const Header = styled(Text)`
  font-size: 48px;
  font-weight: 600;
  color: rgba(255, 252, 252, 0.90);
  text-shadow: 1px 1px 2px rgba(50, 32, 165, 0.94);
  letter-spacing: 0.36px;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
  
  @media ( max-height: 820px ) {
    font-size: 36px;
    line-height: 36px;
  }
  @media ( max-height: 700px ) {
    font-size: 32px;
    line-height: 32px;
  }
`

const HeaderWrapper = styled(Flex)`
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  
  @media ( max-width: 370px ) {
  padding: 0 10px;
  }
  
  @media ( max-width: 350px ) {
  padding: 0;
  }
`

const FirstHeader = styled(Header)`
  width: 100%;
  text-align: start;
  padding-top: 80px;
  line-height: 41px;
  
  @media ( max-height: 820px ) {
    padding-top: 40px;
  }
  @media ( max-height: 700px ) {
    padding-top: 10px;
  }
`

const SecondHeader = styled(Header)`
  text-align: end;
  z-index: 2;
  padding-top: 160px;
  width: 100%;
  line-height: 48px;
  
  @media ( max-height: 820px ) {
    padding-top: 80px;
  }
  @media ( max-height: 700px ) {
    padding-top: 40px;
  }
`

const CardText = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
  margin-top: auto;
`

const AppButton = styled(Button)`
  height: 44px;
  justify-content: space-between;
  box-shadow: 0 4px 4px 0 rgba(0, 35, 220, 0.25);
  border: 1px solid #FFFCFC;
  background: unset;
  z-index: 2;
`

const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.base.light};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.55);
  line-height: 22px;
  letter-spacing: -0.41px;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 18px;
  z-index: 2;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
`

export const PortraitPageStyled = {
  Portrait,
  Main,
  CardText,
  AppButton,
  Description,
  HeaderWrapper,
  FirstHeader,
  SecondHeader,
}
