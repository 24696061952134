import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { Reflection } from '@pages/Reflection'
import { ReflectionInfo } from '@pages/Reflection/pages'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const reflectionRoutes: RouteObject[] = [
  {
    path: ROUTES.REFLECTION.INFO,
    element: (
      <ProtectedRoute>
        <ReflectionInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.REFLECTION.ROOT,
    element: (
      <ProtectedRoute>
        <Reflection />
      </ProtectedRoute>
    ),
  },
]
