import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type CreateLuscherTestResponse,
  type CreateLuscherTestRequest,
} from './types'

export const createLuscherTest = async (
  data?: CreateLuscherTestRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<CreateLuscherTestResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/luscher_test/create/',
    baseUrl,
    method: HTTPMethods.POST,
    headers,
    data,
  })
}
