import { type UseRequestOptions } from '@hooks/useRequest/types'
import { type APIFunctionOptions } from '@lib/api/types'
import { type AxiosResponse } from 'axios'

export type UseAPIOptions = Omit<UseRequestOptions, 'errorHandler'>

export interface UseAPIData<T, G> {
  apiService: (
    data?: T,
    options?: APIFunctionOptions<any>,
  ) => Promise<AxiosResponse<G>>
  requestData?: T,
  ignoredErrors?: string[]
}

export enum ErrorId {
  TRACK_NOT_FOUND = 'TRACK_NOT_FOUND',
  TRACK_HAS_FAILED_PROCESSING_STATUS_ERROR = 'TRACK_HAS_FAILED_PROCESSING_STATUS_ERROR',
  TRACK_WITH_SPECIFIED_ID_IS_ALREADY_ADDED = 'TRACK_WITH_SPECIFIED_ID_IS_ALREADY_ADDED',
  TRACK_WITH_SPECIFIED_ID_IS_NOT_ADDED = 'TRACK_WITH_SPECIFIED_ID_IS_NOT_ADDED',
  TRACK_WITH_SPECIFIED_ID_IS_NOT_READY_YET = 'TRACK_WITH_SPECIFIED_ID_IS_NOT_READY_YET',
  USER_SET_OF_TRACKS_IS_ALREADY_FULL = 'USER_SET_OF_TRACKS_IS_ALREADY_FULL',
  INVALID_FILTERS_ERROR = 'INVALID_FILTERS_ERROR',
  NOT_ALLOWED_TO_PERFORM_THIS_ACTION = 'NOT_ALLOWED_TO_PERFORM_THIS_ACTION',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  SOME_REQUIRED_PROFILE_FIELDS_MISSING = 'SOME_REQUIRED_PROFILE_FIELDS_MISSING',
  SOME_TRACKS_FAILED = 'SOME_TRACKS_FAILED',
  TRACKS_NOT_COMPLETE = 'TRACKS_NOT_COMPLETE',
  TRACKS_NOT_READY_YET = 'TRACKS_NOT_READY_YET',
  TRACKS_MOOD_NOT_READY_YET = 'TRACKS_MOOD_NOT_READY_YET',
  BIG5_TEST_NOT_PASSED = 'BIG5_TEST_NOT_PASSED',
  REFLECTION_NOT_CREATED = 'REFLECTION_NOT_CREATED',
}
