import React, { type PropsWithChildren, type FC } from 'react'

import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types'

import { StyledAudioCard } from './styled'
import { type AudioCardProps } from './types'

export const AudioCard: FC<PropsWithChildren<AudioCardProps>> = ({
  leftColumn,
  middleColumn,
  rightColumn,
  children,
  onTrackClick
}) => {
  return (
    <StyledAudioCard.Card>
      {leftColumn && leftColumn}
      <StyledAudioCard.ContentWrapper
        direction={FlexDirection.ROW}
        flex="1"
        alignItems={FlexAlignItems.CENTER}
        justifyContent={FlexJustifyContent.SPACE_BETWEEN}
        onClick={onTrackClick}
      >
        {middleColumn && middleColumn}
        {children && children}
      </StyledAudioCard.ContentWrapper>
      {rightColumn && rightColumn}
    </StyledAudioCard.Card>
  )
}
