import React, { type FC } from 'react'

import { Flex, Spacer, Text } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'
import { TextSize } from '@uiKit/Text/types'

import { StyledBlockCompatibilities } from './styled'
import { type CompatibilitiesProps } from './types'

export const Compatibilities: FC<CompatibilitiesProps> = ({
    compatibilities,
}) => {
  return (
    <Flex>
      <StyledBlockCompatibilities.CircularProgressbarWrapper>
        <StyledBlockCompatibilities.CircularProgressbarRiffs
          value={(compatibilities.tracks ?? 0) * 100}
          strokeWidth={8}
        >
            <StyledBlockCompatibilities.CircularProgressbarBig5
              value={(compatibilities.big5 ?? 0) * 100}
              strokeWidth={9.7}
            >
              <StyledBlockCompatibilities.CircularProgressbarMoodRiffs
                value={(compatibilities.tracksMood ?? 0) * 100}
                strokeWidth={12.4}
              >
                <StyledBlockCompatibilities.CircularProgressbarLuscher
                  value={(compatibilities.luscher ?? 0) * 100}
                  strokeWidth={15.5}
                />
              </StyledBlockCompatibilities.CircularProgressbarMoodRiffs>
            </StyledBlockCompatibilities.CircularProgressbarBig5>
        </StyledBlockCompatibilities.CircularProgressbarRiffs>
      </StyledBlockCompatibilities.CircularProgressbarWrapper>
      <Spacer space={36} />
      <Flex direction={FlexDirection.COLUMN} fullWidth>

        <Text color="rgba(0, 0, 0, 0.6)" size={TextSize.CAPTION2_11}>
          character
        </Text>
        <Spacer space={4} />

        <StyledBlockCompatibilities.TestBlock>
          <StyledBlockCompatibilities.Point $color={'rgba(25, 111, 240, 1)'} />
          <Spacer space={8} />
          <StyledBlockCompatibilities.TestText>
            Riffs&nbsp;
          </StyledBlockCompatibilities.TestText>
          <Spacer space={8} />
          <StyledBlockCompatibilities.PercentText>
            {compatibilities.tracks ? Math.round((compatibilities.tracks) * 100) : '-'}%
          </StyledBlockCompatibilities.PercentText>
        </StyledBlockCompatibilities.TestBlock>

        <Spacer space={8} />
        <StyledBlockCompatibilities.TestBlock>
          <StyledBlockCompatibilities.Point $color={'rgba(108, 56, 247, 1)'} />
          <Spacer space={8} />
          <StyledBlockCompatibilities.TestText>
            Big 5&nbsp;
          </StyledBlockCompatibilities.TestText>
          <Spacer space={8} />
          <StyledBlockCompatibilities.PercentText>
            {compatibilities.big5 ? Math.round((compatibilities.big5) * 100) : '-'}%
          </StyledBlockCompatibilities.PercentText>
        </StyledBlockCompatibilities.TestBlock>

        <Spacer space={12} />
        <Text color="rgba(0, 0, 0, 0.6)" size={TextSize.CAPTION2_11}>
          mood
        </Text>
        <Spacer space={4} />

        <StyledBlockCompatibilities.TestBlock>
          <StyledBlockCompatibilities.Point $color={'rgba(160, 29, 222, 1)'} />
          <Spacer space={8} />
          <StyledBlockCompatibilities.TestText>
            Riffs&nbsp;
          </StyledBlockCompatibilities.TestText>
          <Spacer space={8} />
          <StyledBlockCompatibilities.PercentText>
            {compatibilities.tracksMood ? Math.round(compatibilities.tracksMood * 100) : '-'}%
          </StyledBlockCompatibilities.PercentText>
        </StyledBlockCompatibilities.TestBlock>
        <Spacer space={8} />

        <StyledBlockCompatibilities.TestBlock>
          <StyledBlockCompatibilities.Point $color={'rgb(207, 29, 222, 1)'} />
          <Spacer space={8} />
          <StyledBlockCompatibilities.TestText>
            Luscher&nbsp;
          </StyledBlockCompatibilities.TestText>
          <Spacer space={8} />
          <StyledBlockCompatibilities.PercentText>
            {compatibilities.luscher ? Math.round(compatibilities.luscher * 100) : '-'}%
          </StyledBlockCompatibilities.PercentText>
        </StyledBlockCompatibilities.TestBlock>
      </Flex>
    </Flex>
  )
}
