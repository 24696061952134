export enum TrackStatus {
  EMPTY,
  PLAY,
  PAUSE,
}

export interface AudioListProviderData {
  audios: HTMLAudioElement[]
  resetAllOnPlay?: boolean
  volume?: number
}

export interface AudioListContextData {
  onPlay: (src: string) => void
  onPause: (src: string) => void
  pauseAllAudios: () => void
  resetAllAudios: () => void
  audioObject?: Record<string, { trackStatus: TrackStatus }>
}
