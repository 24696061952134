import { type APIFunctionOptions } from '@lib/api/types'
import { request } from '@lib/http'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type GetChatRequest, type GetChatResponse } from './types'

export const getChat = async (
  data?: GetChatRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetChatResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/chat/get',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
  })
}
