import { Button as ButtonBase, Flex, Text as TextBase } from '@uiKit'
import { getTextSizeCSS } from '@uiKit/Text/styled'
import { TextSize } from '@uiKit/Text/types'
import { styled } from 'styled-components'

const Wrapper = styled(Flex)`
  position: sticky;
  bottom: 95px;
  border-radius: 44px;
  background: ${({ theme }) => theme.colors.base.light};
  z-index: 2;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

const Button = styled(ButtonBase)<{ $padding?: string }>`
  border: unset;
  background: unset;
  height: 52px;
  padding: ${({ $padding }) => $padding ?? '0'};
  box-shadow: none;
  ${getTextSizeCSS[TextSize.SUBHEADLINE_15]};
`

const LikeText = styled(TextBase)`
  color: ${({ theme }) => theme.colors.base.main};
`

export const StyledLikeToolbar = {
  Wrapper,
  Button,
  LikeText,
}
