import type React from 'react'

export enum LinkSize {
  SM = 1,
  DF,
  MD,
  LG,
}
export enum LinkDirection {
  COLUMN = 1,
  ROW,
}
export interface LinkProps<C extends React.ElementType = 'a'> {
  as?: C
  color?: string
  size?: LinkSize
  showBorder?: boolean
  direction?: LinkDirection
}
