import React, {
  type FC,
} from 'react'

import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { useRedirect } from '@hooks/useRedirect'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Button, Flex, Icon, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AudioListProvider } from 'providers'
import { useTheme } from 'styled-components'

import { StyledCompletedSubset } from './styled'
import { type CompletedSubsetProps } from './types'

export const CompletedSubset: FC<CompletedSubsetProps> = ({
    isLoading,
    data,
    tracks,
    isError,
    audios,
    removeMusic,
    isLoadingRemoveTrack,
    tracksType,
}) => {
  const theme = useTheme()
  const { redirect } = useRedirect()

  return (
    <>
      <Flex>
        <Text
          size={TextSize.TITLE3_20}
          fontWeight={600}
          color={theme.colors.base.main}
        >
          Well done!
        </Text>
        <StyledCompletedSubset.TextComplete
          size={TextSize.SUBHEADLINE_15}
          fontWeight={600}
          color={'#03AB00'}
          align={TextAlign.END}
        >
          complete
        </StyledCompletedSubset.TextComplete>
      </Flex>
      <Spacer space={theme.spacings.x16} />
      {isLoading || !data || !tracks ? (
        <AudioCardSkeleton count={10} spacing={SPACINGS.X16} />
      ) : isError || data.tracks_ctr === 0 ? (
        <StyledCompletedSubset.InfoBlock>
          <Text color={theme.colors.base.grey[400]}>No results found....</Text>
        </StyledCompletedSubset.InfoBlock>
      ) : (
        <AudioListProvider audios={audios}>
          <MusicCardList>
            {tracks.filter((track) => track.is_added).map(
              ({
                spotify_id: spotifyId,
                artist_name: artistName,
                name,
                spotify_preview_link: spotifyPreviewLink,
                spotify_label64_link: musicImageSrc,
                is_added: isAdded,
                spotify_link: spotifyLink
              }) => (
                <MusicCard
                  key={spotifyId}
                  musicImageSrc={musicImageSrc}
                  title={name}
                  author={artistName}
                  spotifyId={spotifyId}
                  spotifyLink={spotifyLink}
                  isMenuVisible={true}
                  isCheckboxVisible={true}
                  isAdded={isAdded}
                  showOnDelete={false}
                  isLoading={isLoadingRemoveTrack}
                  onDelete={
                    !isAdded ? undefined : () => {
                      removeMusic(spotifyId)
                    }
                  }
                  src={spotifyPreviewLink}
                />
              )
            )}
          </MusicCardList>
        </AudioListProvider>
      )}
      <Spacer space={theme.spacings.x16} />
      <Button
        variant={ButtonVariant.SECONDARY}
        onClick={() => {
          redirect(tracksType === 'character'
            ? ROUTES.TRACKS.PRT
            : ROUTES.TRACKS.PRT_MOOD)
        }}
      >
        <Icon image={Images.SEARCH_SIMILAR_SONGS} />
        <Spacer space={theme.spacings.x8} />
        See recommendations
      </Button>
      <Spacer space={theme.spacings.x16} />
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={() => { redirect(ROUTES.REFLECTION.INFO) }}
      >
        Go to Reflection
      </Button>
    </>
  )
}
