import React, { type FC } from 'react'

import { Icon, Text } from '@uiKit'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { StyledPersonFeature } from './styled'
import { type PersonFeatureProps } from './types'

export const PersonFeature: FC<PersonFeatureProps> = ({
    icon,
    text,
    image,
}) => {
  const theme = useTheme()
  return (
    <StyledPersonFeature.Wrapper>
      {image && (<Icon size={IconSize.X14} color={theme.colors.base.dark} image={image} />)}
      {icon && (<Text color={theme.colors.additional.dark_ocean} size={TextSize.S2}>{icon}</Text>)}
      <Text color={theme.colors.additional.dark_ocean} size={TextSize.S2}>{text}</Text>
    </StyledPersonFeature.Wrapper>
  )
}
