import { Flex, Text } from '@uiKit'
import { TextAlign } from '@uiKit/Text/types'
import { styled } from 'styled-components'

const Description = styled(Text).attrs(props => ({
  fontWeight: props.fontWeight ?? 600,
  align: props.align ?? TextAlign.START
}))<{
  $size?: number
}>`
  color: ${({ theme }) => theme.colors.landing.white_descr};
  width: -webkit-fill-available;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.55);
  font-size: ${({ $size }) => $size ?? 17}px;
  line-height: 22px;
  letter-spacing: -0.41px;
  padding-top: 16px;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
`

const Header = styled(Text).attrs(props => ({
  fontWeight: props.fontWeight ?? 600,
  align: props.align ?? TextAlign.START
}))`
  width: -webkit-fill-available;
  color: ${({ theme }) => theme.colors.landing.white_header};
  text-shadow: 1px 1px 2px rgba(50, 32, 165, 0.94);
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.36px;
  padding-top: 24px;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
`

const Image = styled.img<{
  $show?: boolean
}>`
  max-height: 97%;
  max-width: 100%;
  display: ${({ $show }) => $show ? 'unset' : 'none'};
`

const ImageWrapper = styled(Flex)`
  justify-content: center;
  height: auto;
  max-height: 100%;
  overflow: auto;
  padding-top: 16px;
`

const Body = styled(Flex)`
  overflow: auto;
  max-height: 100%;
  height: 100%;
  margin-bottom: 75px;
  position: relative;
  box-sizing: border-box;
`

const Main = styled(Flex)`
  background-color: rgba(233, 233, 233, 0.10);
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  max-height: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  padding: 0 24px;
  box-sizing: border-box;
`

export const LandingContainerWithImageStyled = {
  Image,
  Description,
  ImageWrapper,
  Header,
  Body,
  Main,
}
