import { Flex } from '@uiKit'
import { styled } from 'styled-components'

export const Container = styled(Flex)`
  max-width: 100%;
  overflow: hidden;
`

export const StyledAudioCardInfo = {
  Container,
}
