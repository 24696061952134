import React, { type FC } from 'react'

import { Flex } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'

import { Big5Slider } from './components/Big5Slider'
import { StyledBig5Sliders } from './styled'
import { type Big5SlidersProps } from './types'

export const Big5Sliders: FC<Big5SlidersProps> = (
  {
    currentUser,
    partner,
  }) => {
  return (
    <Flex direction={FlexDirection.ROW} gap={5}>
      <Flex direction={FlexDirection.COLUMN} gap={5}>
        <StyledBig5Sliders.ComponentText>
          Extraversion
        </StyledBig5Sliders.ComponentText>
        <StyledBig5Sliders.ComponentText>
          Agreeableness
        </StyledBig5Sliders.ComponentText>
        <StyledBig5Sliders.ComponentText>
          Conscientiousness
        </StyledBig5Sliders.ComponentText>
        <StyledBig5Sliders.ComponentText>
          Emotional Resilience
        </StyledBig5Sliders.ComponentText>
        <StyledBig5Sliders.ComponentText>
          Open-Mindedness
        </StyledBig5Sliders.ComponentText>
      </Flex>
      <StyledBig5Sliders.SlidersWrapper
        direction={FlexDirection.COLUMN}
        gap={5}
        fullWidth
      >
        {!currentUser && <StyledBig5Sliders.SkeletonLoadingOverlay />}
        {currentUser?.big5Traits && (
          <>
            <Big5Slider
              point1={{
                value: currentUser.big5Traits.extraversion,
                img: currentUser.image,
              }}
              point2={partner?.big5Traits && {
                value: partner.big5Traits.extraversion,
                img: partner.image,
              }}
            />
            <Big5Slider
              point1={{
                value: currentUser.big5Traits.agreeableness,
                img: currentUser.image,
              }}
              point2={partner?.big5Traits && {
                value: partner.big5Traits.agreeableness,
                img: partner.image,
              }}
            />
            <Big5Slider
              point1={{
                value: currentUser.big5Traits.conscientiousness,
                img: currentUser.image,
              }}
              point2={partner?.big5Traits && {
                value: partner.big5Traits.conscientiousness,
                img: partner.image,
              }}
            />
            <Big5Slider
              point1={{
                value: currentUser.big5Traits.emotional_stability,
                img: currentUser.image,
              }}
              point2={partner?.big5Traits && {
                value: partner.big5Traits.emotional_stability,
                img: partner.image,
              }}
            />
            <Big5Slider
              point1={{
                value: currentUser.big5Traits.open_mindedness,
                img: currentUser.image,
              }}
              point2={partner?.big5Traits && {
                value: partner.big5Traits.open_mindedness,
                img: partner.image,
              }}
            />
          </>
        )}
      </StyledBig5Sliders.SlidersWrapper>
    </Flex>
  )
}
