import React, { type FC } from 'react'

import { type AudioCardSkeletonProps } from '@component/AudioCardSkeleton/types'
import { SPACINGS } from '@lib/theme/constants'
import { Flex, Spacer } from '@uiKit'
import { FlexAlignItems, FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

import { StyledAudioCard } from './styled'

export const AudioCardSkeleton: FC<AudioCardSkeletonProps> = (
  {
    count = 1,
    spacing
  }) => {
  const theme = useTheme()
  const items: number[] = []
  for (let i = 0; i < count; i++) {
    items.push(i)
  }
  return (
    <>
      {items?.map((key, index) => (
        <span key={key}>
          <StyledAudioCard.Card>
            <StyledAudioCard.ContentWrapper
              direction={FlexDirection.ROW}
              flex="1"
              alignItems={FlexAlignItems.CENTER}
              justifyContent={FlexJustifyContent.SPACE_BETWEEN}
            >
              <StyledAudioCard.ImageSkeleton />
              <Spacer space={SPACINGS.X16} />
              <Flex flex="1" direction={FlexDirection.COLUMN}>
                <Skeleton width={'70%'} />
                <Spacer space={SPACINGS.X4} />
                <Skeleton width={'50%'} />
              </Flex>
            </StyledAudioCard.ContentWrapper>
          </StyledAudioCard.Card>
          {index !== items.length - 1 && (
            <Spacer space={spacing ?? theme.spacings.x8} />
          )}
        </span>
      ))}
    </>
  )
}
