import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { Big5 } from '@pages/Big5'
import { Big5Info, Big5Results } from '@pages/Big5/pages'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const big5Routes: RouteObject[] = [
  {
    path: ROUTES.BIG5.INFO,
    element: (
      <ProtectedRoute>
        <Big5Info />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.BIG5.TEST,
    element: (
      <ProtectedRoute>
        <Big5 />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.BIG5.RESULTS,
    element: (
      <ProtectedRoute>
        <Big5Results />
      </ProtectedRoute>
    ),
  },
]
