import React, { type FC } from 'react'

import { MenuItem } from '@szhsin/react-menu'
import { Icon, Spacer, Text } from '@uiKit'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { type ProfileMenuItemProps } from './types'

export const ProfileMenuItem: FC<ProfileMenuItemProps> = ({
  label,
  image,
  imageColor,
  textColor,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <MenuItem onClick={onClick}>
      <Icon image={image} color={imageColor} margin={4} />
      <Spacer space={theme.spacings.x8} />
      <Text size={TextSize.FOOTNOTE_13} align={TextAlign.CENTER} color={textColor}>
        {label}
      </Text>
    </MenuItem>
  )
}
