import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type AddUserTrackResponse,
  type AddUserTrackRequest,
  type GetUserTracksResponse,
  type RemoveUserTrackRequest,
  type RemoveUserTrackResponse,
} from './types'

export const getUserTracks = async (
  data?: undefined,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetUserTracksResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track/get',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
  })
}

export const addUserTrack = async (
  data?: AddUserTrackRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<AddUserTrackResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track/add',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}

export const removeUserTrack = async (
  data?: RemoveUserTrackRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<RemoveUserTrackResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track/delete',
    baseUrl,
    method: HTTPMethods.DELETE,
    data,
    headers,
  })
}

export const getUserTracksMood = async (
  data?: undefined,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetUserTracksResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track_mood/get',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
  })
}

export const addUserTrackMood = async (
  data?: AddUserTrackRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<AddUserTrackResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track_mood/add',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}

export const removeUserTrackMood = async (
  data?: RemoveUserTrackRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<RemoveUserTrackResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track_mood/delete',
    baseUrl,
    method: HTTPMethods.DELETE,
    data,
    headers,
  })
}
