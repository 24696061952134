import React, { forwardRef } from 'react'

import { StyledCheckbox } from '@uiKit/Checkbox/styled'
import { type CheckboxProps } from '@uiKit/Checkbox/types'
import { getImage } from '@uiKit/Icon/helpers'
import { Spacer } from '@uiKit/Spacer'
import { Text } from '@uiKit/Text'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

export const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(({
    icon,
    label,
    fullWidth,
    isError = false,
    checked,
    onCheckboxClick,
    className,
    isDisabled = false,
    disabledText,
    ...props
  }, ref) => {
    const theme = useTheme()
    return (
      <StyledCheckbox.Label $fullWidth={fullWidth} className={className} ref={ref}>
        <StyledCheckbox.HiddenCheckbox
          checked={checked}
          onChange={isDisabled ? undefined : onCheckboxClick}
          {...props}
        />
        <StyledCheckbox.CheckboxWrapper $isDisabled={isDisabled}>
          <StyledCheckbox.Checkbox $checked={checked} $isDisabled={isDisabled} />
        </StyledCheckbox.CheckboxWrapper>
        <Spacer space={8} />
        {icon && (
          <StyledCheckbox.Icon src={getImage(icon)} alt={''} />
        )}
        {label && (
          <>
            <Spacer space={4} />
            <StyledCheckbox.LabelText
              fontWeight={600}
              size={TextSize.SUBHEADLINE_15}
              color={isDisabled ? 'rgb(25, 29, 35, 0.4)' : theme.colors.base.dark}
            >
              {label}
            </StyledCheckbox.LabelText>
            <Spacer space={12} />
            {isDisabled && (
              <Text
                color={'rgb(116, 116, 116, 0.4)'}
                size={TextSize.CAPTION1_12}
                align={TextAlign.END}
              >
                {disabledText}
              </Text>
            )}
          </>
        )}
      </StyledCheckbox.Label>
    )
  }
)

Checkbox.displayName = 'Input'
