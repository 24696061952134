import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type ResetLikesRequest, type ResetLikesResponse } from './types'

export const resetLikes = async (
  data?: ResetLikesRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<ResetLikesResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/chat/reset_all_likes',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
    },
  })
}
