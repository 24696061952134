import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { SimilarTracks, SearchTracks } from '@pages/Tracks/pages'
import { PRT } from '@pages/Tracks/pages/PRT'
import { PRTMood } from '@pages/Tracks/pages/PRT/PRTMood'
import { TracksInfo } from '@pages/Tracks/pages/Search/pages'
import { TracksInfoMood } from '@pages/Tracks/pages/Search/pages/TracksInfo/TracksInfoMood'
import { SearchTracksMood } from '@pages/Tracks/pages/Search/SearchMood'
import { ROUTES } from '@router/constants'
import { type RouteObject } from 'react-router-dom'

export const tracksRoutes: RouteObject[] = [
  {
    path: ROUTES.TRACKS.SEARCH.ROOT,
    element: (
      <ProtectedRoute>
        <SearchTracks />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.SEARCH.ROOT_MOOD,
    element: (
      <ProtectedRoute>
        <SearchTracksMood />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.SEARCH.INFO,
    element: (
      <ProtectedRoute>
        <TracksInfo />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.SEARCH.INFO_MOOD,
    element: (
      <ProtectedRoute>
        <TracksInfoMood />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.SIMILAR,
    element: (
      <ProtectedRoute>
        <SimilarTracks />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.PRT,
    element: (
      <ProtectedRoute>
        <PRT />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.TRACKS.PRT_MOOD,
    element: (
      <ProtectedRoute>
        <PRTMood />
      </ProtectedRoute>
    ),
  },
]
