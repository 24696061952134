import React, { forwardRef, useState } from 'react'

import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'

import { StyledImage } from './styled'
import { type ImageProps, ImageSizes } from './types'

export const Image = forwardRef<HTMLImageElement, ImageProps>(({
    src,
    srcPreview,
    size = ImageSizes.FULL_WIDTH,
    alt,
    showBorder,
    ratio = 'auto',
    className,
    borderRadius = 0,
    defaultIcon = Images.PICTURE,
}, ref) => {
  if (showBorder === undefined) {
    if (src) {
      showBorder = false
    } else {
      showBorder = true
    }
  }
  let showPreview: boolean
  let isImagePreviewLoadedInitial: boolean
  let isSrcPreviewValidInitial: boolean
  if (srcPreview) {
    showPreview = true
    isImagePreviewLoadedInitial = false
    isSrcPreviewValidInitial = true
  } else {
    showPreview = false
    isImagePreviewLoadedInitial = true
    isSrcPreviewValidInitial = false
  }
  const [isImagePreviewLoaded, setIsImagePreviewLoaded] = useState(isImagePreviewLoadedInitial)
  const [isSrcPreviewValid, setIsSrcPreviewValid] = useState(isSrcPreviewValidInitial)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isSrcValid, setIsSrcValid] = useState(!!src) // TODO: handle error

  return (
    <StyledImage.Wrapper
      $size={size}
      $showBorder={showBorder}
      $ratio={ratio}
      $borderRadius={borderRadius}
      className={className}
    >
      {showPreview && isSrcPreviewValid && !isImageLoaded && (
        <StyledImage.ImagePreview
          $size={size}
          ref={ref}
          src={srcPreview}
          $hidden={!isImagePreviewLoaded}
          alt={alt}
          $ratio={ratio}
          $borderRadius={borderRadius}
          onLoad={() => { setIsImagePreviewLoaded(true) }}
          onError={() => { setIsSrcPreviewValid(false) }}
        />
      )}
      {isSrcValid && isImagePreviewLoaded && (
        <StyledImage.Image
          $size={size}
          ref={ref}
          src={src}
          $hidden={!isImageLoaded}
          alt={alt}
          $ratio={ratio}
          $borderRadius={borderRadius}
          onLoad={() => { setIsImageLoaded(true) }}
          onError={() => { setIsSrcValid(false) }}
        />
      )}
      {
        (isSrcPreviewValid || isSrcValid) &&
        ((showPreview && !isImagePreviewLoaded) || (!showPreview && !isImageLoaded)) && (
        <StyledImage.SkeletonWrapper>
          <StyledImage.Skeleton height={'100%'} $ratio={ratio} />
        </StyledImage.SkeletonWrapper>
      )}
      {!isSrcValid && (
        <StyledImage.DefaultIcon
          src={getImage(defaultIcon)}
          color={'#909090'}
          alt={'default picture'}
          $ratio={ratio}
        />
      )}
    </StyledImage.Wrapper>
  )
})

Image.displayName = 'Image'
