import { type NavigateOptions, useNavigate } from 'react-router-dom'

export const useRedirect = () => {
  const navigate = useNavigate()

  const redirect = (path: string, options?: NavigateOptions) => {
    navigate(path, options)
  }

  return { redirect }
}
