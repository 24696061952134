import { StyledImage } from '@uiKit/Image/styled'
import styled from 'styled-components'

import { type ClientImageProps } from './types'

import 'react-loading-skeleton/dist/skeleton.css'

const DefaultIcon = styled.img<{$ratio: ClientImageProps['ratio']}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  ${({ $ratio }) => $ratio && $ratio};
`

const Image = styled(StyledImage.Image)<{$fullImageHeight?: boolean}>`
  transition: opacity 1s;
  height: ${({ $fullImageHeight }) => $fullImageHeight && '100%'};
`

export const StyledClientImage = {
  DefaultIcon,
  Image,
}
