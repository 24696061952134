import React, { useState, type FC, useEffect } from 'react'

import { Card } from '@component/Card'

import {
  LuscherFirstStep,
  LuscherSecondStep,
  LuscherThirdStep,
} from './components'
import { useLuscherTest } from './hooks'
import { type LuscherTestProps } from './types'

export const LuscherTest: FC<LuscherTestProps> = ({ onSubmit }) => {
  const [activeStep, setActiveStep] = useState(1)

  const {
    onSubmitFirstLuscherTest,
    onSubmitSecondLuscherTest,
    onSubmitThirdLuscherTest,
    isShowLastStep,
    data,
  } = useLuscherTest({ onChangeStep: setActiveStep })

  useEffect(() => {
    if (
      data.form_1.length === 8 &&
      data.form_2.length === 8 &&
      data.form_3.length === 8
    ) {
      onSubmit(data)
    }
  }, [data])

  return (
    <Card padding={24} showBackground fullHeight>
      {activeStep === 1 && (
        <LuscherFirstStep onSubmit={onSubmitFirstLuscherTest} />
      )}
      {activeStep === 2 && (
        <LuscherSecondStep onSubmit={onSubmitSecondLuscherTest} />
      )}
      {activeStep === 3 && isShowLastStep && (
        <LuscherThirdStep onSubmit={onSubmitThirdLuscherTest} />
      )}
    </Card>
  )
}
