import { useCallback, useEffect, useRef, useState } from 'react'

import { ToastType } from '@hooks/useToast/types'
import { TracksType } from '@pages/Tracks/pages/types'
import { ROUTES } from '@router/constants'
import { type AxiosResponse } from 'axios'
import { API_CONFIG } from 'config'
import { useLocation } from 'react-router-dom'

import { useAuthToken, useRedirect, useToast } from '..'

import { useErrorHandler } from './hooks/useErrorHandler'
import { ErrorId, type UseAPIData, type UseAPIOptions } from './types'

export const useAPI = <T, G>(
    { apiService, requestData, ignoredErrors }: UseAPIData<T, G>,
    options?: UseAPIOptions,
) => {
  const { getAccessToken } = useAuthToken()
  const errorHandler = useErrorHandler()
  const { runOnMount = false } = options ?? {}
  const location = useLocation()
  const { notify } = useToast()

  const { redirect } = useRedirect()
  const [isLoading, setIsLoading] = useState(runOnMount)
  const [isError, setIsError] = useState(false)
  const [statusCode, setStatusCode] = useState<number | undefined>(undefined)
  const [data, setData] = useState<G | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [errorId, setErrorId] = useState<string | undefined>(undefined)
  const [errorData, setErrorData] = useState<object | undefined>(undefined)

  const isSendedOnMount = useRef(false)

  const runRequest = useCallback((data?: T) => {
    setIsLoading(true)
    setIsError(false)
    setData(undefined)

    apiService(data, {
      baseUrl: API_CONFIG.BASE_URL,
      headers: {
        Authorization: `Bearer ${getAccessToken() ?? ''}`,
      },
    })
      .then((res) => {
        setIsError(false)
        setIsLoading(false)
        setData(res.data)
      })
      .catch((e) => {
        // Error Handler
        errorHandler(
          e,
          () => {
            setIsLoading(false)
            setIsError(true)
            setStatusCode(e.response?.status ?? 500)

            const message = e.response?.data.message as string
            const id = e.response?.data.exception_id as string
            setErrorMessage(message)
            setErrorId(id)
            setErrorData(e.response?.data as object)
          },
          (res: AxiosResponse<G>) => {
            setIsError(false)
            setIsLoading(false)
            setData(res.data)
          }
        )
      })
  }, [])

  useEffect(() => {
    if (runOnMount && !isSendedOnMount.current) {
      isSendedOnMount.current = true

      runRequest(requestData)
    }
  }, [runRequest, runOnMount, requestData])

  useEffect(() => {
    if (!isError) {
      return
    }
    if (statusCode && statusCode >= 500) {
      redirect(ROUTES.ERROR.PAGE500)
      return
    }
    if (errorId && ignoredErrors?.includes(errorId)) {
      return
    }

    switch (errorId) {
      case ErrorId.TRACK_NOT_FOUND:
        notify('Track with specified id does not exist.', {type: ToastType.ERROR})
        return
      case ErrorId.TRACK_HAS_FAILED_PROCESSING_STATUS_ERROR:
        redirect(ROUTES.ERROR.TRACK_WAS_DELETED)
        return
      case ErrorId.TRACK_WITH_SPECIFIED_ID_IS_ALREADY_ADDED:
        notify('The track has already been added.', {type: ToastType.ERROR})
        return
      case ErrorId.TRACK_WITH_SPECIFIED_ID_IS_NOT_ADDED:
        notify('The track has already been deleted.', {type: ToastType.ERROR})
        return
      case ErrorId.TRACK_WITH_SPECIFIED_ID_IS_NOT_READY_YET:
        redirect(ROUTES.ERROR.SUBSET_NOT_PROCESSED)
        return
      case ErrorId.USER_SET_OF_TRACKS_IS_ALREADY_FULL:
        notify('You can not add more riffs.', {type: ToastType.ERROR})
        return
      case ErrorId.INVALID_FILTERS_ERROR:
        notify('Unexpected error.', {type: ToastType.ERROR})
        return
      case ErrorId.NOT_ALLOWED_TO_PERFORM_THIS_ACTION:
        notify('Unexpected error.', {type: ToastType.ERROR})
        return
      case ErrorId.NOT_AUTHENTICATED:
        return
      case ErrorId.SOME_REQUIRED_PROFILE_FIELDS_MISSING:
        redirect(ROUTES.PROFILE.EDIT)
        return
      case ErrorId.SOME_TRACKS_FAILED: {
        const trackType = errorData && 'removed_tracks_mood' in errorData
          ? TracksType.MOOD
          : TracksType.CHARACTER
        redirect(ROUTES.ERROR.TRACK_WAS_DELETED + '?type=' + trackType)
        return
      }
      case ErrorId.TRACKS_NOT_COMPLETE:
        redirect(ROUTES.TRACKS.SEARCH.ROOT)
        return
      case ErrorId.TRACKS_NOT_READY_YET:
        if (location.pathname !== ROUTES.ERROR.SUBSET_NOT_PROCESSED) {
          redirect(ROUTES.ERROR.SUBSET_NOT_PROCESSED)
        }
        return
      case ErrorId.TRACKS_MOOD_NOT_READY_YET:
        if (location.pathname !== ROUTES.ERROR.SUBSET_NOT_PROCESSED) {
          redirect(ROUTES.ERROR.SUBSET_NOT_PROCESSED)
        }
        return
      case ErrorId.BIG5_TEST_NOT_PASSED:
        redirect(ROUTES.BIG5.TEST)
        return
      case ErrorId.REFLECTION_NOT_CREATED:
        notify('Unexpected error.', {type: ToastType.ERROR})
        return
      default:
        notify(errorMessage ?? 'Unexpected error.', { type: ToastType.ERROR })
    }
  }, [isError, errorMessage, statusCode, errorId])

  return { data, isError, isLoading, errorMessage, errorData, runRequest, errorId }
}
