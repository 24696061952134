import React, { type FC } from 'react'

import { Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'

import { StyledSectionNav } from './styled'
import { type SectionNavProps } from './types'

export const SectionNav: FC<SectionNavProps> = ({
    section,
    onStepChange,
    buttonsData,
    isDisabled,
}) => {
  return (
    <StyledSectionNav.Wrapper>
      {buttonsData.map(({label, index}) => (
          <StyledSectionNav.Button key={index}
            $isDisabled={isDisabled}
            $isActive={section === index}
            onClick={() => { !isDisabled && onStepChange(index) }}
          >
            <Text size={TextSize.SUBHEADLINE_15}>
              {label}
            </Text>
          </StyledSectionNav.Button>
        )
      )}
    </StyledSectionNav.Wrapper>
  )
}
