import React, { type FC } from 'react'

import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Icon, Spacer, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { ImageSizes } from '@uiKit/Image/types'
import { TextSize } from '@uiKit/Text/types'

import { StyledChatSection } from './styled'
import { type ChatSectionProps } from './types'

export const ChatSection: FC<ChatSectionProps> = (
  {
    users,
    showChatAction = false,
    infoText,
    isLoading,
  }
) => {
  const { redirect } = useRedirect()
  return (
    <StyledChatSection.Wrapper>
      <Spacer space={8} />
      <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
        {infoText}
      </Text>
      <Spacer space={8} />
      {isLoading && (
        <StyledChatSection.UserWrapperSkeleton count={5} />
      )}
      {users?.map((user) => (
        <StyledChatSection.UserWrapper key={user.id}>
          <StyledChatSection.Image
            src={`data:image/png;base64,${user.profile_image_base64}`}
            ratio={'1'}
            size={ImageSizes.MEDIUM}
            borderRadius={48}
          />
          <Spacer space={16} />
          <StyledChatSection.Text size={TextSize.BODY_17} fontWeight={700}>
            {user.username}
          </StyledChatSection.Text>
          <StyledChatSection.Spacer />
          <StyledChatSection.ActionButtonWrapper
            onClick={() => {
              redirect(ROUTES.MATCHING.CANDIDATE + '?candidate_id=' + user.id.toString())
            }}
          >
            <Icon image={Images.EYE} size={IconSize.X24} />
          </StyledChatSection.ActionButtonWrapper>
          {showChatAction && (
            <StyledChatSection.ActionButtonWrapper
              onClick={() => { redirect(ROUTES.CHAT.MOCK) }}
            >
              <Icon image={Images.CHAT} size={IconSize.X20} color={'#196FF0'} />
            </StyledChatSection.ActionButtonWrapper>
          )}
        </StyledChatSection.UserWrapper>
      ))}
    </StyledChatSection.Wrapper>
  )
}
