export const isShowLastLusherTestStep = (
  firstTestData: any[],
  secondTestData: any[]
) => {
  const firstColors = firstTestData.map(({ color }) => color)
  const secondColors = secondTestData.map(({ color }) => color)

  let countOfEqual = 0

  firstColors.forEach((color, index) => {
    if (secondColors[index] === color) {
      countOfEqual += 1
    }
  })

  return countOfEqual < 5
}
