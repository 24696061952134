import React from 'react'

import { parseJWT } from '@lib/jwt'
import { theme } from '@lib/theme'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'

import { App } from './App'
import { useAuthToken } from './hooks'
import { reportAccessibility } from './repoatAccessibility'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn:
    'https://11f4c738cc34a1380540a2ff5b220c2f@o4506725383733248.ingest.sentry.io/4506736125476864',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      unblock: ['.sentry-unblock', '[data-sentry-unblock]'],
      unmask: ['.sentry-unmask', '[data-sentry-unmask]'],
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/rifflect\.com/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const { getAccessToken } = useAuthToken()
const token = getAccessToken()
if (token) {
  Sentry.setUser({
    id: parseJWT(token).user_id,
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
reportAccessibility(React)
