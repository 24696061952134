import React, { type FC } from 'react'

import pause from '@assets/icons/pause.svg'
import play from '@assets/icons/play.svg'
import music from '@assets/images/music.svg'
import { Image } from '@uiKit'
import { useInView } from 'react-intersection-observer'

import { StyledAudioCardImage } from './styled'
import { type AudioCardImageProps, TrackStatus } from './types'

export const AudioCardImage: FC<AudioCardImageProps> = ({
  alt = '',
  src,
  trackStatus,
  margin = 8,
}) => {
  const { ref, inView } = useInView({threshold: 0, triggerOnce: true})
  return (
    <StyledAudioCardImage.Container $margin={margin} ref={ref}>
      {inView && (
        <Image
          ratio={'1'}
          src={!src ? music : src}
          alt={alt}
        />
      )}
      {
        trackStatus === TrackStatus.PLAY
          ? <StyledAudioCardImage.Icon src={pause} alt={'Play music'} />
          : trackStatus === TrackStatus.PAUSE
            ? <StyledAudioCardImage.Icon src={play} alt={'Pause music'} />
            : <></>
      }
    </StyledAudioCardImage.Container>
  )
}
