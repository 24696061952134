import { Item } from '@skbkontur/react-ui/components/Select/Item'
import { styled } from 'styled-components'

import { type LuscherColorCheckboxProps } from './types'

const ColorCheckbox = styled.input<{
  $color: LuscherColorCheckboxProps['color']
  $icon?: string
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  appearance: none;
  
  -webkit-mask: url(${({$icon}) => $icon}) no-repeat center/contain;
  mask: url(${({$icon}) => $icon}) no-repeat center/contain;
  background-color: ${({ $color }) => $color};

  &:checked {
    visibility: hidden;
  }
`

export const StyledLyscherColor = {
  ColorCheckbox,
  Item,
}
