import React, { type FC, useState } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Spacer, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { NavbarSave } from './components/NavbarSave'
import { ProfileEditCard } from './components/ProfileEditCard'

export const ProfileEdit: FC = () => {
  const {redirect} = useRedirect()
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }
  const formName = 'profileUpdateForm'

  return (
    <AppLayout
      header={
        <LogoHeader
          showSecondButton
          secondButton={{
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => { setOpen(prev => !prev) },
          }}
        />
      }
      navbar={<NavbarSave formName={formName} />}
    >
      <StyledPopup.Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        position="right center"
      >
        <PopupInner
          title="Quit?"
          closeModal={closeModal}
          onButtonClick={() => { redirect(ROUTES.PROFILE.ROOT) }}
          showButton
        >
          <Spacer space={16} />
          <Text size={TextSize.SUBHEADLINE_15} color={'#5D5D5D'}>
            Are you sure you want to quit? Your unsaved data will be lost.
          </Text>
          <Spacer space={16} />
        </PopupInner>
      </StyledPopup.Popup>
      <ProfileEditCard formName={formName} />
    </AppLayout>
  )
}
