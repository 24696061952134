import React, { type FC, useCallback } from 'react'

import portrait from '@assets/images/landing_portrait.png'
import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Icon, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexDirection, FlexJustifyContent } from '@uiKit/Flex/types'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { useTheme } from 'styled-components'

import { PortraitPageStyled } from './styled'

export const PortraitPage: FC = () => {
  const theme = useTheme()
  const { redirect } = useRedirect()
  const onOpenAppClick = useCallback(() => {
    redirect(ROUTES.PROFILE.ROOT)
  }, [])
  return (
    <>
      <PortraitPageStyled.HeaderWrapper direction={FlexDirection.COLUMN}>
        <PortraitPageStyled.FirstHeader>
          Music<br />binds<br />souls
        </PortraitPageStyled.FirstHeader>
        <PortraitPageStyled.Portrait src={portrait} />
        <PortraitPageStyled.SecondHeader>
          We make<br />those bonds<br />visible
        </PortraitPageStyled.SecondHeader>
      </PortraitPageStyled.HeaderWrapper>

      <PortraitPageStyled.Main
        direction={FlexDirection.COLUMN}
        justifyContent={FlexJustifyContent.CENTER}
      >
        <PortraitPageStyled.CardText>
          <PortraitPageStyled.Description>
            Discover people matching your musical<br />preferences and mood
          </PortraitPageStyled.Description>
        </PortraitPageStyled.CardText>
        <PortraitPageStyled.AppButton
          role="link"
          onClick={onOpenAppClick}
          fullWidth={true}
          variant={ButtonVariant.SECONDARY}
        >
          <Text color={theme.colors.base.light} fontWeight={500}>Open App</Text>
          <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} color={theme.colors.base.light} />
        </PortraitPageStyled.AppButton>
      </PortraitPageStyled.Main>
    </>
  )
}
