export enum DividerWidth {
  FULL_WIDTH = 1,
  SMALL,
}

export interface DividerProps {
  height?: number
  color?: string
  width?: DividerWidth
}
