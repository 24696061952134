import { Flex, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const ComponentText = styled(Text).attrs({
  size: TextSize.CAPTION2_11,
})`
  white-space: nowrap;
  height: 18px;
`

const SlidersWrapper = styled(Flex)`
  box-sizing: border-box;
  position: relative;
`

const SkeletonLoadingOverlay = styled(Skeleton)`  
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  width: 100%;
  height: 100%;
`

export const StyledBig5Sliders = {
  ComponentText,
  SlidersWrapper,
  SkeletonLoadingOverlay,
}
