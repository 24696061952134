import React, { type FC } from 'react'

import { useLogout } from '@hooks/index'
import { type ProfileMenuProps } from '@pages/Profile/components/ProfileMenu/types'
import { ProfileMenuItem } from '@pages/Profile/components/ProfileMenuItem'
import { Images } from '@uiKit/Icon/constants/images'
import { useTheme } from 'styled-components'

import { StyledProfileMenu } from './styled'

export const ProfileMenu: FC<ProfileMenuProps> = ({ isOpen, setOpen, innerRef }) => {
  const theme = useTheme()
  const { onLogout } = useLogout()

  return (
    <StyledProfileMenu.Menu
      state={isOpen ? 'open' : 'closed'}
      anchorRef={innerRef}
      onClose={() => { setOpen(false) }}
      align={'end'}
      shift={12}
      viewScroll={'auto'}
      position={'anchor'}
    >
      <ProfileMenuItem
        label={'Sign out'}
        image={Images.EXIT}
        onClick={onLogout}
        imageColor={theme.colors.base.red2}
        textColor={theme.colors.base.red2}
      />
    </StyledProfileMenu.Menu>
  )
}
