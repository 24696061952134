import React from 'react'

import { SignIn } from '@pages/SignIn'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const signInRoutes: RouteObject[] = [
  {
    path: ROUTES.SIGN_IN,
    element: <SignIn />,
  },
]
