import { styled } from 'styled-components'

const Icon = styled.img`
  margin: 4px;
  width: 16px;
  height: 16px;
`

export const StyledAudioCardDropDownMenuItem = {
  Icon
}
