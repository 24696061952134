import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type SignInRequest, type SignInResponse } from './types'

export const signIn = async (
  data?: SignInRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<SignInResponse>> => {
  const { baseUrl = '' } = options ?? {}

  return await request({
    url: '/api/app/frontend/auth/jwt/create',
    baseUrl,
    method: HTTPMethods.POST,
    data,
  })
}
