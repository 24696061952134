
export const ROUTES = {
  SIGN_IN: '/signIn',
  LANDING: {
    APP: '/landing/app',
  },
  TRACKS: {
    SEARCH: {
      ROOT: '/tracks/search',
      ROOT_MOOD: '/tracks/search_mood',
      INFO: '/tracks/search/info',
      INFO_MOOD: '/tracks/search/info_mood',
    },
    SIMILAR: '/tracks/search/similar/:musicId',
    PRT: '/tracks/personally_recommended_tracks',
    PRT_MOOD: '/tracks/personally_recommended_tracks_mood',
  },
  ERROR: {
    PAGE404: '/page_404',
    PAGE500: '/page_500',
    TRACK_WAS_DELETED: '/track_was_deleted',
    SUBSET_NOT_COMPLETE: '/subset_not_complete',
    SUBSET_NOT_PROCESSED: '/subset_not_processed',
    NO_REFLECTION: '/no_reflection',
    NO_USERS_FOR_MATCHING: '/no_users_for_matching',
  },
  CHAT: {
    ROOT: '/chat',
    MOCK: '/chat/mock',
  },
  PROFILE: {
    ROOT: '/',
    EDIT: '/profile/edit',
  },
  LUSCHER: {
    INFO: '/luscher/info',
    TEST: '/luscher',
    GRATITUDE: '/luscher/gratitude',
  },
  BIG5: {
    INFO: '/big5/info',
    TEST: '/big5',
    RESULTS: '/big5/results',
  },
  REFLECTION: {
    ROOT: '/reflection',
    INFO: '/reflection/into',
  },
  MATCHING: {
    ROOT: '/matching',
    CANDIDATE: '/matching/candidate',
  }
}
