import { css, styled } from 'styled-components'

import { type CardProps } from './types'

const Wrapper = styled.div<{
  $padding: CardProps['padding']
  $showBackground: CardProps['showBackground']
  $fullHeight: CardProps['fullHeight']
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ $padding }) => $padding + 'px'};
  flex: ${({ $fullHeight }) => $fullHeight && 1};
  ${({ $showBackground, theme }) => $showBackground && css`
    background: ${theme.colors.base.light};
    box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
    border-radius: 24px;
  `}
`

export const StyledCard = {
  Wrapper,
}
