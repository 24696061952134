import { Images } from '@uiKit/Icon/constants/images'

export interface ZodiacProps {
  value: number,
  onChange: (zodiac: number) => void
}

export enum Zodiacs {
  QUESTION = 1,
  ARIES,
  TAURUS,
  GEMINI,
  CANCER,
  LEO,
  VIRGO,
  LIBRA,
  SCORPIO,
  SAGITTARIUS,
  CAPRICORN,
  AQUARIUS,
  PISCES,
}

export const getZodiac = (id: number): {image: Images, text: string} | undefined => {
  switch (id) {
    case Zodiacs.ARIES:
      return {image: Images.ARIES, text: 'Aries'}
    case Zodiacs.TAURUS:
      return {image: Images.TAURUS, text: 'Taurus'}
    case Zodiacs.GEMINI:
      return {image: Images.GEMINI, text: 'Gemini'}
    case Zodiacs.CANCER:
      return {image: Images.CANCER, text: 'Cancer'}
    case Zodiacs.LEO:
      return {image: Images.LEO, text: 'Leo'}
    case Zodiacs.VIRGO:
      return {image: Images.VIRGO, text: 'Virgo'}
    case Zodiacs.LIBRA:
      return {image: Images.LIBRA, text: 'Libra'}
    case Zodiacs.SCORPIO:
      return {image: Images.SCORPIO, text: 'Scorpio'}
    case Zodiacs.SAGITTARIUS:
      return {image: Images.SAGITTARIUS, text: 'Sagittarius'}
    case Zodiacs.CAPRICORN:
      return {image: Images.CAPRICORN, text: 'Capricorn'}
    case Zodiacs.AQUARIUS:
      return {image: Images.AQUARIUS, text: 'Aquarius'}
    case Zodiacs.PISCES:
      return {image: Images.PISCES, text: 'Pisces'}
    case Zodiacs.QUESTION:
      return {image: Images.QUESTION, text: 'Prefer not to say'}
    default:
      return undefined
  }
}
