import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { Profile } from '@pages/Profile'
import { ProfileEdit } from '@pages/ProfileEdit'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const settingsRoutes: RouteObject[] = [
  {
    path: ROUTES.PROFILE.ROOT,
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PROFILE.EDIT,
    element: (
      <ProtectedRoute>
        <ProfileEdit />
      </ProtectedRoute>
    ),
  },
]
