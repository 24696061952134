import { createBrowserRouter } from 'react-router-dom'

import {
  settingsRoutes,
  signInRoutes,
  tracksRoutes,
  chatRoutes,
  matchingRoutes,
  landingRoutes,
  errorRoutes,
  big5Routes,
  luscherRoutes,
} from './routes'
import { reflectionRoutes } from './routes/reflection'

export const router = createBrowserRouter([
  ...errorRoutes,
  ...signInRoutes,
  ...settingsRoutes,
  ...tracksRoutes,
  ...big5Routes,
  ...matchingRoutes,
  ...landingRoutes,
  ...chatRoutes,
  ...reflectionRoutes,
  ...luscherRoutes,
])
