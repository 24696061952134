export const LUSCHER_COLORS = [
  {
    hex: '#929292',
    value: 0,
  },
  {
    hex: '#003FBC',
    value: 1,
  },
  {
    hex: '#287A51',
    value: 2,
  },
  {
    hex: '#DE0000',
    value: 3,
  },
  {
    hex: '#F6D304',
    value: 4,
  },
  {
    hex: '#75218F',
    value: 5,
  },
  {
    hex: '#AD4C03',
    value: 6,
  },
  {
    hex: '#000000',
    value: 7,
  },
]
