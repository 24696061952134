import React, { useMemo, type FC } from 'react'

import { StyledMatching } from '@pages/Matching/styled'
import { AudioListProvider } from 'providers'

import { CandidateDetails } from '../CandidateDetails'
import { CandidatePreview } from '../CandidatePreview'

import { StyledMatchingCard } from './styled'
import { type MatchingCardProps } from './types'

export const MatchingCard: FC<MatchingCardProps> = ({
    onCollapse,
    onLike,
    onDislike,

    dataMatch,
    dataCRT,

    isLoadingMatch,
    isLoadingCRT,
    isLoadingLike,

    isExpanded,
    getCollapsePropsWhenCollapsed,
    getCollapseProps,
    getToggleProps,
}) => {
  const audios = useMemo(
    () =>
      dataMatch?.candidate_tracks && dataCRT?.common_recommended_tracks
        ? [
          ...dataMatch.candidate_tracks.map(
            (audio) => new Audio(audio.spotify_preview_link)
          ),
          ...dataMatch.candidate_tracks_mood.map(
            (audio) => new Audio(audio.spotify_preview_link)
          ),
          ...dataCRT.common_recommended_tracks.map(
            (audio) => new Audio(audio.track.spotify_preview_link)
          ),
          ...dataCRT.common_recommended_tracks_mood.map(
            (audio) => new Audio(audio.track.spotify_preview_link)
          ),
        ]
        : [],
    [
      dataMatch?.candidate_tracks,
      dataMatch?.candidate_tracks_mood,
      dataCRT?.common_recommended_tracks,
      dataCRT?.common_recommended_tracks_mood,
    ]
  )
  return (
    <>
      <StyledMatchingCard.CollapsingSection
        {...getCollapsePropsWhenCollapsed()}
        $isExpanded={isExpanded}
      >
        <CandidatePreview
          dataMatch={dataMatch}
          isLoadingMatch={isLoadingMatch}
          isLoadingLike={isLoadingLike}
          onDislike={onDislike}
          onLike={onLike}
          getToggleProps={getToggleProps}
          onCollapse={onCollapse}
          isExpanded={isExpanded}
        />
      </StyledMatchingCard.CollapsingSection>
      <section {...getCollapseProps()}>
        <StyledMatching.MainCard>
          <AudioListProvider audios={audios}>
            <CandidateDetails
              dataMatch={dataMatch}
              dataCRT={dataCRT}
              isLoadingMatch={isLoadingMatch}
              isLoadingCRT={isLoadingCRT}
            />
          </AudioListProvider>
        </StyledMatching.MainCard>
      </section>
    </>
  )
}
