import { useCallback } from 'react'

import { useAuthToken, useLogout } from '@hooks/index'
import { refreshToken } from '@lib/api'
import { axiosInstance } from '@lib/http'
import { HttpStatusCode, type AxiosError, type AxiosResponse } from 'axios'
import { API_CONFIG } from 'config'

export const useErrorHandler = () => {
  const { getRefreshToken, addAccessToken } = useAuthToken()

  const { onLogout } = useLogout()

  const errorHandler = useCallback(
    async (
      error: AxiosError,
      onUnprocessedError: () => void,
      onRetry: (data: AxiosResponse) => void
    ) => {
      const originalConfig = error.config

      if (error.response?.status === HttpStatusCode.Unauthorized) {
        const currentRefresh = getRefreshToken()

        if (currentRefresh) {
          refreshToken({
            refresh: currentRefresh,
          }, {
            baseUrl: API_CONFIG.BASE_URL
          })
            .then(({ data }) => {
              if (data.access && originalConfig) {
                addAccessToken(data.access)

                originalConfig.headers.Authorization = `Bearer ${data.access}`

                axiosInstance(originalConfig).then((res) => {
                  onRetry(res)
                })
              }
            })
            .catch((e: AxiosError) => {
              if (e.response?.status === HttpStatusCode.BadRequest ||
                  e.response?.status === HttpStatusCode.NotFound) {
                onLogout()
              }
            })
        }
      } else {
        onUnprocessedError()
      }
    },
    []
  )

  return errorHandler
}
