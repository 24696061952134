import { theme } from '@lib/theme'
import { Flex, Text } from '@uiKit'
import { FlexAlignItems, FlexDirection } from '@uiKit/Flex/types'
import { TextSize } from '@uiKit/Text/types'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { styled } from 'styled-components'

import 'react-circular-progressbar/dist/styles.css'
import 'swiper/css'

const CircularProgressbarWrapper = styled.div`
  position: relative;
  width: 122px;
  height: 122px;
`

const CircularProgressbarRiffs = styled(CircularProgressbarWithChildren)`
  width: 122px;
  .CircularProgressbar-path {
    stroke: rgba(25, 111, 240, 1);
  }
  .CircularProgressbar-trail {
    stroke: rgba(25, 111, 240, 0.1);
  }
`

const CircularProgressbarBig5 = styled(CircularProgressbarWithChildren)`
  width: 100px;
  .CircularProgressbar-path {
    stroke: rgba(108, 56, 247, 1);
  }
  .CircularProgressbar-trail {
    stroke: rgba(108, 56, 247, 0.1);
  }
`

const CircularProgressbarMoodRiffs = styled(CircularProgressbarWithChildren)`
  width: 78px;
  .CircularProgressbar-path {
    stroke: rgba(160, 29, 222, 1);
  }
  .CircularProgressbar-trail {
    stroke: rgba(160, 29, 222, 0.1)};
  }
`

const CircularProgressbarLuscher = styled(CircularProgressbarWithChildren)`
  width: 56px;
  .CircularProgressbar-path {
    stroke: rgb(207, 29, 222, 1);
  }
  .CircularProgressbar-trail {
    stroke: rgba(207, 29, 222, 0.1)};
  }
`

const TestText = styled(Text).attrs({
  size: TextSize.SUBHEADLINE_15,
  color: theme.colors.base.dark,
})`
  flex: 1;
`

const PercentText = styled(Text).attrs({
  size: TextSize.SUBHEADLINE_15,
  color: '#878787',
})``

const Point = styled.div<{ $color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
`

const TestBlock = styled(Flex).attrs({
  direction: FlexDirection.ROW,
  flex: '1',
  alignItems: FlexAlignItems.CENTER,
})``

export const StyledBlockCompatibilities = {
  CircularProgressbarWrapper,
  CircularProgressbarRiffs,
  CircularProgressbarBig5,
  CircularProgressbarMoodRiffs,
  CircularProgressbarLuscher,
  TestText,
  PercentText,
  Point,
  TestBlock,
}
