import { RADIUSES } from '@lib/theme/constants'
import styled, { css } from 'styled-components'

import { type ButtonProps, ButtonVariant } from './types'

const getButtonVariantCSS = {
  [ButtonVariant.PRIMARY]: css`
    background: ${({ theme }) => theme.colors.base.main};
    color: ${({ theme }) => theme.colors.base.light};
    height: 44px;
    padding: 0 16px;
    box-shadow: ${({ theme }) => theme.shadows.evenShadow8Bold};

    &:disabled {
      color: #D3D3D3;
      background: #EBEBEB;
    }

    &:active {
      background: #2A3DEB;
    }
  `,
  [ButtonVariant.SECONDARY]: css`
    background: #FFFFFF;
    color: ${({ theme }) => theme.colors.base.dark};
    height: 44px;
    padding: 0 16px;
    box-shadow: ${({ theme }) => theme.shadows.evenShadow8};

    &:disabled {
      color: #D3D3D3;
      background: #EBEBEB;
    }
  `,
  [ButtonVariant.SMALL_CIRCLE]: css`
    border-radius: 47px;
    background: linear-gradient(
      181deg,
      rgba(255, 255, 255, 0.90) 1.15%,
      rgba(255, 255, 255, 0.79) 98.91%
    );
    box-shadow: none;
    width: 56px;
    height: 56px;
    padding: 16px;
  `,
}

const Button = styled.button<{
  $variant: ButtonProps['variant']
  $isBoxing?: ButtonProps['isBoxing']
  $fullWidth?: ButtonProps['fullWidth']
  $flex?: ButtonProps['flex']
  $textColor?: ButtonProps['textColor']
}>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${RADIUSES.LARGE}px;
  box-sizing: border-box;
  transition: 0.25s;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  border: none;
  
  color: ${({ $textColor }) => $textColor && $textColor} !important;
  transition: 0.3s;
  
  ${({ $variant }) => $variant && getButtonVariantCSS[$variant]}

  ${({ $fullWidth }) => $fullWidth && css`width: 100%;`}

  ${({ $flex }) => $flex && css`flex: ${$flex};`}

  &:focus-visible {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.focus};
    outline: none;
  }
`

export const StyledButton = {
  Button,
}
