import PopupBase from 'reactjs-popup'
import { styled } from 'styled-components'

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.base.light};
  border-radius: 24px;
  padding: 24px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

const CloseButton = styled.div`
  cursor: pointer;
  padding: 6px;
`

const Popup = styled(PopupBase)`
  &-content {
    background: transparent;
    border: unset;
    width: 80%;
    max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  }
`

export const StyledPopup = {
  Wrapper,
  CloseButton,
  Popup,
}
