import React, { type FC, type PropsWithChildren } from 'react'

import smallLogo from '@assets/images/smallLogo.png'

import { OnLoadLogoStyled } from './styled'

export const OnLoadLogo: FC<PropsWithChildren> = () => {
  return (
    <OnLoadLogoStyled.LogoWrapper>
      <OnLoadLogoStyled.Logo src={smallLogo} />
    </OnLoadLogoStyled.LogoWrapper>
  )
}
