import { useEffect, useState } from 'react'

import { type Track } from '@lib/api/types'
import { TracksType } from '@pages/Tracks/pages/types'

import { useMusicActions } from '..'

export const useMusicList = (
  listOfTracks?: Track[], tracksType: TracksType = TracksType.CHARACTER
) => {
  const [tracks, setTracks] = useState<Track[]>([])

  useEffect(() => {
    if (listOfTracks) {
      setTracks(listOfTracks)
    }
  }, [listOfTracks])

  const { addMusic, removeMusic, isLoadingAddTrack, isLoadingRemoveTrack } = useMusicActions({
    onAdd: (track) => {
      setTracks((prev) =>
        prev.map((iteratedTrack) => {
          if (track.spotify_id === iteratedTrack.spotify_id) {
            return track
          }

          return iteratedTrack
        })
      )
    },
    onRemove: (track) => {
      setTracks((prev) =>
        prev.map((iteratedTrack) => {
          if (track.spotify_id === iteratedTrack.spotify_id) {
            return track
          }

          return iteratedTrack
        })
      )
    },
    tracksType
  })

  return { tracks, addMusic, removeMusic, isLoadingAddTrack, isLoadingRemoveTrack }
}
