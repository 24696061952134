import React, { type FC } from 'react'

import { StyledProfileSection } from '@pages/Profile/components/ProfileSection/styled'
import {
  TextBackgroundType,
  getAdditionalTextBackgroundColor,
  getAdditionalTextColor,
  type ProfileSectionProps
} from '@pages/Profile/components/ProfileSection/types'
import { Spacer } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'

export const ProfileSection: FC<ProfileSectionProps> = ({
    isDisabled = false,
    mainText,
    mainTextBlock,
    additionalText,
    additionalTextType = TextBackgroundType.INVISIBLE,
    tagText,
    tagTextBackgroundType = TextBackgroundType.INVISIBLE,
    backgroundImage,
    backgroundImageBase64,
    onSectionClick,
    isLoading,
    backgroundComponent,
}) => {
  return (
    <StyledProfileSection.Wrapper $isDisabled={isDisabled}>
      {!isLoading && isDisabled && (
        <StyledProfileSection.DisabledOverlay>
          Riffs should be chosen
        </StyledProfileSection.DisabledOverlay>
      )}
      <StyledProfileSection.Section
        $backgroundImage={backgroundImage ?? (backgroundImageBase64
          ? `data:image/png;base64,${backgroundImageBase64}` : undefined)}
        onClick={!isDisabled ? onSectionClick : undefined}
      >
        {isLoading && <StyledProfileSection.SkeletonLoadingOverlay $isLoading={isLoading} />}
        {!isLoading && (
          <StyledProfileSection.BackgroundOverlay>
            {backgroundComponent && backgroundComponent}
          </StyledProfileSection.BackgroundOverlay>
        )}
        <StyledProfileSection.TextBlock
          $backgroundColor={getAdditionalTextBackgroundColor(additionalTextType)}
        >
          {mainTextBlock && mainTextBlock}
          {mainText && (
            <StyledProfileSection.MainTextBlock>
              <StyledProfileSection.MainText>{mainText}</StyledProfileSection.MainText>
            </StyledProfileSection.MainTextBlock>
          )}
          {additionalText ? (
            <StyledProfileSection.AdditionalText
              size={TextSize.CAPTION2_11}
              color={getAdditionalTextColor(additionalTextType)}
            >
              {additionalText}
            </StyledProfileSection.AdditionalText>
          ) : (
            <Spacer space={5} />
          )}
        </StyledProfileSection.TextBlock>
        {tagText && (
          <StyledProfileSection.TagText
            $backgroundColor={getAdditionalTextBackgroundColor(tagTextBackgroundType)}
            color={getAdditionalTextColor(tagTextBackgroundType)}
            size={TextSize.CAPTION2_11}
          >
            {tagText}
          </StyledProfileSection.TagText>
        )}
      </StyledProfileSection.Section>
    </StyledProfileSection.Wrapper>
  )
}
