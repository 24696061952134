import { Heading as HeadingBase, Text as TextBase } from '@uiKit'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const Text = styled(TextBase)`
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  height: 60px;
`

const Heading = styled(HeadingBase)`
  text-align: center;
  color: ${({ theme }) => theme.colors.base.dark};
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.38px;
`

const ImageSkeleton = styled(Skeleton)`
  aspect-ratio: 1;
  padding-bottom: 2px;
`

const Spacer = styled.div`
  flex: 1;
`

export const StyledReflectionCard = {
  Text,
  Heading,
  ImageSkeleton,
  Spacer,
}
