import React, { type PropsWithChildren, type FC, Children } from 'react'

import { Flex, Spacer } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'
import { useTheme } from 'styled-components'

export const MusicCardList: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme()

  return (
    <Flex direction={FlexDirection.COLUMN}>
      {Children.map(children, (child, index) => (
        <>
          {child}
          {index !== Children.count(children) - 1 &&
            Children.count(children) > 1 && (
              <Spacer space={theme.spacings.x16} />
            )}
        </>
      ))}
    </Flex>
  )
}
