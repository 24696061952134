import React, { type FC } from 'react'

import { useTheme } from 'styled-components'

import { StyledAppLayout } from './styled'
import { type AppLayoutProps } from './types'

export const AppLayout: FC<AppLayoutProps> = (
  {
    children,
    backgroundColor,
    header,
    navbar,
    contentStyles,
  }) => {
  const theme = useTheme()
  backgroundColor = backgroundColor ?? theme.colors.base.whiteGrey
  return (
    <StyledAppLayout.Wrapper $backgroundColor={backgroundColor}>
      <StyledAppLayout.HeaderWrapper $backgroundColor={backgroundColor} />
        {header && header}
      <StyledAppLayout.ContentWrapper
        $paddingBottom={contentStyles?.paddingBottom}
        $justifyContent={contentStyles?.justifyContent}
      >
        {children}
      </StyledAppLayout.ContentWrapper>
      <StyledAppLayout.NavBarWrapper>
        {navbar && (
          <>
            {navbar}
            <StyledAppLayout.NavBarSpacer />
            <StyledAppLayout.Background />
          </>
        )}
      </StyledAppLayout.NavBarWrapper>
    </StyledAppLayout.Wrapper>
  )
}
