import { useLocalStorage } from '..'

export const useAuthToken = () => {
  const { setValue, getValue, removeValue } = useLocalStorage()

  const removeRefreshToken = () => {
    removeValue('refresh_token')
  }

  const addRefreshToken = (token: string) => {
    setValue('refresh_token', token)
  }

  const getRefreshToken = () => {
    return getValue('refresh_token')
  }

  const removeAccessToken = () => {
    removeValue('token')
  }

  const addAccessToken = (token: string) => {
    setValue('token', token)
  }

  const getAccessToken = () => {
    return getValue('token')
  }

  return {
    addRefreshToken,
    getRefreshToken,
    addAccessToken,
    getAccessToken,
    removeRefreshToken,
    removeAccessToken,
  }
}
