
import { css, styled } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  border-radius: 34px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  background: #fafafa;
`

const Button = styled.button<{
  $isActive: boolean
  $isDisabled?: boolean
}>`
  display: flex;
  flex-direction: row;
  border: none;
  height: 44px;
  border-radius: 34px;
  padding: 8px 16px;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  transition: 0.6s ease;
  ${({$isDisabled, $isActive, theme}) => $isDisabled
    ? $isActive
      ? css`
        box-shadow: 0px 0px 12px rgba(0,0,0,0.12);
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
      `
      : css`
        background: #fafafa;
        color: rgba(25, 29, 35, 0.4);
      `
    : $isActive
      ? css`
        box-shadow: 0px 0px 12px rgba(0,0,0,0.12);
        background: ${theme.colors.base.main};
        color: #fff;
      `
      : css`
        background: #fafafa;
      `
  }
`

export const StyledSectionNav = {
  Wrapper,
  Button,
}
