import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const PreviewWrapper = styled.div<{ $image?: string }>`
  position: relative;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  background-size: cover;
  background-image: url(${({ $image }) => $image});
  width: 100%;
  max-height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex: 1;
  margin-bottom: 4px;
`

const UserNameWrapper = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;
  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.base.grey[700]};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
`

const LoadingImageSkeleton = styled(Skeleton)<{ $isVisible: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  position: absolute;

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  z-index: ${({ $isVisible }) => ($isVisible ? 3 : -1)};
  transition: ${({ $isVisible }) => ($isVisible ? 'none' : 'opacity .5s, z-index .01s .5s')};
  pointer-events: none;
`

export const StyledCandidatePreview = {
  ButtonWrapper,
  PreviewWrapper,
  UserNameWrapper,
  LoadingImageSkeleton,
}
