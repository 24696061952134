import { Card as CardBase } from '@component/Card'
import { styled } from 'styled-components'

const WarningIcon = styled.img`
  width: 90px;
`

const Card = styled(CardBase)`
  align-items: center;
  justify-content: center;
`

export const StyledTrackWasDeleted = {
  WarningIcon,
  Card,
}
