import React, { useEffect, type FC, useState } from 'react'

import { Border } from '@component/Border'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { SectionNav } from '@component/SectionNav'
import { useAPI } from '@hooks/useAPI'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { generalProfileInfo } from '@lib/api/rest/profile'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import { TracksType } from '@pages/Tracks/pages/types'
import { Checkbox, Spacer, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'

import { type FilterPopupProps } from './types'

import 'reactjs-popup/dist/index.css'

export const FilterPopup: FC<FilterPopupProps> = ({
    onDataTestsRequestEnd,

    isFilterLuscher,
    isFilterBig5,
    isFilterReflection,
    sortBy: isFilterTracksMood,
    isMatchByMood: sortBy,

    setIsFilterLuscher,
    setIsFilterBig5,
    setIsFilterReflection,
    setIsFilterTracksMood,
    setSortBy,

    open,
    onClose,
    closeModal,
}) => {
  const { setValue, getValue } = useLocalStorage()
  const [sortByIndex, setSortByIndex] = useState(0)

  const { data: dataTests } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo,
  }, {
    runOnMount: true
  })

  useEffect(() => {
    if (dataTests) {
      let _sortBy: TracksType
      if (!dataTests?.are_tracks_mood_full) {
        _sortBy = TracksType.CHARACTER
      } else if (getValue('sort_by') === TracksType.MOOD) {
        _sortBy = TracksType.MOOD
      } else {
        _sortBy = TracksType.CHARACTER
      }
      setSortBy(_sortBy)
      setSortByIndex(_sortBy === TracksType.MOOD ? 1 : 0)

      const _isFilterBig5 = dataTests?.is_big5_done
        ? getValue('filter_is_big5_active') === 'true'
        : false
      setIsFilterBig5(_isFilterBig5)

      let _isFilterTracksMood: boolean
      if (_sortBy === TracksType.MOOD) {
        _isFilterTracksMood = true
      } else {
        _isFilterTracksMood = dataTests?.are_tracks_mood_full
          ? getValue('filter_is_riffs_mood_active') === 'true'
          : false
      }
      setIsFilterTracksMood(_isFilterTracksMood)

      const _isFilterLuscher = (dataTests?.luscher_test_expires_in_seconds ?? 0) > 0
        ? getValue('filter_is_luscher_active') === 'true'
        : false
      setIsFilterLuscher(_isFilterLuscher)

      const _isFilterReflection = dataTests?.image_reflection_base64
        ? getValue('filter_is_reflection_active') === 'true'
        : false
      setIsFilterReflection(_isFilterReflection)

      if (onDataTestsRequestEnd) {
        onDataTestsRequestEnd(
          _sortBy,
          _isFilterLuscher,
          _isFilterBig5,
          _isFilterReflection,
          _isFilterTracksMood,
        )
      }
    }
  }, [dataTests])

  useEffect(() => {
    if (dataTests) {
      setValue('sort_by', sortBy.toString())
      setValue('filter_is_luscher_active', isFilterLuscher.toString())
      setValue('filter_is_big5_active', isFilterBig5.toString())
      setValue('filter_is_reflection_active', isFilterReflection.toString())
      setValue('filter_is_riffs_mood_active', isFilterTracksMood.toString())
    }
  }, [isFilterLuscher, isFilterBig5, isFilterReflection, isFilterTracksMood, sortBy])

  useEffect(() => {
    setSortBy(sortByIndex === 1 ? TracksType.MOOD : TracksType.CHARACTER)
    if (sortByIndex === 1) {
      setIsFilterTracksMood(true)
    }
  }, [sortByIndex])

  return (
    <StyledPopup.Popup
      open={open}
      closeOnDocumentClick
      onClose={onClose}
      position="right center"
    >
      <PopupInner
        title="Filters"
        closeModal={closeModal}
      >
        <Spacer space={16} />
        <Text size={TextSize.SUBHEADLINE_15} color={'#747474'}>
          Match by...
        </Text>
        <Spacer space={8} />
          <SectionNav
            isDisabled={!dataTests?.are_tracks_mood_full}
            section={sortByIndex}
            onStepChange={setSortByIndex}
            buttonsData={[
              {label: 'Character', index: 0},
              {label: 'Mood', index: 1},
            ]}
          />
        {!dataTests?.are_tracks_mood_full && (
          <>
            <Spacer space={8} />
            <Text size={TextSize.CAPTION2_11} color={'#747474'}>
              Choose Mood riffs to match by Mood
            </Text>
          </>
        )}
        <Spacer space={16} />
        <Text size={TextSize.SUBHEADLINE_15} color={'#747474'}>
          Your match should have...
        </Text>
        <Spacer space={8} />

        <Border text={'Character'} padding={12} >
          <Checkbox
            isDisabled={true}
            disabledText={'This filter is always applied'}
            label={'Riffs'}
            checked
          />
          <Spacer space={8} />
          <Checkbox
            isDisabled={!dataTests?.is_big5_done}
            disabledText={'Pass Big 5 test to apply this filter'}
            label={'Big 5'}
            checked={isFilterBig5}
            onCheckboxClick={() => { setIsFilterBig5((prev) => !prev) }}
          />
        </Border>
        <Spacer space={24} />

        <Border text={'Mood'} padding={12}>
          <Checkbox
            isDisabled={!dataTests?.are_tracks_mood_full || sortBy === TracksType.MOOD}
            disabledText={
              sortBy === TracksType.MOOD
                ? 'This filter is always applied when matching by Mood'
                : 'Choose Mood riffs to apply this filter'}
            label={'Riffs'}
            checked={isFilterTracksMood}
            onCheckboxClick={() => { setIsFilterTracksMood((prev) => !prev) }}
          />
          <Spacer space={8} />
          <Checkbox
            isDisabled={(dataTests?.luscher_test_expires_in_seconds ?? 0) === 0}
            disabledText={'Pass Luscher test to apply this filter '}
            label={'Luscher'}
            checked={isFilterLuscher}
            onCheckboxClick={() => { setIsFilterLuscher((prev) => !prev) }}
          />
        </Border>
        <Spacer space={24} />

        <Border text={'Visual'} padding={12} >
          <Checkbox
            isDisabled={!dataTests?.image_reflection_base64}
            disabledText={'Create Reflection to apply this filter '}
            label={'Reflection'}
            checked={isFilterReflection}
            onCheckboxClick={() => { setIsFilterReflection((prev) => !prev) }}
          />
        </Border>
      </PopupInner>
    </StyledPopup.Popup>
  )
}
