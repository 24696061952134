import { CollapsingBlock as CollapsingBlockBase } from '@component/CollapsingBlock'
import { Flex, Text } from '@uiKit'
import { FlexAlignItems, FlexJustifyContent } from '@uiKit/Flex/types'
import { styled } from 'styled-components'

const InfoBlock = styled(Flex).attrs({
  justifyContent: FlexJustifyContent.CENTER,
  alignItems: FlexAlignItems.CENTER,
})`
  height: 304px;
`

const TextComplete = styled(Text)`
  flex: 1;
  margin-top: auto;
`

const CollapsingBlock = styled(CollapsingBlockBase)`
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  border-radius: ${({ theme }) => theme.radiuses.df}px;
  padding: 8px;
`

export const StyledNotCompletedSubset = {
  InfoBlock,
  TextComplete,
  CollapsingBlock,
}
