import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type GetReflectionRequest,
  type GetReflectionResponse
} from './types'

export const getReflection = async (
  data?: GetReflectionRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetReflectionResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/reflection/get_v2',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
    params: data,
  })
}
