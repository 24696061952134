import { type Images } from '@uiKit/Icon/constants/images'

export enum ImageSizes {
  MEDIUM = 1,
  SMALL,
  LARGE,
  FULL_WIDTH,
}

export interface ImageProps {
  src?: string
  srcPreview?: string
  size?: ImageSizes
  alt?: string
  showBorder?: boolean
  ratio?: string
  className?: string
  borderRadius?: number
  defaultIcon?: Images
}
