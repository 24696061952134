import React, { forwardRef, useEffect, useState } from 'react'

import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { StyledImage } from '@uiKit/Image/styled'
import { ImageSizes } from '@uiKit/Image/types'

import { StyledClientImage } from './styled'
import { type ClientImageProps } from './types'

export const ClientImage = forwardRef<HTMLImageElement, ClientImageProps>(({
    isLoading,
    src,
    size = ImageSizes.FULL_WIDTH,
    alt,
    showBorder,
    ratio = 'auto',
    className,
    borderRadius,
    fullImageHeight,
}, ref) => {
  const [isSrcValid, setIsSrcValid] = useState(!!src)

  useEffect(() => {
    if (src) {
      setIsSrcValid(!!src)
    }
  }, [src])

  return (
    <StyledImage.Wrapper
      $size={size}
      $showBorder={showBorder && !isSrcValid}
      $ratio={ratio}
      $borderRadius={borderRadius}
      className={className}
    >
      {isSrcValid && (
        <StyledClientImage.Image
          $size={size}
          ref={ref}
          src={src}
          $hidden={isLoading}
          alt={alt}
          $ratio={ratio}
          $borderRadius={borderRadius}
          $fullImageHeight={fullImageHeight}
        />
      )}
      {isLoading && (
        <StyledImage.SkeletonWrapper>
          <StyledImage.Skeleton $ratio={ratio} />
        </StyledImage.SkeletonWrapper>
      )}
      {!isSrcValid && (
        <StyledClientImage.DefaultIcon
          src={getImage(Images.PICTURE)}
          color={'#909090'}
          alt={'default picture'}
          $ratio={ratio}
        />
      )}
    </StyledImage.Wrapper>
  )
})

ClientImage.displayName = 'ClientImage'
