import { ClientImage, Text } from '@uiKit'
import { styled } from 'styled-components'

const TagsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const ProfileImage = styled(ClientImage)`
  position: absolute;
  top: -58px;
  left: calc(50% - 47px);
  width: 94px;
  border-radius: 47px;
`

const NameText = styled(Text)`
  width: 35%;
  word-break: break-word;
`

export const StyledAboutPerson = {
  TagsBlock,
  ProfileImage,
  NameText,
}
