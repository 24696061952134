import styled, { css } from 'styled-components'

import { type HeadingProps, HeadingSize } from './types'

const getHeadingSizeCSS = {
  [HeadingSize.SM]: css`
    font-size: 0.875rem;
  `,
  [HeadingSize.DF]: css`
    font-size: 1rem;
  `,
  [HeadingSize.MD]: css`
    font-size: 1.125rem;
  `,
  [HeadingSize.LG]: css`
    font-size: 1.25rem;
  `,
}

const Main = styled.h1<{
  $size?: HeadingProps['size']
  $fontWeight?: HeadingProps['fontWeight']
  $color?: HeadingProps['color']
  $align?: HeadingProps['align']
}>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  margin: 0;

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

  ${({ $align }) =>
    $align &&
    css`
      text-align: ${$align};
    `}

  ${({ $size }) => $size && getHeadingSizeCSS[$size]}
`

export const StyleHeading = {
  Main,
}
