import React, { useState, type FC, useRef } from 'react'

import { SectionNav } from '@component/SectionNav'
import { Swiper, type SwiperRef, SwiperSlide } from 'swiper/react'

import { ChatSection } from '../ChatSection'

import { StyledChatCard } from './styled'
import { type ChatCardProps } from './types'

export const ChatCard: FC<ChatCardProps> = ({ data, isLoading }) => {
  const [chatSection, setChatSection] = useState(0)
  const swiperRef = useRef<SwiperRef>(null)
  const onStepChange = (step: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(step)
    }
  }
  return (
    <StyledChatCard.Card>
      <SectionNav
        section={chatSection}
        onStepChange={onStepChange}
        buttonsData={[
          { label: 'Chats', index: 0 },
          { label: 'Likes', index: 1 },
          { label: 'Waitlist', index: 2 },
        ]}
      />
      <Swiper
        ref={swiperRef}
        onSlideChange={(slider) => { setChatSection(slider.activeIndex) }}
      >
        <SwiperSlide>
          <ChatSection
            users={data?.likes_mutual}
            isLoading={isLoading}
            showChatAction
            infoText={'This is a list of people who have matched with you.'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ChatSection
            users={data?.likes_from}
            isLoading={isLoading}
            infoText={'This is a list of people who liked you.'}
          />
        </SwiperSlide>
        <SwiperSlide>
          <ChatSection
            users={data?.likes_to}
            isLoading={isLoading}
            infoText={'This is a list of people you liked.'}
          />
        </SwiperSlide>
      </Swiper>
    </StyledChatCard.Card>
  )
}
