import React, { type FC } from 'react'

import { useCheckTokenExpiration } from '@hooks/index'
import { router } from '@router/router'
import { RouterProvider } from 'react-router-dom'
import { GlobalStyle } from 'styles/globalStyles'
import { StyledToastContainer } from 'styles/toast'

export const App: FC = () => {
  useCheckTokenExpiration()

  return (
    <>
      <GlobalStyle />
      <RouterProvider router={router} />
      <StyledToastContainer.Container
        position="bottom-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
