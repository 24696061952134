import { Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { styled } from 'styled-components'

const TestCard = styled.div`
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

const Label = styled(Text).attrs({
  size: TextSize.CAPTION1_12,
  fontWeight: 500,
})<{
  $isPassed: boolean
}>`
  padding: 4px 8px;
  border-radius: 19px;
  color: ${({ $isPassed, theme }) =>
    $isPassed ? theme.colors.base.dark : theme.colors.base.light
  };
  background-color: ${({ $isPassed, theme }) =>
    $isPassed ? '#3FFF3B' : theme.colors.base.main
  };
  transition: background-color .5s;
`

const ButtonWrapper = styled.div<{
  $isVisible: boolean
}>`
  height: ${({ $isVisible }) => $isVisible ? '52px' : 0};
  visibility: ${({ $isVisible }) => $isVisible ? 'visible' : 'hidden'};
  transition: all .5s;
  overflow: hidden;
  padding: 0 16px 16px 16px;
`

const InfoWrapper = styled.div`
  padding: 16px 16px 0 16px;
`

const TestCardHeading = styled(Text)`
  text-wrap: nowrap;
`

export const StyledReflectionInfo = {
  TestCard,
  Label,
  ButtonWrapper,
  InfoWrapper,
  TestCardHeading,
}
