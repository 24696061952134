import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type RefreshTokenRequest,
  type RefreshTokenResponse,
  type VerifyTokenRequest,
  type VerifyTokenResponse,
} from './types'

export const verifyToken = async (
  data?: VerifyTokenRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<VerifyTokenResponse>> => {
  const { baseUrl = '' } = options ?? {}

  return await request({
    url: '/api/app/frontend/auth/jwt/verify',
    baseUrl,
    method: HTTPMethods.POST,
    data,
  })
}

export const refreshToken = async (
  data?: RefreshTokenRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<RefreshTokenResponse>> => {
  const { baseUrl = '' } = options ?? {}

  return await request({
    url: '/api/app/frontend/auth/jwt/refresh',
    baseUrl,
    method: HTTPMethods.POST,
    data,
  })
}
