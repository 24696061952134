import React, { useState, type FC, useRef } from 'react'

import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { CollapsingBlock } from '@component/CollapsingBlock'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { SectionNav } from '@component/SectionNav'
import { StyledMatching } from '@pages/Matching/styled'
import { Spacer, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { type SwiperRef } from 'swiper/react'

import { type BlockRiffsProps } from './types'

export const BlockRiffs: FC<BlockRiffsProps> = ({
    partnerTracks,
    partnerTracksMood,
    isLoading,
    partnerName,
}) => {
  const [section, setSection] = useState(0)
  const swiperRef = useRef<SwiperRef>(null)
  const onStepChange = (step: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(step)
    }
  }
  return (
    <StyledMatching.Block>
      <CollapsingBlock
        blockName={'Riffs'}
        headingText={partnerName
          ? partnerName + (partnerName.endsWith('s') ? '\'' : '\'s') + ' riffs'
          : 'Partner\'s riffs'
        }
      >
        <Spacer space={16} />
        <SectionNav
          section={section}
          onStepChange={onStepChange}
          buttonsData={[
            { label: 'Character', index: 0 },
            { label: 'Mood', index: 1 },
          ]}
        />
        <Spacer space={8} />
        <StyledMatching.Swiper
          spaceBetween={16}
          ref={swiperRef}
          onSlideChange={(slider) => { setSection(slider.activeIndex) }}
        >
          <StyledMatching.SwiperSlide>
            {isLoading ? (
              <AudioCardSkeleton count={5} />
            ) : (partnerTracks?.length ?? 0) > 0 ? (
              <MusicCardList>
                {partnerTracks?.map((
                  {
                    artist_name: author,
                    name,
                    spotify_preview_link: src,
                    spotify_id: id,
                    spotify_label64_link: musicImageSrc,
                    spotify_link: spotifyLink,
                  }) => (
                    <MusicCard
                      key={id}
                      musicImageSrc={musicImageSrc}
                      title={name}
                      author={author}
                      src={src}
                      spotifyLink={spotifyLink}
                      spotifyId={id}
                    />
                  )
                )}
              </MusicCardList>
            ) : (
              <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
                <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
                Partner does not have any Character riffs.
              </Text>
            )}
          </StyledMatching.SwiperSlide>
          <StyledMatching.SwiperSlide>
            {isLoading ? (
              <AudioCardSkeleton count={3} />
            ) : (partnerTracksMood?.length ?? 0) > 0 ? (
              <MusicCardList>
                {partnerTracksMood?.map((
                  {
                    artist_name: author,
                    name,
                    spotify_preview_link: src,
                    spotify_id: id,
                    spotify_label64_link: musicImageSrc,
                    spotify_link: spotifyLink,
                  }) => (
                    <MusicCard
                      key={id}
                      musicImageSrc={musicImageSrc}
                      title={name}
                      author={author}
                      src={src}
                      spotifyLink={spotifyLink}
                      spotifyId={id}
                    />
                  )
                )}
              </MusicCardList>
            ) : (
              <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
                <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
                Partner does not have any Mood riffs.
              </Text>
            )}
          </StyledMatching.SwiperSlide>
        </StyledMatching.Swiper>
      </CollapsingBlock>
    </StyledMatching.Block>
  )
}
