import React, { type FC, type PropsWithChildren } from 'react'

import { StyledCard } from './styled'
import { type CardProps } from './types'

export const Card: FC<PropsWithChildren<CardProps>> = ({
  className,
  children,
  padding = 0,
  showBackground = false,
  fullHeight = false,
}) => {
  return (
    <StyledCard.Wrapper
      className={className}
      $padding={padding}
      $showBackground={showBackground}
      $fullHeight={fullHeight}
    >
      {children}
    </StyledCard.Wrapper>
  )
}
