import React, { type FC } from 'react'

import { Card } from '@component/Card'
import { LogoHeader } from '@component/LogoHeader'
import { useAuthToken } from '@hooks/useAuthToken'
import { parseJWT } from '@lib/jwt'
import { ROUTES } from '@router/constants'
import * as Sentry from '@sentry/react'
import { Flex, Spacer, Text } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'
import { Navigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { SignInForm } from './components'
import { useSignInForm } from './hooks/useSignInForm'

export const SignIn: FC = () => {
  const theme = useTheme()
  const { getAccessToken } = useAuthToken()

  const { form, onSubmit } = useSignInForm()

  if (getAccessToken()) {
    const { getAccessToken } = useAuthToken()
    const token = getAccessToken()
    if (token) {
      Sentry.setUser({
        id: parseJWT(token).user_id,
      })
    }

    return <Navigate to={ROUTES.PROFILE.ROOT} />
  }

  return (
    <AppLayout
      header={<LogoHeader />}
      contentStyles={{paddingBottom: 90, justifyContent: 'center'}}
    >
      <Card padding={24} >
        <Flex fullWidth direction={FlexDirection.COLUMN}>
          <Text
            fontWeight={600}
            color={theme.colors.additional.dark_ocean}
            size={TextSize.TITLE3_20}
            align={TextAlign.CENTER}
          >
            Sign in
          </Text>
          <Spacer space={theme.spacings.x16} />
          <SignInForm form={form} onSubmit={onSubmit} />
        </Flex>
      </Card>
    </AppLayout>
  )
}
