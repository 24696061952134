import React, { type FC } from 'react'

import big5Section from '@assets/images/big5Section.png'
import defaultReflectionSection from '@assets/images/defaultReflectionSection.svg'
import luscherSection from '@assets/images/luscherSection.png'
import { Timer } from '@component/Timer'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { generalProfileInfo } from '@lib/api/rest/profile/generalProfileInfo'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import { StyledProfileSection } from '@pages/Profile/components/ProfileSection/styled'
import { TextBackgroundType } from '@pages/Profile/components/ProfileSection/types'
import { getLusherTestExpireDate } from '@pages/Profile/helpers'
import { ROUTES } from '@router/constants'
import { Flex, Icon, Spacer } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { ProfileSection } from 'pages/Profile/components/ProfileSection'
import { useTheme } from 'styled-components'

import { StyledProfileCard } from './styled'

export const ProfileCard: FC = () => {
  const theme = useTheme()
  const { redirect } = useRedirect()
  const { data, isLoading } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo
  }, {
    runOnMount: true
  })
  return (
    <StyledProfileCard.Card>
      <StyledProfileCard.Border text={'Profile'} $isHorizontal={true}>
        <ProfileSection
          isLoading={isLoading}
          mainTextBlock={
            <StyledProfileSection.MainTextBlock>
              <StyledProfileSection.MainText>Edit</StyledProfileSection.MainText>
              <Spacer space={8} />
              <Icon
                image={Images.EDIT}
                size={IconSize.X8}
                color={theme.colors.base.dark}
                label={'Edit'}
              />
            </StyledProfileSection.MainTextBlock>
          }
          additionalTextType={TextBackgroundType.PREMIUM}
          additionalText={'Premium'}
          backgroundImage={
            isLoading ? defaultReflectionSection : undefined
          }
          backgroundImageBase64={
            !isLoading ? data?.profile_image_base64 : undefined
          }
          onSectionClick={() => {
            redirect(ROUTES.PROFILE.EDIT)
          }}
        />
        <ProfileSection
          isLoading={isLoading}
          isDisabled={!data?.are_tracks_full}
          mainText={'Reflection'}
          backgroundImage={!data?.image_reflection_base64 ? defaultReflectionSection : undefined}
          backgroundImageBase64={
            data?.image_reflection_base64 ? data?.image_reflection_base64 : undefined
          }
          tagText={data?.image_reflection_base64 ? 'done' : 'optional'}
          tagTextBackgroundType={
            data?.image_reflection_base64 ? TextBackgroundType.DONE : TextBackgroundType.AVAILABLE
          }
          onSectionClick={() => {
            redirect(ROUTES.REFLECTION.INFO)
          }}
        />
      </StyledProfileCard.Border>
      <Flex direction={FlexDirection.ROW} fullWidth gap={16}>
        <StyledProfileCard.Border text={'Character'} $isHorizontal={false}>
          <ProfileSection
            isLoading={isLoading}
            mainText={'Riffs'}
            tagText={'done'}
            tagTextBackgroundType={TextBackgroundType.DONE}
            onSectionClick={() => {
              redirect(ROUTES.TRACKS.SEARCH.INFO)
            }}
            backgroundComponent={
              <StyledProfileCard.RiffsBackgroundWrapper>
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_labels[0].spotify_label64_link}
                  $left={53}
                  $top={16}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_labels[1].spotify_label64_link}
                  $left={35}
                  $top={27}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_labels[2].spotify_label64_link}
                  $left={16}
                  $top={41}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_labels[3].spotify_label64_link}
                  $left={35}
                  $top={62}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_labels[4].spotify_label64_link}
                  $left={53}
                  $top={isLoading ? 0 : 79}
                />
              </StyledProfileCard.RiffsBackgroundWrapper>
            }
          />
          <ProfileSection
            isLoading={isLoading}
            isDisabled={!data?.are_tracks_full}
            mainText={'Big 5'}
            tagText={
              data?.is_big5_done ? 'done' : 'optional'
            }
            tagTextBackgroundType={
              data?.is_big5_done ? TextBackgroundType.DONE : TextBackgroundType.AVAILABLE
            }
            backgroundImage={big5Section}
            onSectionClick={() => {
              if (data?.is_big5_done) {
                redirect(ROUTES.BIG5.RESULTS)
              } else {
                redirect(ROUTES.BIG5.TEST)
              }
            }}
          />
        </StyledProfileCard.Border>
        <StyledProfileCard.Border text="Mood" $isHorizontal={false}>
          <ProfileSection
            isLoading={isLoading}
            mainText={'Riffs'}
            tagText={
              data?.are_tracks_mood_full ? 'done' : 'optional'
            }
            tagTextBackgroundType={
              data?.are_tracks_mood_full ? TextBackgroundType.DONE : TextBackgroundType.AVAILABLE
            }
            onSectionClick={() => {
              redirect(ROUTES.TRACKS.SEARCH.INFO_MOOD)
            }}
            backgroundComponent={
              <StyledProfileCard.RiffsBackgroundWrapper>
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_mood_labels[0]?.spotify_label64_link}
                  $left={35}
                  $top={27}
                  defaultIcon={Images.QUESTION}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_mood_labels[1]?.spotify_label64_link}
                  $left={16}
                  $top={41}
                  defaultIcon={Images.QUESTION}
                />
                <StyledProfileCard.RiffsBackgroundImage
                  src={data?.tracks_mood_labels[2]?.spotify_label64_link}
                  $left={35}
                  $top={62}
                  defaultIcon={Images.QUESTION}
                />
              </StyledProfileCard.RiffsBackgroundWrapper>
            }
          />
          <ProfileSection
            isLoading={isLoading}
            mainText={'Luscher'}
            tagText={
              data?.luscher_test_expires_in_seconds === 0 ? 'optional' : (
                <Timer
                  expiredDate={getLusherTestExpireDate(data?.luscher_test_expires_in_seconds ?? 0)}
                />
              )
            }
            tagTextBackgroundType={
              data?.luscher_test_expires_in_seconds === 0
                ? TextBackgroundType.AVAILABLE
                : TextBackgroundType.DONE
            }
            backgroundImage={luscherSection}
            onSectionClick={() => { redirect(ROUTES.LUSCHER.TEST) }}
          />
        </StyledProfileCard.Border>
      </Flex>
    </StyledProfileCard.Card>
  )
}
