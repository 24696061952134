import React, { type FC } from 'react'

import { Header } from '@component/Header'
import { Navbar } from '@component/Navbar'
import { useGoBack } from '@hooks/useGoBack'
import { Images } from '@uiKit/Icon/constants/images'
import { AppLayout } from 'layouts/AppLayout'

import { SimilarMusicCard } from './components'

export const SimilarTracks: FC = () => {
  const { goBack } = useGoBack()
  return (
    <AppLayout
      header={
        <Header
          title={'Similar riffs'}
          showLeftButton
          showRightButton
          leftButton={{
            icon: Images.BACK,
            onClick: goBack
          }}
        />
      }
      navbar={<Navbar />}
    >
      <SimilarMusicCard />
    </AppLayout>
  )
}
