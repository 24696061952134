import React, { useState, type FC } from 'react'

import chart from '@assets/images/chart.svg'
import { Big5Sliders } from '@component/Big5Sliders'
import { CollapsingBlock } from '@component/CollapsingBlock'
import { StyledMatching } from '@pages/Matching/styled'
import { Spacer, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'
import {Swiper, SwiperSlide} from 'swiper/react'

import { Compatibilities } from './components/Compatibilities/Compatibilities'
import { StyledBlockCompatibilities } from './styled'
import { type BlockCompatibilitiesProps } from './types'
import 'swiper/css'

export const BlockCompatibilities: FC<BlockCompatibilitiesProps> = ({
    isLoading,
    compatibilities,
    partner,
    currentUser,
}) => {
  const theme = useTheme()
  const [currentPage, setCurrentPage] = useState(0)
  const headings = ['Compatibilities', 'Big 5', 'Riffs']
  return (
    <StyledMatching.Block>
      <CollapsingBlock blockName={'compatibilities'} headingText={headings[currentPage]}>
        <Spacer space={theme.spacings.x8} />
        <Swiper onSlideChange={(slider) => { setCurrentPage(slider.activeIndex) }}>
          <StyledBlockCompatibilities.SwiperSlide>
            {isLoading && (
              <StyledBlockCompatibilities.SkeletonLoadingOverlay height={'156px'} />
            )}
            <Compatibilities compatibilities={compatibilities} />
          </StyledBlockCompatibilities.SwiperSlide>
          {currentUser.big5Traits && partner.big5Traits && (
            <SwiperSlide>
              {isLoading && (
                <StyledBlockCompatibilities.SkeletonLoadingOverlay height={'156px'} />
              )}
              <Big5Sliders
                currentUser={{ big5Traits: currentUser.big5Traits, image: currentUser.image }}
                partner={{ big5Traits: partner.big5Traits, image: partner.image }}
              />
            </SwiperSlide>
          )}
          {(!currentUser.big5Traits || !partner.big5Traits) && (
            <StyledBlockCompatibilities.SwiperSlideBig5NotPassed>
              {isLoading && (
                <StyledBlockCompatibilities.SkeletonLoadingOverlay height={'156px'} />
              )}
              <StyledBlockCompatibilities.Big5NotPassedWrapper>
                <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
                  <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
                  Both you and partner have to pass Big 5 test to see this section.
                </Text>
              </StyledBlockCompatibilities.Big5NotPassedWrapper>
            </StyledBlockCompatibilities.SwiperSlideBig5NotPassed>
          )}
          <SwiperSlide>
            {isLoading && (
              <StyledBlockCompatibilities.SkeletonLoadingOverlay height={'156px'} />
            )}
            <StyledBlockCompatibilities.Image src={chart} />
          </SwiperSlide>
        </Swiper>
        <StyledBlockCompatibilities.PageNumberIndication
          currentPage={currentPage + 1}
          totalPages={3}
          activeIndicatorColor={theme.colors.base.dark}
        />
      </CollapsingBlock>
    </StyledMatching.Block>
  )
}
