import { ClientImage, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const Form = styled.form`
  padding: 24px;
  color: ${({ theme }) => theme.colors.base.blueGrey};
`

const HiddenFileInput = styled.input`
  display: none;
`

const LoadingImageSkeleton = styled(Skeleton)`
  width: 100%;
  aspect-ratio: 1;
`

const RequiredText = styled(Text).attrs(() => ({
  size: TextSize.CAPTION2_11,
  color: 'danger',
}))`
  padding-left: 12px;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const Image = styled(ClientImage)`
  width: 60%;
`

export const StyledProfileEditCard = {
  Form,
  HiddenFileInput,
  LoadingImageSkeleton,
  RequiredText,
  ImageWrapper,
  Image,
}
