import React, { type FC, useState } from 'react'

import { Icon, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { useCollapse } from 'react-collapsed'
import { useTheme } from 'styled-components'

import { StyledZodiac } from './styled'
import { getZodiac, type ZodiacProps } from './types'

export const Zodiac: FC<ZodiacProps> = ({value, onChange}) => {
  const theme = useTheme()
  const [isExpanded, setIsExpanded] = useState(false)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded
  })
  return (
    <>
      <StyledZodiac.Heading
        $isExpanded={isExpanded}
        {...getToggleProps({onClick: () => { setIsExpanded((prev) => !prev) }})}
      >
        <Icon
          color={isExpanded ? theme.colors.base.light : '#A2A2A2'}
          image={getZodiac(value)?.image}
        />
        <Text>{getZodiac(value)?.text}</Text>
        <Icon
          color={isExpanded ? theme.colors.base.light : theme.colors.base.dark}
          image={
            isExpanded
              ? Images.ARROW_UP_COLLAPSE_MENU
              : Images.ARROW_DOWN_COLLAPSE_MENU
          }
        />
      </StyledZodiac.Heading>
      <section {...getCollapseProps()}>
        {Array(13).fill(1).map((e, i) => i + 1).map((index) => (
          <StyledZodiac.Item
            key={index}
            $isLast={index === 13}
            onClick={() => { onChange(index - 1); setIsExpanded(false) }}
          >
            <StyledZodiac.Icon image={getZodiac(index)?.image} />
            <StyledZodiac.Text>{getZodiac(index)?.text}</StyledZodiac.Text>
          </StyledZodiac.Item>
        ))}
      </section>
    </>
  )
}
