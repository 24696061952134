import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type CRTRequest, type CRTResponse } from './types'

export const getCRT = async (
  data?: CRTRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<CRTResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/matching/get_crt',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}
