import { useCallback, useEffect } from 'react'

import { addUserTrack, addUserTrackMood, removeUserTrack, removeUserTrackMood } from '@lib/api'
import {
  type RemoveUserTrackResponse,
  type AddUserTrackRequest,
  type AddUserTrackResponse,
  type RemoveUserTrackRequest,
} from '@lib/api/rest/user/tracks/types'

import { useAPI } from '..'

import { type useMusicActionsData } from './types'

export const useMusicActions = (data?: useMusicActionsData) => {
  const { onAdd, onRemove, tracksType } = data ?? {}

  const {
    runRequest: addTrackRequest,
    isError: isAddingMusicError,
    data: addUserTrackData,
    isLoading: isLoadingAddTrack,
  } = useAPI<AddUserTrackRequest, AddUserTrackResponse>({
    apiService: tracksType === 'character' ? addUserTrack : addUserTrackMood,
  })

  const {
    runRequest: removeTrackRequest,
    isError: isRemovingMusicError,
    data: removingMusicData,
    isLoading: isLoadingRemoveTrack,
  } = useAPI<RemoveUserTrackRequest, RemoveUserTrackResponse>({
    apiService: tracksType === 'character' ? removeUserTrack : removeUserTrackMood,
  })

  useEffect(() => {
    if (!isAddingMusicError && addUserTrackData?.track) {
      onAdd?.(addUserTrackData?.track)
    }
  }, [isAddingMusicError, addUserTrackData])
  useEffect(() => {
    if (!isRemovingMusicError && removingMusicData?.track) {
      onRemove?.(removingMusicData?.track)
    }
  }, [isRemovingMusicError, removingMusicData])

  const addMusic = useCallback(
    (id: string) => {
      addTrackRequest({ spotify_id: id })
    },
    [addTrackRequest]
  )

  const removeMusic = useCallback(
    (id: string) => {
      removeTrackRequest({ spotify_id: id })
    },
    [removeTrackRequest]
  )

  return { addMusic, removeMusic, isLoadingAddTrack, isLoadingRemoveTrack }
}
