import React, {
  type PropsWithChildren,
  type FC,
  Children,
  Fragment,
} from 'react'

import { MenuDivider } from '@szhsin/react-menu'
import { Icon } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { useTheme } from 'styled-components'

import { StyledAudioCardDropDownMenu } from './styled'

export const AudioCardDropDownMenu: FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme()

  const filteredChildren = Children.toArray(children).filter(Boolean)

  return (
    <StyledAudioCardDropDownMenu.Menu
      align={'end'}
      shift={12}
      viewScroll={'auto'}
      position={'anchor'}
      menuButton={
      <StyledAudioCardDropDownMenu.MenuButton>
        <Icon
          aria-hidden={true}
          color={theme.colors.base.main}
          image={Images.TRACK_MENU}
          size={IconSize.X24}
        />
      </StyledAudioCardDropDownMenu.MenuButton>}
    >
      {filteredChildren.map((child, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            {child}
            {index !== filteredChildren.length - 1 && (
              <MenuDivider />
            )}
          </Fragment>
        )
      })}
    </StyledAudioCardDropDownMenu.Menu>
  )
}
