import React, { forwardRef } from 'react'

import { getHeadingSize, getHeadingTag } from './helpers'
import { StyleHeading } from './styled'
import { type HeadingProps, HeadingType } from './types'

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  (
    {
      type = HeadingType.H1,
      size,
      fontWeight = 600,
      color,
      align,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <StyleHeading.Main
        {...props}
        as={getHeadingTag(type) as undefined}
        $color={color}
        $fontWeight={fontWeight}
        $size={size ?? getHeadingSize(type)}
        $align={align}
        ref={ref}
      >
        {children}
      </StyleHeading.Main>
    )
  }
)

Heading.displayName = 'Heading'
