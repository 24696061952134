import { Flex } from '@uiKit'
import { css, styled } from 'styled-components'

const Main = styled(Flex)`
  justify-content: center;
  padding: 24px 16px 32px 16px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: -webkit-fill-available;
`

export const activeIndicator = css`
  width: 12px;
  height: 12px;
`

export const inactiveIndicator = css`
  width: 8px;
  height: 8px;
`

const Indicator = styled.div<{
  $isActive?: boolean
  $activeColor?: string
}>`
  margin-top: auto;
  margin-bottom: auto;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  ${({$isActive}) => $isActive ? activeIndicator : inactiveIndicator}
  background-color: ${({$isActive, $activeColor, theme}) =>
    $isActive
      ? ($activeColor ?? '#E9E9E9')
      : theme.colors.landing.inactive
  }
`

export const PageNumberIndicationStyled = {
  Main,
  Indicator,
}
