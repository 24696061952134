import React, { useState, type FC, useEffect } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { useAPI } from '@hooks/useAPI'
import { getChat, resetLikes } from '@lib/api/rest/chat'
import { type GetChatRequest, type GetChatResponse } from '@lib/api/rest/chat/chat/types'
import {
  type ResetLikesRequest,
  type ResetLikesResponse
} from '@lib/api/rest/chat/resetLikes/types'
import { Spacer, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { ChatCard } from './components/ChatCard'

export const Chat: FC = () => {
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }

  const { data, runRequest, isLoading } = useAPI<GetChatRequest, GetChatResponse>({
    apiService: getChat
  }, {
    runOnMount: true
  })

  const {
    runRequest: runRequestReset,
    data: dataReset,
  } = useAPI<ResetLikesRequest, ResetLikesResponse>({
    apiService: resetLikes,
  },)

  const onResetLikes = () => {
    runRequestReset({})
  }

  useEffect(() => {
    if (dataReset) {
      runRequest({})
      closeModal()
    }
  }, [dataReset])

  return (
    <AppLayout
    header={
      <LogoHeader
        showSecondButton
        secondButton={{
          icon: Images.TRASH,
          onClick: () => { setOpen(prev => !prev) }
        }}
      />
    }
      navbar={<Navbar />}
    >
      <StyledPopup.Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        position="right center"
      >
        <PopupInner
          title="Reset likes?"
          closeModal={closeModal}
          onButtonClick={() => { onResetLikes() }}
          showButton
          buttonText="Reset likes"
        >
          <Spacer space={16} />
          <Text size={TextSize.SUBHEADLINE_15} color={'#5D5D5D'}>
            This action will <b>reset all your “likes”</b> to people but it will <b>not
            reset “likes” from other people</b> to you.
          </Text>
          <Spacer space={16} />
        </PopupInner>
      </StyledPopup.Popup>
      <ChatCard data={data} isLoading={isLoading} />
    </AppLayout>
  )
}
