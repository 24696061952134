import React, { type FC, useMemo } from 'react'

import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { useAPI } from '@hooks/useAPI'
import { useMusicList } from '@hooks/useMusicList'
import { type PRTResponse } from '@lib/api/rest/tracks/PRT/types'
import { SPACINGS } from '@lib/theme/constants'
import { StyledPRTList } from '@pages/Tracks/pages/PRT/components/PRTList/styled'
import { Spacer } from '@uiKit'
import { AudioListProvider } from 'providers'

import { type PRTListProps } from './types'

export const PRTList: FC<PRTListProps> = ({
    getPRT,
}) => {
  const {data, isLoading, isError} = useAPI<undefined, PRTResponse>({
    apiService: getPRT,
  }, {
    runOnMount: true
  })

  const tracksList = useMemo(
    () => data?.personally_recommended_tracks.map((trackObject) => trackObject.track),
    [data?.personally_recommended_tracks]
  )
  const { tracks } = useMusicList(tracksList)

  const audios = useMemo(
    () =>
      data?.personally_recommended_tracks
        ? [
            ...data.personally_recommended_tracks.map(
              (track) => new Audio(track.track.spotify_preview_link)
            ),
          ]
        : [],
    [data?.personally_recommended_tracks]
  )

  return (
    <>
      <Spacer space={SPACINGS.X16} />
      <StyledPRTList.Text>Based on your riffs, here are our recommendations.</StyledPRTList.Text>
      <Spacer space={SPACINGS.X16} />
      {isLoading || !data || isError || !tracks ? (
        <AudioCardSkeleton count={10} spacing={SPACINGS.X16} />
      ) : (
        <AudioListProvider audios={audios}>
          <MusicCardList>
            {tracks.map(
              ({
                 artist_name: artistName,
                 spotify_preview_link: spotifyPreviewLink,
                 name,
                 spotify_id: spotifyId,
                 spotify_label64_link: musicImageSrc,
                 is_added: isAdded,
                 spotify_link: spotifyLink,
               }) => (
                <MusicCard
                  musicImageSrc={musicImageSrc}
                  key={spotifyId}
                  title={name}
                  author={artistName}
                  spotifyLink={spotifyLink}
                  spotifyId={spotifyId}
                  src={spotifyPreviewLink}
                />
              )
            )}
          </MusicCardList>
        </AudioListProvider>
      )}
      <Spacer space={SPACINGS.X16} />
    </>
  )
}
