import { css, styled } from 'styled-components'

import {LinkDirection, type LinkProps, LinkSize} from './types'

export const getLinkSizeCSS = {
  [LinkSize.SM]: css`
    font-size: 0.75rem;
  `,
  [LinkSize.DF]: css`
    font-size: 0.875rem;
  `,
  [LinkSize.MD]: css`
    line-height: 1rem;
  `,
  [LinkSize.LG]: css`
    font-size: 1.25rem;
  `,
}
export const getLinkDirectionCSS = {
  [LinkDirection.ROW]: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  [LinkDirection.COLUMN]: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
  `,
}
const Link = styled.a<{
  $color?: LinkProps['color']
  $showBorder: LinkProps['showBorder']
  $size: LinkProps['size']
  $direction: LinkProps['direction']
}>`
  text-decoration: none;
  outline: none;
  background: none;
  cursor: pointer;
  -o-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${({ theme, $color, $showBorder }) =>
    $color &&
    css`
      color: ${$color};
      border-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      border-top: 0;
      border-right: 0;
      border-left: 0;

      ${$showBorder &&
      css`
        border-bottom: 1px solid ${$color};
        padding-bottom: ${theme.spacings.x4}px;
      `}

      &:focus-visible {
        background-color: ${theme.colors.focus};
        outline: none;
      }
    `}
 
  ${({ $size }) => $size && getLinkSizeCSS[$size]};
  ${({ $direction }) => $direction && getLinkDirectionCSS[$direction]};
`

export const StyledLink = {
  Link,
}
