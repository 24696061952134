import React, { useState, type FC } from 'react'

import { FilterPopup } from '@component/FilterPopup'
import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useRedirect } from '@hooks/useRedirect'
import { theme } from '@lib/theme'
import { TracksType } from '@pages/Tracks/pages/types'
import { ROUTES } from '@router/constants'
import { Button, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledNoUsersForMatching } from './styled'

export const NoUsersForMatching: FC = () => {
  const { redirect } = useRedirect()
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }
  const [isFilterLuscher, setIsFilterLuscher] = useState(false)
  const [isFilterBig5, setIsFilterBig5] = useState(false)
  const [isFilterReflection, setIsFilterReflection] = useState(false)
  const [isFilterRiffsMood, setIsFilterRiffsMood] = useState(false)
  const [isMatchByMood, setIsMatchByMood] = useState(TracksType.CHARACTER)

  return (
    <AppLayout
      header={
        <LogoHeader showSecondButton
          secondButton={{
            icon: Images.FILTER,
            color: '#565656',
            onClick: () => { setOpen(prev => !prev) }
          }}
        />}
      navbar={<Navbar />}
    >
      <FilterPopup
        isFilterLuscher={isFilterLuscher}
        isFilterBig5={isFilterBig5}
        isFilterReflection={isFilterReflection}
        sortBy={isFilterRiffsMood}
        isMatchByMood={isMatchByMood}

        setIsFilterLuscher={setIsFilterLuscher}
        setIsFilterBig5={setIsFilterBig5}
        setIsFilterReflection={setIsFilterReflection}
        setIsFilterTracksMood={setIsFilterRiffsMood}
        setSortBy={setIsMatchByMood}

        open={open}
        onClose={() => {
          redirect(ROUTES.MATCHING.ROOT)
          closeModal()
        }}
        closeModal={closeModal}
      />
      <StyledNoUsersForMatching.Card showBackground padding={24} fullHeight>
        <StyledNoUsersForMatching.WarningIcon
          alt="warning"
          src={getImage(Images.CHECK_CIRCLE)}
        />
        <Spacer space={theme.spacings.x40} />
        <Text
          fontWeight={700}
          size={TextSize.TITLE2_22}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.85)'}
        >
          No users left for matching
        </Text>
        <Spacer space={theme.spacings.x8} />
        <Text
          size={TextSize.SUBHEADLINE_15}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.65)'}
        >
          Wait for the reaction of people you liked
        </Text>
        <Spacer space={theme.spacings.x36} />
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => { redirect(ROUTES.CHAT.ROOT) }}
        >
          Go to Chats
        </Button>
      </StyledNoUsersForMatching.Card>
    </AppLayout>
  )
}
