import { Text as TextBase } from '@uiKit'
import { styled } from 'styled-components'

const Text = styled(TextBase)`
  color: #9B9B9B;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0.06px;
`

export const StyledPRTList = {
  Text,
}
