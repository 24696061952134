import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type CreateReflectionRequest,
  type CreateReflectionResponse,
} from './types'

export const createReflection = async (
  data?: CreateReflectionRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<CreateReflectionResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/reflection/create_v2',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}
