import { Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { css, styled } from 'styled-components'

import { BubbleHorizontalPosition, BubbleVerticalPosition } from './types'

const getBubbleVerticalPositionCSS = {
  [BubbleVerticalPosition.TOP]: css`
    top: -10px;
  `,
  [BubbleVerticalPosition.BOTTOM]: css`
    bottom: -10px;
  `,
}
const getBubbleHorizontalPositionCSS = {
  [BubbleHorizontalPosition.LEFT]: css`
    left: 20px;
  `,
  [BubbleHorizontalPosition.CENTER]: css`
    left:0;
    right: 0;
    margin: 0 auto;
  `,
  [BubbleHorizontalPosition.RIGHT]: css`
    right: 20px;
  `,
}

const BubbleWrapper = styled.div<{
  $positionVertical: BubbleVerticalPosition
  $positionHorizontal: BubbleHorizontalPosition
  $color: string
}>`
  display: inline-block;
  position: relative;
  z-index: 1;

  &:before {
    ${({ $positionVertical }) =>
      $positionVertical && getBubbleVerticalPositionCSS[$positionVertical]}
    ${({ $positionHorizontal }) =>
      $positionHorizontal && getBubbleHorizontalPositionCSS[$positionHorizontal]}
    background-color: ${({ $color }) => $color};
    content: '';
    height: 22px;
    width: 22px;
    position: absolute;
    transform: rotate(45deg);
    z-index: -1;
  }
`

const Bubble = styled(Text).attrs({
  size: TextSize.SUBHEADLINE_15,
  fontWeight: 200,
})<{ $color: string }>`
  background: ${({ $color }) => $color};
  border-radius: 48px;
  display: inline-block;
  padding: 8px 16px;
  color: ${({ theme }) => theme.colors.base.light};
  white-space: nowrap;
`

const Button = styled.button<{
  $size: number
  $color: string
}>`
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border-radius: 48px;
  border: 2px solid ${({ $color }) => $color};
`

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
`

export const StyledBig5StepButtons = {
  BubbleWrapper,
  Bubble,
  Button,
  ButtonsWrapper,
}
