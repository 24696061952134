import { useCallback } from 'react'

import { toast } from 'react-toastify'

import { ToastType, type UseToastOptions } from './types'

export const useToast = () => {
  const notify = useCallback((content: string, options?: UseToastOptions) => {
    const { type } = options ?? {}

    switch (type) {
      case ToastType.INFO:
        toast.info(content)
        break
      case ToastType.WARNING:
        toast.warn(content)
        break
      case ToastType.ERROR:
        toast.error(content)
        break
      case ToastType.SUCCESS:
        toast.success(content)
        break
    }
  }, [])

  return { notify }
}
