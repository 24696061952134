import { Heading as HeadingBase, Text as TextBase } from '@uiKit'
import { styled } from 'styled-components'

const Text = styled(TextBase)`
  color: #9B9B9B;
  text-align: start;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.078px;
`

const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.base.main};
  text-align: start;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.36px;
`

const Spacer = styled.div`
  flex: 1;
`

export const StyledBig5Info = {
  Text,
  Heading,
  Spacer,
}
