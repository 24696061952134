import React, { type FC, useCallback } from 'react'

import searchInfoImageMood from '@assets/images/SearchInfoImageMood.png'
import searchInfoImageMoodPreview from '@assets/images/SearchInfoImageMoodPreview.png'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Image, Button, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledTracksInfo } from './styled'

export const TracksInfoMood: FC = () => {
  const { redirect } = useRedirect()

  const onButtonClick = useCallback(() => {
    redirect(ROUTES.TRACKS.SEARCH.ROOT_MOOD)
  }, [])

  return (
    <AppLayout header={<Header />}>
      <Card padding={24} showBackground>
        <StyledTracksInfo.Heading>
          About Me:<br />My Mood in Music
        </StyledTracksInfo.Heading>
        <Spacer space={SPACINGS.X16} />
        <Text size={TextSize.FOOTNOTE_13} fontWeight={600} color="#9B9B9B">
          Please describe how you feel now in music.
        </Text>
        <Spacer space={SPACINGS.X16} />
        <Image
          alt="riffs search info"
          src={searchInfoImageMood}
          srcPreview={searchInfoImageMoodPreview}
          ratio={(1240 / 1317).toString()}
          borderRadius={16}
        />
        <Spacer space={SPACINGS.X16} />
        <Button
          role="link"
          onClick={onButtonClick}
          variant={ButtonVariant.PRIMARY}
        >
          Begin
        </Button>
      </Card>
    </AppLayout>
  )
}
