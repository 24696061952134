import React, { type FC } from 'react'

import { ROUTES } from '@router/constants'
import { Icon } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { LinkDirection } from '@uiKit/Link/types'
import { NavLink } from 'react-router-dom'
import { useTheme } from 'styled-components'

import { StyledNavbar } from './styled'

export const Navbar: FC = () => {
  const theme = useTheme()

  return (
    <StyledNavbar.Wrapper>
      <nav>
        <StyledNavbar.Ul>
          <StyledNavbar.LI $padding={'0 0 0 48px'}>
            <StyledNavbar.Link
              as={NavLink}
              showBorder={false}
              color={theme.colors.base.grey[350]}
              to={ROUTES.MATCHING.ROOT}
            >
              <Icon
                label="Matching"
                size={IconSize.X20}
                color={theme.colors.base.grey[350]}
                image={Images.MATCHING}
              />
            </StyledNavbar.Link>
          </StyledNavbar.LI>
          <StyledNavbar.LI>
            <StyledNavbar.Link
              as={NavLink}
              direction={LinkDirection.COLUMN}
              showBorder={false}
              to={ROUTES.CHAT.ROOT}
            >
              <Icon
                label="Chat"
                size={IconSize.X20}
                image={Images.CHAT}
              />
            </StyledNavbar.Link>
          </StyledNavbar.LI>
          <StyledNavbar.LI $padding={'0 48px 0 0'}>
            {/* <StyledNavbar.LinkWrapper> */}
              <StyledNavbar.Link
                as={NavLink}
                showBorder={false}
                color={theme.colors.base.grey[350]}
                to={ROUTES.PROFILE.ROOT}
              >
                <Icon
                  label="Home"
                  color={'rgba(0, 0, 0, 0.3)'}
                  size={IconSize.X20}
                  image={Images.HOME}
                />
              </StyledNavbar.Link>
            {/* </StyledNavbar.LinkWrapper> */}
          </StyledNavbar.LI>
        </StyledNavbar.Ul>
      </nav>
    </StyledNavbar.Wrapper>
  )
}
