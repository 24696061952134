import React, { type FC, useCallback, useEffect, useState } from 'react'

import { useAPI } from '@hooks/useAPI'
import { useInterval } from '@hooks/useInterval'
import { useToast } from '@hooks/useToast'
import { ToastType } from '@hooks/useToast/types'
import { combinedImage } from '@lib/api/rest/matching'
import {
  type CombinedImageRequest,
  type CombinedImageResponse,
} from '@lib/api/rest/matching/combinedImage/types'
import { Button, Flex, Spacer, Spinner } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexAlignItems, FlexDirection, FlexJustifyContent, } from '@uiKit/Flex/types'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

import { StyledCombinedImage } from './styled'
import { type CombinedImageProps } from './types'

export const CombinedImage: FC<CombinedImageProps> = ({
    selfImage,
    partnerImage,
    matchId,
    isLoading
}) => {
  const theme = useTheme()
  const { notify } = useToast()

  const [startInterval, setStartInterval] = useState(false)
  const [combinedImageUrl, setCombinedImageUrl] = useState('')
  const onStartInterval = useCallback(() => {
    setStartInterval(true)
  }, [])

  const {
    data: combinedImageData,
    isError: isErrorCombinedImage,
    runRequest: requestCombinedImage,
  } = useAPI<CombinedImageRequest, CombinedImageResponse>({
    apiService: combinedImage,
  })

  useInterval(
    () => {
      if (matchId && startInterval) {
        requestCombinedImage({ candidate_id: matchId })
      }
    },
    combinedImageUrl ? undefined : 1000
  )

  useEffect(() => {
    if (combinedImageData) {
      if (combinedImageData.message === 'Fusion is failed') {
        notify('Sorry, your fusion has failed', {
          type: ToastType.ERROR,
        })
        setStartInterval(false)
      }
      setCombinedImageUrl(combinedImageData.fusion_image_base64)
    }
  }, [combinedImageData])

  useEffect(() => {
    setStartInterval(false)
    setCombinedImageUrl('')
  }, [matchId])

  return (
    <>
      <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
        <Flex
          justifyContent={FlexJustifyContent.SPACE_BETWEEN}
          alignItems={FlexAlignItems.END}
          direction={FlexDirection.ROW}
          flex="1"
        >
          {isLoading ? (
            <Skeleton width={110} height={110} />
          ) : (
            <StyledCombinedImage.Reflection src={`data:image/png;base64,${selfImage}`} />
          )}
          <StyledCombinedImage.Arrow
            src={getImage(Images.ARROW_DOWN_FUSE)}
          />
          {isLoading ? (
            <Skeleton width={110} height={110} />
          ) : (
            <StyledCombinedImage.Reflection src={`data:image/png;base64,${partnerImage}`} />
          )}
        </Flex>
      </Flex>
      <Spacer space={theme.spacings.x16} />
      {!isErrorCombinedImage && (
        <>
          {!startInterval ? (
            <Button
              disabled={isLoading}
              variant={ButtonVariant.PRIMARY}
              onClick={onStartInterval}
            >Fuse</Button>
          ) : !combinedImageUrl ? (
            <Flex
              fullHeight
              justifyContent={FlexJustifyContent.CENTER}
              alignItems={FlexAlignItems.CENTER}
            >
              <Spinner />
            </Flex>
          ) : (
            combinedImageUrl && (
              <StyledCombinedImage.FusedReflection
                src={`data:image/png;base64,${combinedImageUrl}`}
              />
            )
          )}
        </>
      )}
    </>
  )
}
