import React, { type FC } from 'react'

import { MenuItem } from '@szhsin/react-menu'
import { Spacer, Text } from '@uiKit'
import { getImage } from '@uiKit/Icon/helpers'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { StyledAudioCardDropDownMenuItem } from './styled'
import { type AudioCardDropDownMenuItemProps } from './types'

export const AudioCardDropDownMenuItem: FC<AudioCardDropDownMenuItemProps> = ({
  label,
  image,
  onClick,
}) => {
  const theme = useTheme()

  return (
    <MenuItem onClick={onClick}>
      <StyledAudioCardDropDownMenuItem.Icon src={getImage(image)} />
      <Spacer space={theme.spacings.x8} />
      <Text size={TextSize.S1} align={TextAlign.CENTER}>
        {label}
      </Text>
    </MenuItem>
  )
}
