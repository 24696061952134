import React, { type FC } from 'react'

import { OnLoadLogo } from '@pages/Landing/pages/OnLoadLogo'

import { StyledLandingLayout } from './styled'
import { type LandingLayoutProps } from './types'

export const LandingLayout: FC<LandingLayoutProps> = (
  {
    children,
    backgroundColor,
    backgroundImage,
    footer,
    showOnLoadLogo = true,
  }) => {
  return (
    <StyledLandingLayout.Wrapper
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
    >
      {showOnLoadLogo && <OnLoadLogo />}
      <StyledLandingLayout.Container>
        {children}
        <StyledLandingLayout.FooterWrapper>
          {footer && footer}
        </StyledLandingLayout.FooterWrapper>
      </StyledLandingLayout.Container>
    </StyledLandingLayout.Wrapper>
  )
}
