import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { LandingApp } from '@pages/Landing/pages/LandingApp'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const landingRoutes: RouteObject[] = [
  {
    path: ROUTES.LANDING.APP,
    element: (
      <ProtectedRoute>
        <LandingApp />
      </ProtectedRoute>
    ),
  },
]
