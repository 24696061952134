import { PageNumberIndication as PageNumberIndicationBase } from '@component/PageNumberIndication'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'
import { SwiperSlide as SwiperSlideBase } from 'swiper/react'

const PageNumberIndication = styled(PageNumberIndicationBase)`
  position: relative;
  justify-content: center;
  gap: 16px;
  width: -webkit-fill-available;
  height: 35px;
  padding: 0;
`

const Image = styled.img`
  position: relative;
  height: 100%;
`

const Big5NotPassedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const SwiperSlideBig5NotPassed = styled(SwiperSlideBase)`
  height: auto;
`

const SkeletonLoadingOverlay = styled(Skeleton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
`

const SwiperSlide = styled(SwiperSlideBase)`
  min-height: 156px;
`

export const StyledBlockCompatibilities = {
  PageNumberIndication,
  Image,
  Big5NotPassedWrapper,
  SwiperSlideBig5NotPassed,
  SkeletonLoadingOverlay,
  SwiperSlide,
}
