import React, { type FC } from 'react'

import { getSectionName } from '@pages/Landing/types'
import { useTheme } from 'styled-components'

import { SectionIndicationStyled } from './styled'
import { type SectionIndicationProps } from './types'

export const SectionIndication: FC<SectionIndicationProps> = ({ currentSection }) => {
  const theme = useTheme()
  const items: number[] = []
  const totalSections = 3
  if (currentSection && currentSection >= 0) {
    for (let i = 1; i <= totalSections; i++) {
      items.push(i)
    }
  }
  return (
    <SectionIndicationStyled.Main>
      {items?.map((section) => (
        <span key={section}>
          {currentSection === section ? (
            <SectionIndicationStyled.TextIndicator color={theme.colors.base.light}>
              {getSectionName(currentSection)}
            </SectionIndicationStyled.TextIndicator>
          ) : (
            <SectionIndicationStyled.Indicator $isActive={currentSection === section} />
          )}
        </span>
      ))}
    </SectionIndicationStyled.Main>
  )
}
