import { Flex } from '@uiKit'
import { styled } from 'styled-components'

const Wrapper = styled(Flex)`
  padding: 4px 8px;
  gap: 8px;
  width: max-content;
  border-radius: ${({ theme }) => theme.radiuses.df}px;
  border: 1px solid #141414;
`

export const StyledPersonFeature = {
  Wrapper,
}
