import React, { useState, type FC, useCallback, useEffect, type KeyboardEvent } from 'react'

import { Card } from '@component/Card'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { createReflection, getReflection } from '@lib/api'
import { generalProfileInfo } from '@lib/api/rest/profile'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import {
  type CreateReflectionRequest,
  type CreateReflectionResponse,
  type GetReflectionRequest,
  type GetReflectionResponse
} from '@lib/api/rest/reflection/types'
import { ROUTES } from '@router/constants'
import { Flex, Spacer, Text, Button, Range, ClientImage } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { TextSize } from '@uiKit/Text/types'

import { StyledReflectionCard } from './styled'

export const ReflectionCard: FC = () => {
  const [slider, setSlider] = useState(50)
  const [isLoading, setIsLoading] = useState(true)
  const [image, setImage] = useState<string | undefined>(undefined)
  const [reflectionBasedOn, setReflectionBasedOn] = useState<string | undefined>(undefined)

  const { redirect } = useRedirect()
  const {
    data: dataGet,
    isLoading: isLoadingGet,
  } = useAPI<GetReflectionRequest, GetReflectionResponse>({
    apiService: getReflection,
    requestData: {},
  }, {
    runOnMount: true
  })

  const { data: dataTests } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo,
  }, {
    runOnMount: true
  })

  const {
    data: dataCreate,
    runRequest: runRequestCreate,
    isLoading: isLoadingCreate,
  } = useAPI<CreateReflectionRequest, CreateReflectionResponse>({
    apiService: createReflection,
    requestData: {slider: 0.5},
  })

  useEffect(() => {
    if (dataCreate) {
      setImage(dataCreate.reflection_image_base64)
      setReflectionBasedOn(dataCreate.reflection_based_on)
      if ((dataTests?.luscher_test_expires_in_seconds ?? 0) === 0) {
        setSlider(0)
      } else {
        setSlider(dataCreate.slider * 100)
      }
    } else if (dataGet) {
      setImage(dataGet.reflection_image_base64)
      setReflectionBasedOn(dataGet.reflection_based_on)
      if ((dataTests?.luscher_test_expires_in_seconds ?? 0) === 0) {
        setSlider(0)
      } else {
        setSlider(dataGet.slider * 100)
      }
    }
  }, [dataGet, dataCreate, dataTests])

  useEffect(() => {
    if (isLoadingGet || isLoadingCreate) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoadingGet, isLoadingCreate])

  const onSliderChange = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    setSlider(parseInt(e.currentTarget.value))
  }, [])

  const onRecreate = useCallback(() => {
    runRequestCreate({
      slider: slider / 100,
    })
  }, [slider])

  return (
    <Card showBackground padding={24} fullHeight>
      <ClientImage
        alt={'generated image'}
        src={image && `data:image/png;base64,${image}`}
        ratio={'1'}
        isLoading={isLoading}
      />
      <Spacer space={8} />
      <Text fontWeight={400} size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
        Current Reflection is based on: {reflectionBasedOn}
      </Text>
      <Spacer space={16} />
      <Text size={TextSize.TITLE3_20} fontWeight={600}>Refine your search</Text>
      <Spacer space={16} />
      <Text fontWeight={400} size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
        Move the slider left if you want to be matched with someone that is more closely
        connected with your core nature and right if you would like to connect with
        someone that reflects your present mood.
      </Text>
      <Spacer space={24} />
      <Flex>
        <Text fontWeight={400} size={TextSize.FOOTNOTE_13}>Character</Text>
        <Spacer space={4} />
        <Range
          disabled={isLoading || (dataTests?.luscher_test_expires_in_seconds ?? 0) === 0}
          isVisibleThumb={isLoading}
          value={slider}
          onChange={onSliderChange}
          onMouseUp={onRecreate}
          onTouchEnd={onRecreate}
          fullWidth
        />
        <Spacer space={4} />
        <Text fontWeight={400} size={TextSize.FOOTNOTE_13}>Mood</Text>
      </Flex>
      <Spacer space={12} />
      <Text size={TextSize.CAPTION1_12} color={'#5D5D5D'}>
        {dataTests?.luscher_test_expires_in_seconds === 0 &&
          'Take Luscher test to refine your search'
        }
      </Text>
      <Spacer space={8} />
      <StyledReflectionCard.Spacer />
      <Flex>
        <Button
          disabled={isLoading}
          onClick={onRecreate}
          fullWidth
          variant={ButtonVariant.SECONDARY}
        >
          Generate
        </Button>
        <Spacer space={32} />
        <Button
          disabled={isLoading}
          onClick={() => { redirect(ROUTES.PROFILE.ROOT) }}
          fullWidth
          variant={ButtonVariant.PRIMARY}
        >
          Apply
        </Button>
      </Flex>
    </Card>
  )
}
