import React, { type FC } from 'react'

import { Flex, Spacer } from '@uiKit'
import { FlexJustifyContent } from '@uiKit/Flex/types'

import { StyledBig5StepButtons } from './styled'
import {
  type Big5StepButtonsProps,
  BubbleHorizontalPosition,
  BubbleVerticalPosition
} from './types'

export const Big5StepButtons: FC<Big5StepButtonsProps> = ({onGiveAnswer}) => {
  return (
    <>
      <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
        <StyledBig5StepButtons.BubbleWrapper
          $color={'#3B84F3'}
          $positionHorizontal={BubbleHorizontalPosition.LEFT}
          $positionVertical={BubbleVerticalPosition.BOTTOM}
        >
          <StyledBig5StepButtons.Bubble $color={'#3B84F3'}>
            Disagree strongly
          </StyledBig5StepButtons.Bubble>
        </StyledBig5StepButtons.BubbleWrapper>
        <StyledBig5StepButtons.BubbleWrapper
          $color={'#AE37E5'}
          $positionHorizontal={BubbleHorizontalPosition.RIGHT}
          $positionVertical={BubbleVerticalPosition.BOTTOM}
        >
          <StyledBig5StepButtons.Bubble $color={'#AE37E5'}>
            Agree strongly
          </StyledBig5StepButtons.Bubble>
        </StyledBig5StepButtons.BubbleWrapper>
      </Flex>
      <Spacer space={16} />
      <StyledBig5StepButtons.ButtonsWrapper>
        <StyledBig5StepButtons.Button
          onClick={() => { onGiveAnswer(1) }}
          $size={48}
          $color={'#3B84F3'}
        />
        <StyledBig5StepButtons.Button
          onClick={() => { onGiveAnswer(2) }}
          $size={36}
          $color={'#476BE8'}
        />
        <StyledBig5StepButtons.Button
          onClick={() => { onGiveAnswer(3) }}
          $size={24}
          $color={'#564ADB'}
        />
        <StyledBig5StepButtons.Button
          onClick={() => { onGiveAnswer(4) }}
          $size={36}
          $color={'#6E32D4'}
        />
        <StyledBig5StepButtons.Button
          onClick={() => { onGiveAnswer(5) }}
          $size={48}
          $color={'#AE37E5'}
        />
      </StyledBig5StepButtons.ButtonsWrapper>
      <Spacer space={16} />
      <Flex justifyContent={FlexJustifyContent.CENTER}>
        <StyledBig5StepButtons.BubbleWrapper
          $color={'#564ADB'}
          $positionHorizontal={BubbleHorizontalPosition.CENTER}
          $positionVertical={BubbleVerticalPosition.TOP}
        >
          <StyledBig5StepButtons.Bubble $color={'#564ADB'}>
            Neutral
          </StyledBig5StepButtons.Bubble>
        </StyledBig5StepButtons.BubbleWrapper>
      </Flex>
    </>
  )
}
