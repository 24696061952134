import React, { type FC } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useRedirect } from '@hooks/useRedirect'
import { theme } from '@lib/theme'
import { ROUTES } from '@router/constants'
import { Button, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledPage500 } from './styled'

export const Page500: FC = () => {
  const { redirect } = useRedirect()
  return (
    <AppLayout
      header={<LogoHeader showSecondButton />}
      navbar={<Navbar />}
    >
      <StyledPage500.Card showBackground padding={24} fullHeight>
        <StyledPage500.WarningIcon
          alt="warning"
          src={getImage(Images.WARNING)}
        />
        <Spacer space={theme.spacings.x40} />
        <Text
          fontWeight={700}
          size={TextSize.TITLE2_22}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.85)'}
        >
          500 error
        </Text>
        <Spacer space={theme.spacings.x8} />
        <Text
          size={TextSize.SUBHEADLINE_15}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.65)'}
        >
          {'We\'re very sorry, service is temporarily unavailable'}
        </Text>
        <Spacer space={theme.spacings.x36} />
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => { redirect(ROUTES.PROFILE.ROOT) }}
        >
          Go Home
        </Button>
      </StyledPage500.Card>
    </AppLayout>
  )
}
