export const SPACINGS = {
  X4: 4,
  X8: 8,
  X12: 12,
  X14: 14,
  X16: 16,
  X20: 20,
  X24: 24,
  X28: 28,
  X32: 32,
  X30: 30,
  X36: 36,
  X40: 40,
  X44: 44,
  X48: 48,
  X60: 60,
  X128: 128,
}
