import React, { type FC, type PropsWithChildren } from 'react'

import { Button, Flex, Icon, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexAlignItems, FlexJustifyContent } from '@uiKit/Flex/types'
import { Images } from '@uiKit/Icon/constants/images'
import { TextSize } from '@uiKit/Text/types'

import { StyledPopup } from './styled'
import { type PopupProps } from './types'

import 'reactjs-popup/dist/index.css'

export const PopupInner: FC<PropsWithChildren<PopupProps>> = ({
  className,
  children,
  title,
  closeModal,
  showButton,
  onButtonClick,
  buttonText,
}) => {
  return (
    <StyledPopup.Wrapper className={className}>
      <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN} alignItems={FlexAlignItems.CENTER}>
        {title && (
          <Text size={TextSize.TITLE2_22} fontWeight={700}>{title}</Text>
        )}
        <StyledPopup.CloseButton onClick={closeModal}>
          <Icon image={Images.CROSS_SEMIBOLD} />
        </StyledPopup.CloseButton>
      </Flex>
      {children}
      {showButton && (
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={onButtonClick}
        >
          {buttonText ?? 'Quit'}
        </Button>
      )}
    </StyledPopup.Wrapper>
  )
}
