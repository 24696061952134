import React, { type FC, type PropsWithChildren } from 'react'

import { StyledLabel } from '@pages/ProfileEdit/components/Label/styled'
import { type LabelProps } from '@pages/ProfileEdit/components/Label/types'
import { TextAlign, TextSize } from '@uiKit/Text/types'

export const Label: FC<PropsWithChildren<LabelProps>> = (
  {required, children}
) => {
  return (
    <>
      <StyledLabel.Text color={'#747474'} align={TextAlign.START} size={TextSize.SUBHEADLINE_15}>
        {children}
      </StyledLabel.Text>
      {required && <StyledLabel.Text color={'#EE2C2C'}>*</StyledLabel.Text>}
    </>
  )
}
