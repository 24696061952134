import { type HTMLAttributes, type ReactNode } from 'react'

export enum ButtonVariant {
  PRIMARY = 1,
  SECONDARY,
  SMALL_CIRCLE,
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  disabled?: boolean
  isBoxing?: boolean
  variant: ButtonVariant
  type?: 'submit' | 'reset' | 'button'
  fullWidth?: boolean
  flex?: string
  formName?: string
  textColor?: string
}
