import { styled } from 'styled-components'

const Container = styled.div<{
  $margin: number
}>`
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #fefeff;
  border: 2px solid #fff;
  border-radius: ${({ theme }) => (theme.radiuses.df)}px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
  margin: ${({ $margin }) => ($margin && $margin)}px;
  overflow: hidden;
`

const Icon = styled.img`
  object-fit: cover;
  display: block;
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 40px;
  height: 40px;
`

export const StyledAudioCardImage = {
  Image,
  Icon,
  Container
}
