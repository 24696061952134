import React, { type FormEvent, type FC } from 'react'

import {
  LuscherColorCheckbox,
  LuscherColorsFieldset
} from '@pages/Luscher/components'
import { Spacer } from '@uiKit'
import { useTheme } from 'styled-components'

import { useLuscherTestStep } from '../../hooks'
import { StyledLuscherTest } from '../../styled'

import { type LuscherThirdStepProps } from './types'

export const LuscherThirdStep: FC<LuscherThirdStepProps> = ({ onSubmit }) => {
  const theme = useTheme()

  const {
    shuffledColors,
    onChangeColor
  } = useLuscherTestStep({ onSubmit })

  return (
    <>
      <StyledLuscherTest.Heading>Last time</StyledLuscherTest.Heading>
      <Spacer space={theme.spacings.x16} />
      <StyledLuscherTest.Text>Let it flow and be natural.</StyledLuscherTest.Text>
      <Spacer space={theme.spacings.x24} />
      <LuscherColorsFieldset>
        {shuffledColors.map(({
          hex,
          value
        }) => {
          return (
            <LuscherColorCheckbox
              onChange={(e: FormEvent<HTMLDivElement>) => {
                onChangeColor(e, value)
              }}
              value={value}
              key={value}
              color={hex}
            />
          )
        })}
      </LuscherColorsFieldset>
    </>
  )
}
