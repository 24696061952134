/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import * as yup from 'yup'

export const validateProfileForm = yup.object().shape({
  username: yup.string().required('Please, enter your name.').max(20, 'Name is too long.'),
  age: yup
    .number()
    .integer()
    .typeError('Please, enter valid age.')
    .positive('Please, enter valid age.')
    .optional()
    .nullable()
    .max(200, 'Age can not be more than 200 years.')
    .transform((v, o) => o === '' ? null : v),
  work: yup.string().optional().max(200, 'Occupation is too long.'),
  city: yup.string().optional().max(200, 'City is too long.'),
  languages: yup.string().optional().max(200, 'Languages are too long.'),
  about: yup
    .string()
    .required("Please, fill the 'About me' field.")
    .max(1000, "'About me' is too long."),
  is_looking_for_long_term: yup.boolean().optional(),
  is_looking_for_short_term: yup.boolean().optional(),
  is_looking_for_friends: yup.boolean().optional(),
  is_looking_for_chatting: yup.boolean().optional(),
  zodiac_sign: yup.number().integer().default(1),
  profile_image: yup.mixed().optional()
}).test(
  'isLookingForFilled',
  '',
  (obj) => {
    const isLookingForFilled = (
      obj.is_looking_for_long_term ||
      obj.is_looking_for_short_term ||
      obj.is_looking_for_friends ||
      obj.is_looking_for_chatting
    )

    if (!isLookingForFilled) {
      return new yup.ValidationError(
        "Please, select at least one option in 'I am looking for a...' section",
        null,
        'is_looking_for_long_term'
      )
    }
    return true
  }
).test(
  'isImageFilled',
  '',
  (_, context) => {
    if (!context.options.context?.image) {
      return new yup.ValidationError(
        'Please, upload a photo.',
        null,
        'profile_image'
      )
    }

    return true
  }
)
