import { type ReactNode } from 'react'

import { theme } from '@lib/theme'

export interface ProfileSectionProps {
  mainText?: string
  mainTextBlock?: ReactNode
  additionalText?: ReactNode
  tagText?: ReactNode
  additionalTextType?: TextBackgroundType
  tagTextBackgroundType?: TextBackgroundType
  backgroundImage?: string
  backgroundImageBase64?: string
  onSectionClick: VoidFunction
  isDisabled?: boolean
  isLoading?: boolean
  backgroundComponent?: ReactNode
}

export enum TextBackgroundType {
  DONE = 1,
  REQUIRED,
  AVAILABLE,
  PREMIUM,
  LOADING,
  INVISIBLE,
}

export const getAdditionalTextBackgroundColor = (
  position: TextBackgroundType
): string | undefined => {
  switch (position) {
    case TextBackgroundType.DONE:
      return theme.colors.base.green
    case TextBackgroundType.REQUIRED:
      return theme.colors.base.red
    case TextBackgroundType.AVAILABLE:
      return theme.colors.base.main
    case TextBackgroundType.PREMIUM:
      return theme.colors.base.background_gradient_1
    case TextBackgroundType.LOADING:
      return theme.colors.base.grey[350]
    case TextBackgroundType.INVISIBLE:
    default:
      return undefined
  }
}
export const getAdditionalTextColor = (position: TextBackgroundType): string | undefined => {
  switch (position) {
    case TextBackgroundType.DONE:
    case TextBackgroundType.LOADING:
      return theme.colors.base.dark

    case TextBackgroundType.REQUIRED:
    case TextBackgroundType.AVAILABLE:
    case TextBackgroundType.PREMIUM:
      return theme.colors.base.light

    case TextBackgroundType.INVISIBLE:
    default:
      return undefined
  }
}
