import { Link as LinkBase } from '@uiKit'
import { StyledIcon } from '@uiKit/Icon/styled'
import { styled } from 'styled-components'

const Wrapper = styled.div`
  z-index: 5;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  width: 100%;
`

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  padding-inline-start: 0;
  background: ${({ theme }) => theme.colors.base.light};
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  border-radius: 44px;

  .active ${StyledIcon.Container} {
    background: rgba(0, 0, 0, 0.75);
  }
  ${StyledIcon.Container} {
    background: rgba(0, 0, 0, 0.3);
  }
`

const LI = styled.li<{ $padding?: string }>`
  padding: ${({ $padding }) => $padding ?? '0'};
`

const Link = styled(LinkBase)`
  padding: 16px;
  display: flex;
  justify-content: center;
`

export const StyledNavbar = {
  Wrapper,
  Ul,
  LI,
  Link,
}
