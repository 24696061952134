import React, { type FC, useCallback } from 'react'

import luscherTestInfo from '@assets/images/LuscherTestInfo.png'
import LuscherTestInfoPreview from '@assets/images/LuscherTestInfoPreview.png'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Button, Image, Spacer } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledLuscherInfo } from './styled'

export const LuscherInfo: FC = () => {
  const {redirect} = useRedirect()

  const onStart = useCallback(() => {
    redirect(ROUTES.LUSCHER.TEST)
  }, [])

  return (
    <AppLayout header={<Header />} contentStyles={{justifyContent: 'center', paddingBottom: 90}}>
      <Card padding={24} showBackground fullHeight>
        <StyledLuscherInfo.Heading>
          About me:<br />
          My Mood in Color
        </StyledLuscherInfo.Heading>
        <Spacer space={SPACINGS.X16} />
        <StyledLuscherInfo.Text>
          For those curious, this is the Luscher test, it is an evaluation of your current mood.
          <br /><br />
          Please describe how you feel, your current mood with a color sequence.
          <br /><br />
          Please note:
        </StyledLuscherInfo.Text>
        <StyledLuscherInfo.List>
          <li>
            <StyledLuscherInfo.Text>
            This is not about rating your favorite colors in order.
            </StyledLuscherInfo.Text>
          </li>
          <li>
            <StyledLuscherInfo.Text>
            Our mood changes throughout the day. Let your present mood guide you.
            </StyledLuscherInfo.Text>
          </li>
          <li>
            <StyledLuscherInfo.Text>
              {"Your choices may vary slightly each time. All Good. It's part of the process."}
            </StyledLuscherInfo.Text>
          </li>
        </StyledLuscherInfo.List>
        <Spacer space={SPACINGS.X16} />
        <Image
          alt="Luscher test info"
          src={luscherTestInfo}
          srcPreview={LuscherTestInfoPreview}
          ratio={'1'}
        />
        <Spacer space={SPACINGS.X16} />
        <Button
          role="link"
          onClick={onStart}
          fullWidth
          variant={ButtonVariant.PRIMARY}
        >
          Begin
        </Button>
      </Card>
    </AppLayout>
  )
}
