import { styled } from 'styled-components'

const LogoWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: myAnimation;
  animation-duration: 2000ms;
  animation-fill-mode: forwards;
  
  @keyframes myAnimation{
    0%{
      opacity: 1;
      z-index: 3;
    }
    50%{
      opacity: 1;
      z-index: 3;
    }
    99%{
      display: none;
      opacity: 0;
      z-index: 3;
    }
    100%{
      display: none;
      opacity: 0;
      z-index: -1;
    }
  }
`
const Logo = styled.img`
  width: 30%;
  max-width: 150px;
  height: auto;
`

export const OnLoadLogoStyled = {
  LogoWrapper,
  Logo,
}
