import { ToastContainer } from 'react-toastify'
import { styled } from 'styled-components'

import 'react-toastify/dist/ReactToastify.css'

const Container = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 16px;
    padding: 15px;
    box-shadow: inset 2px 2px 4px rgba(255, 255, 255, 0.35);
    border: 1px solid transparent;
    background: linear-gradient(#fafafe, #fafafe) padding-box,
      linear-gradient(to right, #175dc6, #d167ed) border-box;
  }
`

export const StyledToastContainer = {
  Container,
}
