import React, { type FC } from 'react'

import { Card } from '@component/Card'

import { Big5Step } from './components'
import { useBig5Test } from './hooks'
import { type Big5TestProps } from './types'

export const Big5Test: FC<Big5TestProps> = ({ onSubmit }) => {
  const { onGiveAnswer, question, questionNumber } = useBig5Test({onSubmit})

  return (
    <Card showBackground padding={24}>
      <Big5Step onGiveAnswer={onGiveAnswer} question={question} questionNumber={questionNumber} />
    </Card>
  )
}
