import { useAuthToken } from '@hooks/useAuthToken'
import { ROUTES } from '@router/constants'

export const useLogout = () => {
  const { removeAccessToken, removeRefreshToken } = useAuthToken()

  const onLogout = () => {
    removeAccessToken()
    removeRefreshToken()

    location.replace(ROUTES.SIGN_IN)
  }

  return { onLogout }
}
