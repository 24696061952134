import React, { type FC } from 'react'

import { AudioCard } from '@component/AudioCard'
import {
  AudioCardDropDownMenu,
  AudioCardDropDownMenuItem,
  AudioCardImage,
  AudioCardInfo,
} from '@component/AudioCard/components'
import { useAudioList } from '@hooks/useAudioList'
import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Spacer } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { useTheme } from 'styled-components'

import { TrackStatus } from '../../providers/AudioListProvider/types'

import { StyledMusicCard } from './styled'
import { type MusicCardProps } from './types'

export const MusicCard: FC<MusicCardProps> = ({
    src,
    onAdd,
    onDelete,
    title,
    author,
    additionalInfo,
    musicImageSrc,
    spotifyLink,
    spotifyId,
    isMenuVisible = true,
    isCheckboxVisible = false,
    isAdded,
    imageMargin,
    showOnDelete = true,
    isLoading,
}) => {
  const theme = useTheme()
  const {
    onPlay,
    onPause,
    audioObject
  } = useAudioList()
  const trackStatus = audioObject?.[src]?.trackStatus ?? TrackStatus.EMPTY
  const { redirect } = useRedirect()
  // TODO: add loader for music card

  return (
    <AudioCard
      onTrackClick={
        isLoading
          ? undefined
          : trackStatus === TrackStatus.PLAY
            ? () => { onPause(src) }
            : () => { onPlay(src) }
      }
      leftColumn={
        <>
          {isCheckboxVisible && (
            <StyledMusicCard.CheckboxWrapper>
              <Spacer space={theme.spacings.x12} />
              {isLoading && (
                <StyledMusicCard.LoadingOverlay>
                  <StyledMusicCard.Spinner />
                </StyledMusicCard.LoadingOverlay>
              )}
              {isAdded ? (
                <StyledMusicCard.Checkbox
                  checked={true}
                  onCheckboxClick={onDelete}
                />
              ) : (
                <StyledMusicCard.Checkbox
                  checked={false}
                  onCheckboxClick={onAdd}
                />
              )}
              <Spacer space={theme.spacings.x4} />
            </StyledMusicCard.CheckboxWrapper>
          )}
        </>
      }
      middleColumn={
        <>
          <AudioCardImage
            alt="album_image"
            src={musicImageSrc}
            trackStatus={trackStatus}
            margin={imageMargin}
          />
          <Spacer space={theme.spacings.x12} />
          <AudioCardInfo title={title} subtitle={author} />
          <Spacer space={theme.spacings.x12} />
        </>
      }
      rightColumn={isMenuVisible ? (
        <AudioCardDropDownMenu>
          {onAdd && (
            <AudioCardDropDownMenuItem
              onClick={onAdd}
              label="Add to my riffs"
              image={Images.ADD}
            />
          )}
          {showOnDelete && onDelete && (
            <AudioCardDropDownMenuItem
              onClick={onDelete}
              label="Remove from my riffs"
              image={Images.DELETE}
            />
          )}
          <AudioCardDropDownMenuItem
            onClick={() => {
              redirect(
                ROUTES.TRACKS.SIMILAR.replace(':musicId', spotifyId)
              )
            }}
            label="Search for similar riffs"
            image={Images.SEARCH_SIMILAR_SONGS}
          />
          <AudioCardDropDownMenuItem
            onClick={() => {
              window.location.href = spotifyLink
            }}
            label="Open in Spotify"
            image={Images.SPOTIFY_MUSIC}
          />
          {additionalInfo !== undefined && additionalInfo}
        </AudioCardDropDownMenu>
      ) : (
        undefined
      )}
    />
  )
}
