import React, { type FC } from 'react'

import { HeadingOverflow } from '@component/HeadingOverflow'
import { TextOverflow } from '@component/TextOverflow'
import { Spacer } from '@uiKit'
import { FlexDirection } from '@uiKit/Flex/types'
import { HeadingSize, HeadingType } from '@uiKit/Heading/types'
import { TextSize } from '@uiKit/Text/types'

import { StyledAudioCardInfo } from './styled'
import { type AudioCardInfoProps } from './types'

export const AudioCardInfo: FC<AudioCardInfoProps> = ({ title, subtitle }) => {
  return (
    <StyledAudioCardInfo.Container flex="1" direction={FlexDirection.COLUMN}>
      <HeadingOverflow type={HeadingType.H3} title={title} size={HeadingSize.SM}>
        {title}
      </HeadingOverflow>
      <Spacer space={5} />
      <TextOverflow title={title} size={TextSize.S1}>{subtitle}</TextOverflow>
    </StyledAudioCardInfo.Container>
  )
}
