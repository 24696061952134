import { Text } from '@uiKit/Text'
import styled from 'styled-components'

const Label = styled.label<{ $fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  border-radius: 8px;
  background: #FFF;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  padding: 8px 8px 8px 12px;
  height: 42px;
`

const Icon = styled.img`
  width: 24px;
  aspect-ratio: 1;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const CheckboxWrapper = styled.div<{ $isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 1px ${({$isDisabled, theme}) => (
    $isDisabled ? theme.colors.base.main_disabled : theme.colors.base.main
  )} solid;
  border-radius: 4px;
`

const Checkbox = styled.div<{
  $checked: boolean
  $isDisabled: boolean
}>`
  width: 14px;
  height: 14px;
  background: ${({ $checked, $isDisabled, theme }) => (
    !$checked
      ? 'rgb(255, 255, 255, 1)'
      : $isDisabled
        ? theme.colors.base.main_disabled
        : theme.colors.base.main
  )};
  border-radius: 2px;
  transition: all 150ms;
`

const LabelText = styled(Text)`
  text-wrap: nowrap;
`

export const StyledCheckbox = {
  Icon,
  CheckboxWrapper,
  Checkbox,
  HiddenCheckbox,
  Label,
  LabelText,
}
