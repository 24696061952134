import { type APIFunctionOptions } from '@lib/api/types'
import { request } from '@lib/http'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type UpdateProfilePhotoResponse,
  type UpdateProfileInfoRequest,
  type UpdateProfileInfoResponse,
  type UpdateProfilePhotoRequest
} from './types'

export const updateProfileInfo = async (
  data?: UpdateProfileInfoRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<UpdateProfileInfoResponse>> => {
  const { baseUrl = '', headers } = options ?? {}
  return await request({
    url: '/api/app/frontend/profile/get_or_update_profile_v2',
    baseUrl,
    method: HTTPMethods.PUT,
    headers,
    data,
  })
}

export const updateProfilePhoto = async (
  data?: UpdateProfilePhotoRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<UpdateProfilePhotoResponse>> => {
  const { baseUrl = '', headers } = options ?? {}
  return await request({
    url: '/api/app/frontend/profile/get_or_update_profile_v2',
    baseUrl,
    method: HTTPMethods.PUT,
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
}
