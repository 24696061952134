import React, { type FC, type PropsWithChildren } from 'react'

import { StyledBorder } from './styled'
import { type BorderProps } from './types'

export const Border: FC<PropsWithChildren<BorderProps>> = ({
    className,
    children,
    text,
    borderRadius = 24,
    padding = 8,
}) => {
  return (
    <StyledBorder.Border className={className} $borderRadius={borderRadius} $padding={padding}>
      <StyledBorder.BorderText $marginTop={-(padding + 6)}>{text}</StyledBorder.BorderText>
      {children}
    </StyledBorder.Border>
  )
}
