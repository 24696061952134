import React, { type FC, useCallback, useEffect, useState } from 'react'

import { Header } from '@component/Header'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { useAPI, useRedirect } from '@hooks/index'
import { createBig5Test } from '@lib/api/rest/big5'
import {
  type CreateBig5TestRequest,
  type CreateBig5TestResponse
} from '@lib/api/rest/big5/create/types'
import { ROUTES } from '@router/constants'
import { Spacer, Text } from '@uiKit'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { Big5Test } from './components/Big5Test/Big5Test'

export const Big5: FC = () => {
  const { redirect } = useRedirect()
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }

  const {
    runRequest,
    data: responseData
  } = useAPI<
    CreateBig5TestRequest,
    CreateBig5TestResponse
  >({
    apiService: createBig5Test,
  })

  useEffect(() => {
    if (responseData?.message) {
      redirect(ROUTES.BIG5.RESULTS)
    }
  }, [responseData])

  const onSubmit = useCallback(
    (data: number[]) => {
      runRequest({answers: data})
    },
    []
  )

  return (
    <AppLayout
      contentStyles={{justifyContent: 'center', paddingBottom: 90}}
      header={
        <Header
          title={'Big 5'}
          showLeftButton
          leftButton={{
            icon: Images.INFO,
            onClick: () => { redirect(ROUTES.BIG5.INFO) }
          }}
          showRightButton
          rightButton={{
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => { setOpen(prev => !prev) }
          }}
        />
      }
    >
      <StyledPopup.Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        position="right center"
      >
        <PopupInner
          title="Quit the test?"
          closeModal={closeModal}
          onButtonClick={() => { redirect(ROUTES.PROFILE.ROOT) }}
          showButton
        >
          <Spacer space={16} />
          <Text size={TextSize.SUBHEADLINE_15} color={'#5D5D5D'}>
            This action will discard the progress of this test.
            Feel free to take this test again later.
          </Text>
          <Spacer space={16} />
        </PopupInner>
      </StyledPopup.Popup>
      <Big5Test onSubmit={onSubmit} />
    </AppLayout>
  )
}
