import { Button, Flex, Text, Icon as IconBase } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { styled } from 'styled-components'

const AppButton = styled(Button)`
  height: 44px;
  justify-content: space-between;
  box-shadow: 0 4px 4px 0 rgba(0, 35, 220, 0.25);
  margin-top: auto;
  border: 1px solid #FFFCFC;
  background: unset;
`

const ResearchButton = styled(AppButton)`
  margin-bottom: 68px;
`

const StrengthText = styled(Text).attrs(props => ({
  size: props.size ?? TextSize.S1,
}))`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.landing.dark};
  letter-spacing: -0.24px;
  line-height: 20px;
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
  max-height: 100%;
  overflow: auto;
`

const StrengthBlock = styled(Flex)`
  gap: 8px;
`

const StrengthIcon = styled(IconBase)`
  min-width: 16px;
  background: linear-gradient(
    86deg, #175DC6 0%, rgba(101, 60, 213, 0.82) 60.72%, rgba(188, 23, 229, 0.62) 128.1%);
  margin-top: 5px;
`

// noinspection CssInvalidPropertyValue
const BodyTextBlock = styled(Flex)`
  flex-direction: column;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.90) 1.15%,
    rgba(255, 255, 255, 0.79) 98.91%
  );
  padding: 16px 24px;
  box-shadow: 2px 2px 4px 0 rgba(255, 255, 255, 0.35) inset, 2px 2px 36px 0 rgba(0, 0, 0, 0.25);
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  width: -webkit-fill-available;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: 24px;
`

export const LandingAppStyled = {
  StrengthText,
  BodyTextBlock,
  ResearchButton,
  StrengthBlock,
  StrengthIcon,
}
