import { styled } from 'styled-components'

const Border = styled.div<{ $borderRadius: number, $padding: number }>`
  border-radius: ${({ $borderRadius }) => $borderRadius}px;
  border: 1px solid #E1E1E1;
  padding: ${({ $padding }) => $padding}px;
`

const BorderText = styled.div<{ $marginTop: number }>`
  color: #2478F4;
  font-size: 10px;
  font-weight: 400;
  margin-top: ${({ $marginTop }) => $marginTop}px;
  margin-left: 15px;
  background: ${({ theme }) => theme.colors.base.light};
  width: max-content;
  padding: 0 4px;
  position: absolute;
`

export const StyledBorder = {
  BorderText,
  Border,
}
