import React, { type FC, useCallback, useEffect, useMemo } from 'react'

import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { Border } from '@component/Border'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { useAPI } from '@hooks/useAPI'
import { useMusic } from '@hooks/useMusic'
import { useMusicList } from '@hooks/useMusicList'
import { similarTracks } from '@lib/api'
import {
  type SimilarTracksRequest,
  type SimilarTracksResponse,
} from '@lib/api/rest/tracks/similar/types'
import { SPACINGS } from '@lib/theme/constants'
import {
  StyledSimilarMusicCard
} from '@pages/Tracks/pages/Similar/components/SimilarMusicCard/styled'
import { Spacer } from '@uiKit'
import { AudioListProvider } from 'providers'
import { useParams } from 'react-router-dom'
import { useTheme } from 'styled-components'

export const SimilarMusicCard: FC = () => {
  const theme = useTheme()
  const { musicId } = useParams()

  const {
    isLoading,
    data,
    isError,
    runRequest
  } = useAPI<
    SimilarTracksRequest,
    SimilarTracksResponse
  >({
    apiService: similarTracks,
  })

  const tracksList = useMemo(
    () => data?.tracks.map((trackObject) => trackObject.track),
    [data?.tracks]
  )

  const originalTrackMemoized = useMemo(
    () => data?.original_track,
    [data?.original_track]
  )

  const {
    track: originalTrack,
  } = useMusic(originalTrackMemoized)

  const {
    tracks
  } = useMusicList(tracksList)

  const getTrackScore = useCallback(
    (id: string) => {
      return data?.tracks.find((track) => track.track.spotify_id === id)?.score
    },
    [data?.tracks]
  )

  useEffect(() => {
    if (musicId) {
      runRequest({ spotify_id: musicId })
    }
  }, [musicId])

  const audios = useMemo(
    () =>
      data?.original_track.spotify_preview_link && data?.tracks
        ? [
          new Audio(data.original_track.spotify_preview_link),
          ...data.tracks.map(
            (track) => new Audio(track.track.spotify_preview_link)
          ),
        ]
        : [],
    [data?.original_track.spotify_preview_link, data?.tracks]
  )

  return (
    <StyledSimilarMusicCard.Card>
      {isLoading || isError || !data || !originalTrack || !tracks ? (
        <>
          <AudioCardSkeleton count={1} spacing={SPACINGS.X16} />
          <Spacer space={theme.spacings.x16} />
          <AudioCardSkeleton count={10} spacing={SPACINGS.X16} />
        </>
      ) : (
        <AudioListProvider audios={audios}>
          <Border text={'Original riff'} borderRadius={16}>
            <MusicCard
              musicImageSrc={originalTrack?.spotify_label64_link}
              title={originalTrack?.name}
              author={originalTrack?.artist_name}
              src={originalTrack?.spotify_preview_link}
              spotifyId={originalTrack?.spotify_id}
              spotifyLink={originalTrack?.spotify_link}
            />
          </Border>
          <Spacer space={theme.spacings.x16} />
          <Border text={'Similar riffs'} borderRadius={16}>
            <MusicCardList>
              {tracks.map(
                ({
                  artist_name: artistName,
                  name,
                  spotify_preview_link: spotifyPreviewLink,
                  spotify_id: spotifyId,
                  spotify_label64_link: musicImageSrc,
                  is_added: isAdded,
                  spotify_link: spotifyLink
                }) => (
                  <MusicCard
                    key={spotifyId}
                    musicImageSrc={musicImageSrc}
                    title={'>' + getTrackScore(spotifyId) + '% ' + name}
                    author={artistName}
                    src={spotifyPreviewLink}
                    spotifyId={spotifyId}
                    spotifyLink={spotifyLink}
                  />
                )
              )}
            </MusicCardList>
          </Border>
        </AudioListProvider>
      )}
    </StyledSimilarMusicCard.Card>
  )
}
