import React, { type FC, useCallback, useState } from 'react'

import bmatches from '@assets/images/BestMatches.png'
import bmatchesPreview from '@assets/images/BestMatchesPreview.png'
import fuse from '@assets/images/Fusion.png'
import fusePreview from '@assets/images/FusionPreview.png'
import state from '@assets/images/Lusher.png'
import statePreview from '@assets/images/LusherPreview.png'
import prt from '@assets/images/PRT.png'
import prtPreview from '@assets/images/PRTPreview.png'
import tune from '@assets/images/Reflection.png'
import tunePreview from '@assets/images/ReflectionPreview.png'
import similar from '@assets/images/Similar.png'
import similarPreview from '@assets/images/SimilarPreview.png'
import trait from '@assets/images/Trait.png'
import traitPreview from '@assets/images/TraitPreview.png'
import vibes from '@assets/images/Vibe.png'
import vibesPreview from '@assets/images/VibePreview.png'
import { SPACINGS } from '@lib/theme/constants'
import { Landing } from '@pages/Landing'
import { LandingContainerWithImage } from '@pages/Landing/components/LandingContainerWithImage'
import {
  LandingContainerWithImageStyled
} from '@pages/Landing/components/LandingContainerWithImage/styled'
import { LandingContainerWithText } from '@pages/Landing/components/LandingContainerWithText'
import { PortraitPage } from '@pages/Landing/components/PortraitPage'
import { LandingPages } from '@pages/Landing/types'
import { Icon, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { useTheme } from 'styled-components'

import { LandingAppStyled } from './styled'

export const LandingApp: FC = () => {
  const theme = useTheme()
  const [step, setStep] = useState(1)

  const onStepFullResearch = useCallback(() => {
    setStep(LandingPages.FULL_RESEARCH)
  }, [])
  return (
    <Landing setStep={setStep} currentStep={step}>
      {step === LandingPages.MAIN && (
        <PortraitPage />
      )}
      {step === LandingPages.TRAIT && (
        <LandingContainerWithImage
          step={LandingPages.TRAIT}
          headerText={'Trait'}
          description={
            'Based on your favourite riffs, our algorithms determine your musical individuality'
          }
          previewSource={traitPreview}
          imageSource={trait}
        />
      )}
      {step === LandingPages.STATE && (
        <LandingContainerWithImage
          step={LandingPages.STATE}
          headerText={'State'}
          description={
            'The Luscher test evaluates your current mood to complement the matching process'
          }
          previewSource={statePreview}
          imageSource={state}
        />
      )}
      {step === LandingPages.TUNE && (
        <LandingContainerWithImage
          step={LandingPages.TUNE}
          headerText={'Tune'}
          description={'AI creates your Trait & State reflection for matching with others'}
          previewSource={tunePreview}
          imageSource={tune}
        >
          <LandingContainerWithImageStyled.Description>
            Alter your image and calibrate your search criteria
          </LandingContainerWithImageStyled.Description>
        </LandingContainerWithImage>
      )}
      {step === LandingPages.MATCH && (
        <LandingContainerWithImage
          step={LandingPages.MATCH}
          headerText={'Match'}
          description={'Our algorithms find people best matching your Trait & State'}
          previewSource={bmatchesPreview}
          imageSource={bmatches}
        />
      )}
      {step === LandingPages.FUSE && (
        <LandingContainerWithImage
          step={LandingPages.FUSE}
          headerText={'Fuse'}
          description={
            'AI fuses your and your partner’s Reflections\nto visually represent your match'
          }
          previewSource={fusePreview}
          imageSource={fuse}
        />
      )}
      {step === LandingPages.VIBE && (
        <LandingContainerWithImage
          step={LandingPages.VIBE}
          headerText={'Vibe'}
          description={'Reveal suggestions for listening together'}
          previewSource={vibesPreview}
          imageSource={vibes}
        />
      )}
      {step === LandingPages.SEARCH_SIMILAR && (
        <LandingContainerWithImage
          step={LandingPages.SEARCH_SIMILAR}
          headerText={'Search for similar riffs'}
          previewSource={similarPreview}
          imageSource={similar}
        />
      )}
      {step === LandingPages.PRT && (
        <LandingContainerWithImage
          step={LandingPages.PRT}
          headerText={'Personally recommended tracks'}
          previewSource={prtPreview}
          imageSource={prt}
        />
      )}
      {step === LandingPages.RIFFLECT && (
        <LandingContainerWithText
          step={LandingPages.RIFFLECT}
          currentStep={step}
          headerText={'Rifflect'}
        >
          <LandingAppStyled.BodyTextBlock>
            <LandingAppStyled.StrengthBlock>
              <LandingAppStyled.StrengthIcon image={Images.SWIPING} />
              <LandingAppStyled.StrengthText>
                is a <b>modern, science-based</b> dating & matching platform
              </LandingAppStyled.StrengthText>
            </LandingAppStyled.StrengthBlock>
            <Spacer space={SPACINGS.X16} />
            <LandingAppStyled.StrengthBlock>
              <LandingAppStyled.StrengthIcon image={Images.NOTE} />
              <LandingAppStyled.StrengthText>
                utilizes its <b>proprietary technology</b> to determine your
                Trait based on <b>your musical preferences</b>
              </LandingAppStyled.StrengthText>
            </LandingAppStyled.StrengthBlock>
            <Spacer space={SPACINGS.X16} />
            <LandingAppStyled.StrengthBlock>
              <LandingAppStyled.StrengthIcon image={Images.SETTINGS} />
              <LandingAppStyled.StrengthText>
                allows to <b>tune</b> the importance of your Trait&State in
                <b>better search</b> for potential matches
              </LandingAppStyled.StrengthText>
            </LandingAppStyled.StrengthBlock>
            <Spacer space={SPACINGS.X16} />
            <LandingAppStyled.StrengthBlock>
              <LandingAppStyled.StrengthIcon image={Images.COLORS} />
              <LandingAppStyled.StrengthText>
                uses <b>evidence-based</b> Luscher color test for assessing <b>your mood</b> State
              </LandingAppStyled.StrengthText>
            </LandingAppStyled.StrengthBlock>
            <Spacer space={SPACINGS.X16} />
            <LandingAppStyled.StrengthBlock>
              <LandingAppStyled.StrengthIcon image={Images.BRAIN} />
              <LandingAppStyled.StrengthText>
                uses <b>AI</b> to <b>visualize</b> your Trait&State and then
                <b>fuse</b> it with Trait&State images of <b>your matches</b>
                to evaluate the result as <b>one art piece</b>
              </LandingAppStyled.StrengthText>
            </LandingAppStyled.StrengthBlock>
          </LandingAppStyled.BodyTextBlock>
        </LandingContainerWithText>
      )}
      {step === LandingPages.TEAM && (
        <LandingContainerWithText
          step={LandingPages.TEAM}
          currentStep={step}
          headerText={'Team'}
        >
          <LandingAppStyled.BodyTextBlock>
            <LandingAppStyled.StrengthText>
              We are <b>Mind Rhythms</b> – a group of neuroscientists,
              mathematicians, and advanced software engineers who developed
              <b>Rifflect™</b> - an innovative matching platform that employs
              ideas of <b>modern neuroscience, evidence-based</b> tests, and
              <b>AI</b> to significantly increase <b>the odds of success.</b>
            </LandingAppStyled.StrengthText>
          </LandingAppStyled.BodyTextBlock>
        </LandingContainerWithText>
      )}
      {step === LandingPages.MISSION && (
        <LandingContainerWithText
          step={LandingPages.MISSION}
          currentStep={step}
          headerText={'Mission'}
        >
          <LandingAppStyled.BodyTextBlock>
            <LandingAppStyled.StrengthText>
              While the world is facing many challenges which may set people
              apart, our mission is to help a person to <b> find</b> a matching
              counterpart: a <b>soulmate</b>, a <b>friend</b>, a <b>romantic partner</b>,
              or just a <b>date.</b>
            </LandingAppStyled.StrengthText>
          </LandingAppStyled.BodyTextBlock>
        </LandingContainerWithText>
      )}
      {step === LandingPages.RESEARCH && (
        <LandingContainerWithText
          step={LandingPages.RESEARCH}
          currentStep={step}
          headerText={'Basics & Research'}
        >
          <LandingAppStyled.BodyTextBlock>
            <LandingAppStyled.StrengthText>
              Based on the well-studied Brain Music methods, which can “decode”
              brain waves into musical frequencies (link to research), Rifflect
              innovative algorithm “uncovers” your personal <b>Trait</b> from your
              favorite musical pieces (link of music preferences and personality
              traits). <b>Trait</b> carries less dependency on music genre or
              style, or artists; <b>Trait</b> is a set of your personal musical
              characteristics, it’s like your musical personality, your
              ‘musical DNA’(?). So, the best match for you is not necessarily
              someone who listens to the same records; pretty often, your best
              <b> Trait</b> matching candidates might have their playlists
              noticeably different from yours.
              <br /><br />
              <b>Trait</b> does not comprise your instantaneous emotional mindset,
              what plays an important role in finding your counterpart at the
              time of your search request. To acquire this “here and now” mindset
              you need to take Luscher test (link to Luscher test), the output
              of which is managed by Rifflect algorithms to obtain characteristics
              of your current mind <b>State.</b>
              <br /><br />
              Both <b>Trait&State</b> are represented by sets of numerical
              and non-numerical data, which is the foundation in the search
              for cognate minds.
              <br /><br />
              One additional outstanding Rifflect feature is its ability to
              employ AI to, firstly, create the visual reflection of your mindset
              based on the mix of your <b>Trait&State</b>; and then, to fuse
              this image with mindset reflections of your potential matches.
              It gives you and your potential counterparts a unique option
              to visually assess the compatibility of your mindsets.
            </LandingAppStyled.StrengthText>
          </LandingAppStyled.BodyTextBlock>
        </LandingContainerWithText>
      )}
      {step === LandingPages.FULL_RESEARCH && (
        <LandingContainerWithText
          step={LandingPages.FULL_RESEARCH}
          currentStep={step}
          headerText={'Basics & Related Research'}
        >
          <LandingAppStyled.BodyTextBlock>
            <LandingAppStyled.StrengthText>
              Short research description
            </LandingAppStyled.StrengthText>
          </LandingAppStyled.BodyTextBlock>
          <LandingAppStyled.ResearchButton
            role="link"
            onClick={onStepFullResearch}
            fullWidth={true}
            variant={ButtonVariant.SECONDARY}
          >
            <Text color={theme.colors.base.light} fontWeight={500}>Open Research</Text>
            <Icon size={IconSize.X20} image={Images.ARROW_RIGHT} color={theme.colors.base.light} />
          </LandingAppStyled.ResearchButton>
        </LandingContainerWithText>
      )}
    </Landing>
  )
}
