import styled, { css } from 'styled-components'

import { type RangeProps } from './types'

const thumbStyles = css`
  width: 22px;
  height: 22px;
  border-radius: 1em;
  background: ${({ theme }) => theme.colors.base.light};
  border: none;
  box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.12),
  0 6px 13px rgba(0, 0, 0, 0.12);
`

const trackStyles = css`
  height: 6px;
  border-radius: 0.5em;
  background: #efefef;
  box-shadow: none;
`

const InputRange = styled.input<{
  $fullWidth: RangeProps['fullWidth'],
  $isDisabled: RangeProps['disabled'],
  $isVisibleThumb: RangeProps['isVisibleThumb'],
}>`
  -webkit-appearance: none;
  appearance: none;
  background: none;
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'initial')};
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
  --sx1: calc(var(--value) * 1%);

  &:focus {
    outline: none;
  }

  /*webkit*/

  &::-webkit-slider-thumb {
    -webkit-appearance: none;

    ${thumbStyles};

    margin-top: calc(max((6px - 1px - 1px) * 0.5, 0px) - 22px * 0.5);
    visibility: ${({ $isVisibleThumb }) => ($isVisibleThumb ? 'hidden' : 'visible')};
  }

  &::-webkit-slider-runnable-track {
    ${trackStyles}
  }

  &:focus-visible::-webkit-slider-thumb {
    outline: 4px solid ${({ theme }) => theme.colors.focus};
  }

  &::-webkit-slider-runnable-track {
    background: ${({ theme }) => `
      linear-gradient(
        to right,
        ${theme.colors.base.primary},
        ${theme.colors.base.secondary} var(--sx1),
        ${theme.colors.base.primary}) 100% no-repeat
    `};
  }

  &:disabled::-webkit-slider-runnable-track {
    background: ${({ theme }) => `
      linear-gradient(
        to right,
        ${theme.colors.base.grey[350]},
        ${theme.colors.base.light} var(--sx1),
        ${theme.colors.base.grey[350]}
      ) 100% no-repeat
    `};
  }

  /*mozilla*/

  &::-moz-range-thumb {
    ${thumbStyles}
  }

  &::-moz-range-track {
    ${trackStyles}
  }

  &:focus-visible::-moz-range-thumb {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focus};
  }

  &::-moz-range-track {
    background: ${({ theme }) => `
      linear-gradient(
      to right,
      ${theme.colors.base.primary},
      ${theme.colors.base.secondary} var(--sx1),
      ${theme.colors.base.primary}) 100% no-repeat
    `};
  }
`

export const StyledRange = {
  InputRange,
}
