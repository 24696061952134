import React, { forwardRef } from 'react'

import { COLORS } from '@lib/theme/constants'

import { StyledSpinner } from './styled'
import { type SpinnerProps } from './types'

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(({
    color = COLORS.BASE_COLORS.GREY[400],
    className,
}: SpinnerProps, ref) => (
    <StyledSpinner.SpinnerWrapper
      ref={ref}
      $color={color}
      className={className}
    />
  )
)

Spinner.displayName = 'Spinner'
