import { useEffect, useState } from 'react'

import { type Track } from '@lib/api/types'
import { TracksType } from '@pages/Tracks/pages/types'

import { useMusicActions } from '..'

export const useMusic = (
  track: Track | undefined,
  tracksType: TracksType = TracksType.CHARACTER
) => {
  const [trackState, setTrackState] = useState(track)

  useEffect(() => {
    if (track) {
      setTrackState(track)
    }
  }, [track])

  const { addMusic, removeMusic } = useMusicActions({
    onAdd: (track) => {
      setTrackState(track)
    },
    onRemove: (track) => {
      setTrackState(track)
    },
    tracksType
  })

  return { track: trackState, addMusic, removeMusic }
}
