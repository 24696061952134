import React, { type FC } from 'react'

import { AudioCardImage } from '@component/AudioCard/components'
import { TrackStatus } from '@component/AudioCard/components/AudioCardImage/types'
import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { SPACINGS } from '@lib/theme/constants'
import { Divider, Flex, Icon, Input, Spacer, Text } from '@uiKit'
import { DividerWidth } from '@uiKit/Divider/types'
import { Images } from '@uiKit/Icon/constants/images'
import { TextSize } from '@uiKit/Text/types'
import { AudioListProvider } from 'providers'
import { useTheme } from 'styled-components'

import { StyledNotCompletedSubset } from './styled'
import { type NotCompletedSubsetProps } from './types'

export const NotCompletedSubset: FC<NotCompletedSubsetProps> = ({
    isLoading,
    data,
    tracks,
    isError,
    audios,
    addMusic,
    removeMusic,
    onChangeSearch,
    searchedTrackName,
    isLoadingAddTrack,
    isLoadingRemoveTrack,
    tracksType,
    title,
}) => {
  const theme = useTheme()
  return (
    <>
      <Text
        size={TextSize.TITLE3_20}
        fontWeight={600}
        color={theme.colors.base.main}
      >
        {title}
      </Text>
      <Spacer space={theme.spacings.x16} />
      <Input
        fullWidth
        icon={<Icon image={Images.SEARCH} />}
        placeholder="Ella Fitzgerald"
        onChange={onChangeSearch}
        value={searchedTrackName}
      />
      <Spacer space={theme.spacings.x16} />
      {isLoading ? (
        <AudioCardSkeleton count={10} spacing={SPACINGS.X16} />
      ) : isError || data?.tracks_ctr === 0 ? (
        <StyledNotCompletedSubset.InfoBlock>
          <Text color={theme.colors.base.grey[400]}>
            {searchedTrackName.length > 0
              ? 'No results found....'
              : 'Search for your favorite riffs'
            }
          </Text>
        </StyledNotCompletedSubset.InfoBlock>
      ) : (
        <AudioListProvider audios={audios}>
          <StyledNotCompletedSubset.CollapsingBlock
            headingText={'Selected riffs'}
            blockName={'AddedTracks'}
            blockWhenCollapsed={
              <Flex>
                {tracks.filter((track) => track.is_added).map(
                  ({
                    spotify_id: spotifyId,
                    spotify_label64_link: musicImageSrc,
                  }) => (
                    <AudioCardImage
                      key={spotifyId}
                      src={musicImageSrc}
                      trackStatus={TrackStatus.EMPTY}
                      margin={4}
                    />
                  )
                )}
              </Flex>
            }
          >
            <Spacer space={theme.spacings.x8} />
            <MusicCardList>
              {tracks.filter((track) => track.is_added).map(
                ({
                  spotify_id: spotifyId,
                  artist_name: artistName,
                  name,
                  spotify_preview_link: spotifyPreviewLink,
                  spotify_label64_link: musicImageSrc,
                  is_added: isAdded,
                  spotify_link: spotifyLink
                }) => (
                  <MusicCard
                    key={spotifyId}
                    musicImageSrc={musicImageSrc}
                    title={name}
                    author={artistName}
                    spotifyId={spotifyId}
                    spotifyLink={spotifyLink}
                    isMenuVisible={false}
                    isCheckboxVisible={true}
                    isAdded={isAdded}
                    isLoading={isLoadingRemoveTrack || isLoadingAddTrack}
                    onAdd={
                      isAdded ? undefined : () => {
                        addMusic(spotifyId)
                      }
                    }
                    onDelete={
                      !isAdded ? undefined : () => {
                        removeMusic(spotifyId)
                      }
                    }
                    src={spotifyPreviewLink}
                  />
                )
              )}
            </MusicCardList>
          </StyledNotCompletedSubset.CollapsingBlock>
          <Spacer space={theme.spacings.x16} />
          <Divider width={DividerWidth.SMALL} />
          <Spacer space={theme.spacings.x16} />
          <MusicCardList>
            {tracks.filter((track) => !track.is_added).map(
              ({
                spotify_id: spotifyId,
                artist_name: artistName,
                name,
                spotify_preview_link: spotifyPreviewLink,
                spotify_label64_link: musicImageSrc,
                is_added: isAdded,
                spotify_link: spotifyLink
              }) => (
                <MusicCard
                  key={spotifyId}
                  musicImageSrc={musicImageSrc}
                  title={name}
                  author={artistName}
                  spotifyId={spotifyId}
                  spotifyLink={spotifyLink}
                  isMenuVisible={false}
                  isCheckboxVisible={true}
                  isLoading={isLoadingRemoveTrack || isLoadingAddTrack}
                  onAdd={
                    isAdded ? undefined : () => {
                      addMusic(spotifyId)
                    }
                  }
                  onDelete={
                    !isAdded ? undefined : () => {
                      removeMusic(spotifyId)
                    }
                  }
                  src={spotifyPreviewLink}
                />
              )
            )}
          </MusicCardList>
        </AudioListProvider>
      )}
    </>
  )
}
