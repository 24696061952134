import React, { type FC } from 'react'

import { Icon } from '@uiKit'
import { IconSize } from '@uiKit/Icon/types'

import { StyledHeader } from './styled'
import { type HeaderProps } from './types'

export const Header: FC<HeaderProps> = ({
    title,
    showLeftButton = false,
    showRightButton = false,
    leftButton,
    rightButton,
}) => {
  return (
    <StyledHeader.Header>
      {showLeftButton && (
        <StyledHeader.LeftButton onClick={leftButton?.onClick} label="Left button">
          <Icon
            aria-hidden={true}
            color={leftButton?.color ?? '#565656'}
            image={leftButton?.icon ?? undefined}
            size={leftButton?.size ?? IconSize.X20}
          />
        </StyledHeader.LeftButton>
      )}
      {title && <StyledHeader.Heading>{title}</StyledHeader.Heading>}
      {showRightButton && (
        <StyledHeader.RightButton onClick={rightButton?.onClick} label="Right button">
          <Icon
            aria-hidden={true}
            color={rightButton?.color ?? '#565656'}
            image={rightButton?.icon ?? undefined}
            size={rightButton?.size ?? IconSize.X20}
          />
        </StyledHeader.RightButton>
      )}
    </StyledHeader.Header>
  )
}
