import { styled } from 'styled-components'

import { type AppLayoutProps } from './types'

const Wrapper = styled.main<{
  $backgroundColor?: AppLayoutProps['backgroundColor']
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  background-color: ${({ $backgroundColor }) => $backgroundColor && $backgroundColor};
`

const ContentWrapper = styled.div<{$paddingBottom?: number, $justifyContent?: string}>`
  padding: 6px 0;
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom && $paddingBottom + 'px'};
  justify-content: ${({ $justifyContent }) => $justifyContent && $justifyContent};
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  margin: 0 auto;
`

const FixedWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 1;
  width: 100%;
  margin: 0 auto;
`

const NavBarWrapper = styled(FixedWrapper)`
  bottom: 0;
  flex-direction: column;
  align-items: center;
  z-index: 5;
`

const HeaderWrapper = styled(FixedWrapper)<{
  $backgroundColor: AppLayoutProps['backgroundColor']
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  top: 0;
`

const NavBarSpacer = styled.div`
  height: 31px;
  background-color: ${({ theme }) => theme.colors.base.whiteGrey};
  width: 100%;
`

const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background: ${({ theme }) => theme.colors.base.whiteGrey};
  z-index: -1;
`

export const StyledAppLayout = {
  Wrapper,
  ContentWrapper,
  NavBarWrapper,
  HeaderWrapper,
  NavBarSpacer,
  Background,
}
