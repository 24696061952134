import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type SimilarTracksResponse, type SimilarTracksRequest } from './types'

export const similarTracks = async (
  data?: SimilarTracksRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<SimilarTracksResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track/search_similar/',
    baseUrl,
    method: HTTPMethods.GET,
    params: data,
    headers,
  })
}
