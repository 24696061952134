import { type APIFunctionOptions } from '@lib/api/types'
import { request } from '@lib/http'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type GetGeneralProfileInfoRequest, type GetGeneralProfileInfoResponse } from './types'

export const generalProfileInfo = async (
  data?: GetGeneralProfileInfoRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetGeneralProfileInfoResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/profile/get_general_profile_info_v2',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
  })
}
