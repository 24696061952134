import React, { type FC } from 'react'

import { SPACINGS } from '@lib/theme/constants'
import { StyledMatching } from '@pages/Matching/styled'
import { getZodiac } from '@pages/ProfileEdit/components/ProfileEditCard/components/Zodiac/types'
import { Flex, Spacer, Text } from '@uiKit'
import { FlexJustifyContent } from '@uiKit/Flex/types'
import { ImageSizes } from '@uiKit/Image/types'
import { TextSize } from '@uiKit/Text/types'
import Skeleton from 'react-loading-skeleton'
import { useTheme } from 'styled-components'

import { PersonFeature } from './components'
import { StyledAboutPerson } from './styled'
import { type AboutPersonProps } from './types'

export const AboutPerson: FC<AboutPersonProps> = ({
    candidate,
    isLoading,
}) => {
  const theme = useTheme()
  return (
    <StyledMatching.Block>
      <StyledAboutPerson.ProfileImage
        isLoading={isLoading}
        size={ImageSizes.LARGE}
        src={candidate?.profile_image_base64
          ? `data:image/png;base64,${candidate.profile_image_base64}`
          : undefined
        }
        borderRadius={50}
        ratio={'1'}
        showBorder
      />
      <Flex justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
        <StyledAboutPerson.NameText
          size={TextSize.BODY_17}
          color={theme.colors.base.main}
          fontWeight={600}
        >
          {candidate?.username && candidate.username}
        </StyledAboutPerson.NameText>
        <Text
          size={TextSize.BODY_17}
          color={theme.colors.additional.dark_ocean}
          fontWeight={600}
        >
          {candidate?.age && candidate.age + ' y.o.'}
        </Text>
      </Flex>
      <Spacer space={SPACINGS.X8} />
      {isLoading && (
        <>
          <Spacer space={SPACINGS.X24} />
          <Skeleton count={6} />
        </>
      )}
      {!isLoading && (
        <>
          <Text
            color={theme.colors.additional.dark_ocean}
            size={TextSize.SUBHEADLINE_15}
          >
            {candidate?.about}
          </Text>
          <Spacer space={SPACINGS.X8} />
          <StyledAboutPerson.TagsBlock>
            {candidate?.work && (<PersonFeature icon={'💼'} text={candidate?.work} />)}
            {candidate?.city && <PersonFeature icon={'🏙️'} text={candidate?.city} />}
            {candidate?.is_looking_for_long_term && (
              <PersonFeature icon={'💘'} text={'Long-term partner'} />
            )}
            {candidate?.is_looking_for_short_term && (
              <PersonFeature icon={'🎉'} text={'Short-term fun'} />
            )}
            {candidate?.is_looking_for_friends && (
              <PersonFeature icon={'👋'} text={'New friends'} />
            )}
            {candidate?.is_looking_for_chatting && (
              <PersonFeature icon={'💬'} text={'Just chatting'} />
            )}
            {candidate?.languages && (<PersonFeature icon={'🌍'} text={candidate?.languages} />)}
            {!!candidate?.zodiac_sign && (
              <PersonFeature
                image={getZodiac(candidate?.zodiac_sign + 1)?.image}
                text={getZodiac(candidate?.zodiac_sign + 1)?.text}
              />
            )}
          </StyledAboutPerson.TagsBlock>
        </>
      )}
    </StyledMatching.Block>
  )
}
