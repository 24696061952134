export interface LandingProps {
  setStep: (value: number | ((prev: number) => number)) => void
  currentStep: number
}

export enum LandingPages {
  MAIN = 1,
  TRAIT,
  STATE,
  TUNE,
  MATCH,
  FUSE,
  VIBE,
  SEARCH_SIMILAR,
  PRT,
  RIFFLECT,
  TEAM,
  MISSION,
  RESEARCH,
  FULL_RESEARCH,
}

export enum Sections {
  MAIN_FEATURES = 1,
  ADDITIONAL_FEATURES,
  ABOUT,
}

export const getSectionName = (LandingPage: number): string | undefined => {
  switch (LandingPage) {
    case Sections.MAIN_FEATURES:
      return 'Main features'
    case Sections.ADDITIONAL_FEATURES:
      return 'Additional features'
    case Sections.ABOUT:
      return 'About'
    default:
      return undefined
  }
}

export const getPageAndSection = (LandingPage: number): {
  page: number,
  section: number,
  totalPages: number,
} | undefined => {
  switch (LandingPage) {
    case LandingPages.MAIN:
      return {page: -1, section: -1, totalPages: -1}
    case LandingPages.TRAIT:
      return {page: 1, section: 1, totalPages: 6}
    case LandingPages.STATE:
      return {page: 2, section: 1, totalPages: 6}
    case LandingPages.TUNE:
      return {page: 3, section: 1, totalPages: 6}
    case LandingPages.MATCH:
      return {page: 4, section: 1, totalPages: 6}
    case LandingPages.FUSE:
      return {page: 5, section: 1, totalPages: 6}
    case LandingPages.VIBE:
      return {page: 6, section: 1, totalPages: 6}
    case LandingPages.SEARCH_SIMILAR:
      return {page: 1, section: 2, totalPages: 2}
    case LandingPages.PRT:
      return {page: 2, section: 2, totalPages: 2}
    case LandingPages.RIFFLECT:
      return {page: 1, section: 3, totalPages: 4}
    case LandingPages.TEAM:
      return {page: 2, section: 3, totalPages: 4}
    case LandingPages.MISSION:
      return {page: 3, section: 3, totalPages: 4}
    case LandingPages.RESEARCH:
      return {page: 4, section: 3, totalPages: 4}
    case LandingPages.FULL_RESEARCH:
      return {page: -1, section: -1, totalPages: -1}
    default:
      return undefined
  }
}
