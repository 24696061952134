import React, { type FC } from 'react'

import { Header } from '@component/Header'
import { useRedirect } from '@hooks/index'
import { ROUTES } from '@router/constants'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { AppLayout } from 'layouts/AppLayout'

import { ReflectionCard } from './components/ReflectionCard/ReflectionCard'

export const Reflection: FC = () => {
  const { redirect } = useRedirect()
  return (
    <AppLayout
      contentStyles={{justifyContent: 'center', paddingBottom: 90}}
      header={
        <Header
          title={'Reflection'}
          showLeftButton={true}
          showRightButton={true}
          leftButton={{
            icon: Images.INFO,
            onClick: () => { redirect(ROUTES.REFLECTION.INFO) }
          }}
          rightButton={{
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => { redirect(ROUTES.PROFILE.ROOT) }
          }}
        />
      }
    >
      <ReflectionCard />
    </AppLayout>
  )
}
