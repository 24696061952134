import { useCallback, useEffect, useMemo, useState } from 'react'

import { LUSCHER_COLORS } from '@pages/Luscher/constants'
import { isShowLastLusherTestStep } from '@pages/Luscher/helpers'

import { type LuscherColor } from '../../types'

import { type UseLuscherTestData } from './types'

export const useLuscherTest = ({ onChangeStep }: UseLuscherTestData) => {
  const [firstStepColors, setFirstStepColors] = useState<LuscherColor[]>([])
  const [secondStepColors, setSecondStepColors] = useState<LuscherColor[]>([])
  const [thirdStepColors, setThirdStepColors] = useState<LuscherColor[]>([])

  const isShowLastStep = useMemo(() => {
    return isShowLastLusherTestStep(firstStepColors, secondStepColors)
  }, [firstStepColors, secondStepColors])

  const onSubmitFirstLuscherTest = useCallback((data: LuscherColor[]) => {
    setFirstStepColors(data)
    onChangeStep(2)
  }, [])

  const onSubmitSecondLuscherTest = useCallback(
    (data: LuscherColor[]) => {
      setSecondStepColors(data)

      if (isShowLastStep) onChangeStep(3)
    },
    [isShowLastStep]
  )

  const onSubmitThirdLuscherTest = useCallback((data: LuscherColor[]) => {
    setThirdStepColors(data)
  }, [])

  const data = useMemo(
    () => ({
      form_1: firstStepColors,
      form_2: secondStepColors,
      form_3: thirdStepColors,
    }),
    [firstStepColors, secondStepColors, thirdStepColors]
  )

  useEffect(() => {
    if (!isShowLastStep) {
      setThirdStepColors(
        LUSCHER_COLORS.map(({ value: color }) => ({ color, delta: 0 }))
      )
    }
  }, [isShowLastStep])

  return {
    data,
    onSubmitFirstLuscherTest,
    onSubmitSecondLuscherTest,
    onSubmitThirdLuscherTest,
    isShowLastStep,
  }
}
