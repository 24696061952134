import React, { type FC } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useRedirect } from '@hooks/useRedirect'
import { theme } from '@lib/theme'
import { TracksType } from '@pages/Tracks/pages/types'
import { ROUTES } from '@router/constants'
import { Button, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'
import { useSearchParams } from 'react-router-dom'

import { StyledTrackWasDeleted } from './styled'

export const TrackWasDeleted: FC = () => {
  const { redirect } = useRedirect()
  const [searchParams] = useSearchParams()
  const trackType = searchParams.get('type') ?? TracksType.CHARACTER
  return (
    <AppLayout
      header={<LogoHeader showSecondButton />}
      navbar={<Navbar />}
    >
      <StyledTrackWasDeleted.Card showBackground padding={24} fullHeight>
        <StyledTrackWasDeleted.WarningIcon
          alt="warning"
          src={getImage(Images.WARNING)}
        />
        <Spacer space={theme.spacings.x40} />
        <Text
          fontWeight={700}
          size={TextSize.TITLE2_22}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.85)'}
        >
          Some of your riffs have failed
        </Text>
        <Spacer space={theme.spacings.x8} />
        <Text
          size={TextSize.SUBHEADLINE_15}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.65)'}
        >
          Sorry, some of your riffs were removed from your choices
          due to an error on our side.<br />
          Please select other riffs.
        </Text>
        <Spacer space={theme.spacings.x36} />
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => {
            redirect(trackType === TracksType.CHARACTER
              ? ROUTES.TRACKS.SEARCH.ROOT
              : ROUTES.TRACKS.SEARCH.ROOT_MOOD)
          }}
        >
          Complete riffs
        </Button>
      </StyledTrackWasDeleted.Card>
    </AppLayout>
  )
}
