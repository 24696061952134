import React, {
  useState,
  type FC,
  type KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { Card } from '@component/Card'
import { useAPI } from '@hooks/useAPI'
import { useMusicList } from '@hooks/useMusicList'
import { searchTracks, searchTracksMood } from '@lib/api'
import {
  type SearchTracksRequest,
  type SearchTracksResponse,
} from '@lib/api/rest/tracks/search/types'
import { debounce } from '@lib/debounce'

import { CompletedSubset } from '../CompletedSubset'
import { NotCompletedSubset } from '../NotCompletedSubset'

import { type SearchMusicCardProps } from './types'

export const SearchMusicCard: FC<SearchMusicCardProps> = ({
    isSubsetFull,
    setIsSubsetFull,
    tracksType,
    title,
}) => {
  const [searchedTrackName, setSearchedTrackName] = useState('')

  const requestData = useMemo(() => ({ query: '' }), [])

  const {
    data,
    isLoading,
    runRequest,
    isError,
  } = useAPI<SearchTracksRequest, SearchTracksResponse>({
    apiService: tracksType === 'character' ? searchTracks : searchTracksMood,
    requestData,
  }, {
    runOnMount: true
  })

  const {
    addMusic,
    removeMusic,
    tracks,
    isLoadingAddTrack,
    isLoadingRemoveTrack,
  } = useMusicList(data?.tracks, tracksType)
  const debouncedSearch = debounce<typeof runRequest>(runRequest, 1000)

  const onChangeSearch = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value

    setSearchedTrackName(value)

    debouncedSearch({ query: value })
  }, [])

  useEffect(() => {
    setIsSubsetFull(
      tracks?.filter((track) => track.is_added).length === data?.max_number_of_tracks_per_user
    )
  }, [tracks])

  const audios = useMemo(
    () => tracks.map((audio) => {
      const audioElement = new Audio(audio.spotify_preview_link)
      audioElement.preload = 'none'
      return audioElement
    }),
    [tracks]
  )

  return (
    <Card padding={24} showBackground>
      {isSubsetFull && (
        <CompletedSubset
          isLoading={isLoading}
          data={data}
          tracks={tracks}
          isError={isError}
          audios={audios}
          removeMusic={removeMusic}
          isLoadingRemoveTrack={isLoadingRemoveTrack}
          tracksType={tracksType}
        />
      )}
      {!isSubsetFull && (
        <NotCompletedSubset
          isLoading={isLoading}
          data={data}
          tracks={tracks}
          isError={isError}
          audios={audios}
          addMusic={addMusic}
          removeMusic={removeMusic}
          isLoadingAddTrack={isLoadingAddTrack}
          isLoadingRemoveTrack={isLoadingRemoveTrack}
          searchedTrackName={searchedTrackName}
          onChangeSearch={onChangeSearch}
          tracksType={tracksType}
          title={title}
        />
      )}
    </Card>
  )
}
