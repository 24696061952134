import SkeletonBase from 'react-loading-skeleton'
import styled, { css } from 'styled-components'

import { type ImageProps, ImageSizes } from './types'
import 'react-loading-skeleton/dist/skeleton.css'

const getImageSizeStyleCSS = {
  [ImageSizes.SMALL]: css`
    width: 30px;
    height: 30px;
  `,
  [ImageSizes.MEDIUM]: css`
    width: 48px;
    height: 48px;
  `,
  [ImageSizes.LARGE]: css`
    width: 96px;
    height: 96px;
  `,
  [ImageSizes.FULL_WIDTH]: css`
    width: 100%;
  `,
}

const Wrapper = styled.div<{
  $ratio: ImageProps['ratio']
  $size: ImageProps['size']
  $showBorder: ImageProps['showBorder']
  $borderRadius?: ImageProps['borderRadius']
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  ${({ $size }) => $size && getImageSizeStyleCSS[$size]};
  border-radius: ${({ $borderRadius, theme }) => ($borderRadius ?? theme.radiuses.md) + 'px'};
  ${({ $showBorder }) => $showBorder && css`
    border: 1px solid #000;
  `};
  aspect-ratio: ${({ $ratio }) => $ratio ?? 'auto'};
  overflow: hidden;
  background-color: #fff;
`

const Image = styled.img<{
  $ratio: ImageProps['ratio']
  $size: ImageProps['size']
  $borderRadius?: ImageProps['borderRadius']
  $hidden?: boolean
}>`
  opacity: ${({ $hidden }) => $hidden ? 0 : 1};
  height: ${({ $hidden }) => $hidden ? '0' : 'unset'};
  background-clip: border-box;
  object-fit: cover;
  ${({ $size }) => $size && getImageSizeStyleCSS[$size]};
  ${({ $ratio }) => $ratio && $ratio};
  border-radius: ${({ $borderRadius, theme }) => ($borderRadius ?? theme.radiuses.md) + 'px'};
`

const ImagePreview = styled(Image)`
  transition: opacity 1s;
`

const Skeleton = styled(SkeletonBase)<{ $ratio: ImageProps['ratio'] }>`
  aspect-ratio: ${({ $ratio }) => $ratio ?? 'auto'};
`

const SkeletonWrapper = styled.span`
  line-height: 1;
  z-index: 1;
  height: 100%;
`

const DefaultIcon = styled.img<{$ratio: ImageProps['ratio']}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 32px;
  max-height: 32px;
  ${({ $ratio }) => $ratio && $ratio};
`

export const StyledImage = {
  Image,
  ImagePreview,
  Wrapper,
  Skeleton,
  SkeletonWrapper,
  DefaultIcon
}
