import { Flex } from '@uiKit'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  padding: ${({ theme }) => `${theme.spacings.x4}px`};
  border-radius: ${({ theme }) => theme.radiuses.df}px;
  box-shadow: 2px 2px 1px rgba(121, 89, 215, 0.12);
`

const ContentWrapper = styled(Flex)`
  overflow: hidden;
`

const ImageSkeleton = styled(Skeleton)`
  position: relative;
  width: 42px;
  height: 42px;
  border: 1px solid transparent;
  border-radius: ${({ theme }) => (theme.radiuses.df)}px;
  box-shadow: 2px 2px 4px #00000040;
  margin: 4px;
`
export const StyledAudioCard = {
  Card,
  ContentWrapper,
  ImageSkeleton,
}
