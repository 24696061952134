import { Heading as HeadingBase, Text as TextBase } from '@uiKit'
import { styled } from 'styled-components'

const Image = styled.img`
  object-fit: contain;
  overflow: hidden;
  width: 90%;
  max-height: 228px;
  display: flex;
  margin: auto;
`

const Text = styled(TextBase)`
  color: rgba(0, 0, 0, 0.85);;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
`

const Heading = styled(HeadingBase)`
  color: ${({ theme }) => theme.colors.base.main};
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.36px;
`

export const StyledLuscherGratitude = {
  Heading,
  Image,
  Text
}
