import React, { useMemo, type FC } from 'react'

import { StyledMatching } from '@pages/Matching/styled'
import { AudioListProvider } from 'providers'

import { CandidateDetails } from '../CandidateDetails'

import { type MatchingCandidateCardProps } from './types'

export const MatchingCandidateCard: FC<MatchingCandidateCardProps> = ({
    dataMatch,
    dataCRT,
    isLoadingMatch,
    isLoadingCRT,
}) => {
  const audios = useMemo(
    () =>
      dataMatch?.candidate_tracks && dataCRT?.common_recommended_tracks
        ? [
          ...dataMatch.candidate_tracks.map(
            (audio) => new Audio(audio.spotify_preview_link)
          ),
          ...dataMatch.candidate_tracks_mood.map(
            (audio) => new Audio(audio.spotify_preview_link)
          ),
          ...dataCRT.common_recommended_tracks.map(
            (audio) => new Audio(audio.track.spotify_preview_link)
          ),
          ...dataCRT.common_recommended_tracks_mood.map(
            (audio) => new Audio(audio.track.spotify_preview_link)
          ),
        ]
        : [],
    [
      dataMatch?.candidate_tracks,
      dataMatch?.candidate_tracks_mood,
      dataCRT?.common_recommended_tracks,
      dataCRT?.common_recommended_tracks_mood,
    ]
  )
  return (
    <StyledMatching.MainCard>
      <AudioListProvider audios={audios}>
        <CandidateDetails
          dataMatch={dataMatch}
          dataCRT={dataCRT}
          isLoadingMatch={isLoadingMatch}
          isLoadingCRT={isLoadingCRT}
        />
      </AudioListProvider>
    </StyledMatching.MainCard>
  )
}
