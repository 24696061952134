import React, { type FC } from 'react'

import { Header } from '@component/Header'
import { useGoBack } from '@hooks/useGoBack'
import { getPRT } from '@lib/api'
import { Images } from '@uiKit/Icon/constants/images'
import { AppLayout } from 'layouts/AppLayout'

import { PRTList } from './components'

export const PRT: FC = () => {
  const { goBack } = useGoBack()
  return (
    <AppLayout
      header={
        <Header
          title={'Recommendations'}
          showLeftButton
          showRightButton
          leftButton={{
            icon: Images.BACK,
            onClick: goBack
          }}
        />
      }
    >
      <PRTList getPRT={getPRT} />
    </AppLayout>
  )
}
