import React, { type FC, useRef, useState } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { ProfileMenu } from '@pages/Profile/components/ProfileMenu'
import { Images } from '@uiKit/Icon/constants/images'
import { AppLayout } from 'layouts/AppLayout'

import { ProfileCard } from './components/ProfileCard'

export const Profile: FC = () => {
  const ref = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onMenuClick = () => {
    setIsMenuOpen((prev) => !prev)
  }
  return (
    <AppLayout
      header={
        <LogoHeader
          showSecondButton
          secondButton={{
            onClick: onMenuClick,
            icon: Images.MENU2,
            innerRef: ref,
            color: '#B2B2B2',
          }}
        />
      }
      navbar={<Navbar />}
    >
      <ProfileMenu isOpen={isMenuOpen} setOpen={setIsMenuOpen} innerRef={ref} />
      <ProfileCard />
    </AppLayout>
  )
}
