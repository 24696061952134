import { Text as TextBase} from '@uiKit'
import { styled } from 'styled-components'

const Text = styled(TextBase)`
  display: inline;
`

export const StyledLabel = {
  Text,
}
