import { Flex, Text } from '@uiKit'
import { styled } from 'styled-components'

const Main = styled(Flex)`
  padding: 16px 16px 16px 0;
  gap: 9px;
  align-items: center;
`

const Indicator = styled(Flex)<{
  $isActive?: boolean
}>`
  width: 8px;
  height: 8px;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  background-color: ${({ theme }) => theme.colors.landing.inactive_section};
`

const TextIndicator = styled(Text)`
  font-family: SF Pro Display, 'Golos Text', Arial, sans-serif;
`

export const SectionIndicationStyled = {
  Main,
  Indicator,
  TextIndicator,
}
