import { Flex } from '@uiKit'
import styled, { css } from 'styled-components'

import { type LandingLayoutProps } from './types'

const Wrapper = styled.main<{
  $backgroundImage?: LandingLayoutProps['backgroundImage']
  $backgroundColor?: LandingLayoutProps['backgroundColor']
}>`
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
  height: 100vh;
  box-sizing: border-box;
  overflow:hidden;
  
  ${({ $backgroundImage }) =>
    $backgroundImage &&
    css`
      background-image: url(${$backgroundImage});
    `}

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 44px 16px 43px 16px;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  position: relative;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
`

const FooterWrapper = styled(Flex)`
  width: 100%;
`

export const StyledLandingLayout = {
  Wrapper,
  Container,
  FooterWrapper,
}
