import styled, { css } from 'styled-components'

import { type IconProps, IconSize } from './types'

const getIconSizeCSS = {
  [IconSize.X8]: css`
    width: 8px;
    min-width: 8px;
    height: 8px;
  `,
  [IconSize.X12]: css`
    width: 12px;
    min-width: 12px;
    height: 12px;
  `,
  [IconSize.X14]: css`
    width: 14px;
    min-width: 14px;
    height: 14px;
  `,
  [IconSize.X16]: css`
    width: 16px;
    min-width: 16px;
    height: 16px;
  `,
  [IconSize.X20]: css`
    width: 20px;
    min-width: 20px;
    height: 20px;
  `,
  [IconSize.X24]: css`
    width: 24px;
    min-width: 24px;
    height: 24px;
  `,
  [IconSize.X32]: css`
    width: 32px;
    min-width: 32px;
    height: 32px;
  `,
  [IconSize.X42]: css`
    width: 42px;
    min-width: 42px;
    height: 42px;
  `,
}
const Container = styled.div<{
  $size: IconProps['size']
  $icon?: string
  $color?: IconProps['color']
  $margin?: number
}>`
  -webkit-mask: url(${({$icon}) => $icon}) no-repeat center/contain;
  mask: url(${({$icon}) => $icon}) no-repeat center/contain;
  background-color: ${({$color}) => $color};
  ${({$size}) => $size && getIconSizeCSS[$size]};
  margin: ${({$margin}) => $margin && $margin + 'px'};
`

export const StyledIcon = {
  Container,
}
