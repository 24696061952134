export const BUG5_QUESTIONS = [
  'Tends to be quiet.',
  'Is compassionate, has a soft heart.',
  'Tends to be disorganized.',
  'Worries a lot.',
  'Is fascinated by art, music, or literature.',
  'Is dominant, acts as a leader.',
  'Is sometimes rude to others.',
  'Has difficulty getting started on tasks.',
  'Tends to feel depressed, blue.',
  'Has little interest in abstract ideas.',
  'Is full of energy.',
  'Assume the best about people.',
  'Is reliable, can always be counted on.',
  'Is emotionally stable, not easily upset.',
  'Is original, comes up with new ideas.',
]
