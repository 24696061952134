import React from 'react'

import { ProtectedRoute } from '@component/ProtectedRoute'
import { Matching } from '@pages/Matching/Matching'
import { MatchingCandidate } from '@pages/Matching/MatchingCandidate'
import { type RouteObject } from 'react-router-dom'

import { ROUTES } from '../constants'

export const matchingRoutes: RouteObject[] = [
  {
    path: ROUTES.MATCHING.ROOT,
    element: (
      <ProtectedRoute>
        <Matching />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.MATCHING.CANDIDATE,
    element: (
      <ProtectedRoute>
        <MatchingCandidate />
      </ProtectedRoute>
    ),
  },
]
