import React, { type FC, useCallback } from 'react'

import { LogoHeader } from '@component/LogoHeader'
import { Navbar } from '@component/Navbar'
import { useRedirect } from '@hooks/useRedirect'
import { theme } from '@lib/theme'
import { ROUTES } from '@router/constants'
import { Button, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledSubsetNotComplete } from './styled'

export const SubsetNotComplete: FC = () => {
  const { redirect } = useRedirect()
  const onClick = useCallback(() => {
    redirect(ROUTES.TRACKS.SEARCH.ROOT)
  }, [])
  return (
    <AppLayout
      header={<LogoHeader showSecondButton />}
      navbar={<Navbar />}
    >
      <StyledSubsetNotComplete.Card showBackground padding={24} fullHeight>
        <StyledSubsetNotComplete.WarningIcon
          alt="warning"
          src={getImage(Images.WARNING)}
        />
        <Spacer space={theme.spacings.x40} />
        <Text
          fontWeight={700}
          size={TextSize.TITLE2_22}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.85)'}
        >
          Your riffs are not complete
        </Text>
        <Spacer space={theme.spacings.x8} />
        <Text
          size={TextSize.SUBHEADLINE_15}
          align={TextAlign.CENTER}
          color={'rgba(0, 0, 0, 0.65)'}
        >
          Our algorithms need them to match you with other people
        </Text>
        <Spacer space={theme.spacings.x36} />
        <Button variant={ButtonVariant.PRIMARY} onClick={onClick}>
          Complete riffs
        </Button>
      </StyledSubsetNotComplete.Card>
    </AppLayout>
  )
}
