import React, { type ForwardedRef, forwardRef } from 'react'

import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'

import { StyledLyscherColor } from './styled'
import { type LuscherColorCheckboxProps } from './types'

export const LuscherColorCheckbox = forwardRef(
  (
    {
      color,
      checked,
      ...props
    }: LuscherColorCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <StyledLyscherColor.ColorCheckbox
        {...props}
        ref={ref}
        type="checkbox"
        $color={color}
        checked={checked}
        $icon={getImage(Images.SQUIRCLE)}
      />
    )
  }
)

LuscherColorCheckbox.displayName = 'LuscherColorCheckbox'
