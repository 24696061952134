import React, {
  type FC,
  useEffect,
  useRef,
  type ChangeEvent,
  useState,
  useCallback,
  useImperativeHandle
} from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { useToast } from '@hooks/useToast'
import { ToastType } from '@hooks/useToast/types'
import { getProfileInfo, updateProfileInfo, updateProfilePhoto } from '@lib/api/rest/profile'
import {
  type GetProfileInfoRequest,
  type GetProfileInfoResponse,
  type UpdateProfileInfoRequest,
  type UpdateProfileInfoResponse,
  type UpdateProfilePhotoRequest,
  type UpdateProfilePhotoResponse
} from '@lib/api/rest/profile/profileInfo/types'
import {
  type ProfileForm,
  type ProfileEditCardProps
} from '@pages/ProfileEdit/components/ProfileEditCard/types'
import { ROUTES } from '@router/constants'
import { Button, Checkbox, Input, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { InputType } from '@uiKit/Input/types'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { TextArea } from '@uiKit/TextArea'
import { useController, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'

import { Label } from '../../components/Label'

import { Zodiac } from './components/Zodiac'
import { validateProfileForm } from './helpers/validateProfileForm'
import { StyledProfileEditCard } from './styled'

export const ProfileEditCard: FC<ProfileEditCardProps> = ({formName}) => {
  const theme = useTheme()
  const {redirect} = useRedirect()
  const { notify } = useToast()
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | undefined>(undefined)
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const scrollToElement = useRef<HTMLLabelElement>(null)

  const allowedImageTypes = {
    'image/png': 1,
    'image/jpg': 2,
    'image/jpeg': 3,
  }

  const {
    data: dataGet,
    isLoading: isLoadingGet,
    isError: isErrorGet,
    errorMessage: errorMessageGet,
    errorData: errorDataGet,
  } = useAPI<GetProfileInfoRequest, GetProfileInfoResponse>({
    apiService: getProfileInfo
  }, {
    runOnMount: true
  })

  const {
    data: dataUpdate,
    runRequest: runRequestUpdate,
    isError: isErrorUpdate,
    errorMessage: errorMessageUpdate,
    errorData: errorDataUpdate,
  } = useAPI<UpdateProfileInfoRequest, UpdateProfileInfoResponse>({
    apiService: updateProfileInfo,
  })

  const {
    runRequest: runRequestUpdatePhoto,
    isError: isErrorUpdatePhoto,
    errorMessage: errorMessageUpdatePhoto,
    errorData: errorDataUpdatePhoto,
  } = useAPI<UpdateProfilePhotoRequest, UpdateProfilePhotoResponse>({
    apiService: updateProfilePhoto,
  })

  const showErrorMessage = (isError: boolean, errorMessage?: string, errorData?: object) => {
    if (isError) {
      let message: string
      if (errorData) {
        const errorFields = Object.keys(errorData)
        const firstErrorField = errorFields[0]
        let firstErrorFieldName: string
        switch (firstErrorField) {
          case 'profile_image':
            firstErrorFieldName = 'Photo'
            break
          case 'username':
            firstErrorFieldName = 'Name'
            break
          case 'age':
            firstErrorFieldName = 'Age'
            break
          case 'zodiac_sign':
            firstErrorFieldName = 'Zodiac'
            break
          case 'work':
            firstErrorFieldName = 'Occupation'
            break
          case 'city':
            firstErrorFieldName = 'City'
            break
          case 'languages':
            firstErrorFieldName = 'Languages'
            break
          case 'about':
            firstErrorFieldName = 'About me'
            break
          case 'is_looking_for_long_term':
          case 'is_looking_for_short_term':
          case 'is_looking_for_friends':
          case 'is_looking_for_chatting':
            firstErrorFieldName = 'I am looking for a...'
            break
          default:
            firstErrorFieldName = firstErrorField
        }
        message = `There is an error in field 
        '${firstErrorFieldName}': ${errorData[firstErrorField as keyof typeof errorData]}`
      } else if (errorMessage) {
        message = errorMessage
      } else {
        message = 'Unexpected error'
      }
      notify(message, { type: ToastType.ERROR })
    }
  }

  useEffect(() => {
    showErrorMessage(isErrorGet, errorMessageGet, errorDataGet)
  }, [isErrorGet, errorMessageGet, errorDataGet])

  useEffect(() => {
    showErrorMessage(isErrorUpdate, errorMessageUpdate, errorDataUpdate)
  }, [isErrorUpdate, errorMessageUpdate, errorDataUpdate])

  useEffect(() => {
    showErrorMessage(isErrorUpdatePhoto, errorMessageUpdatePhoto, errorDataUpdatePhoto)
  }, [isErrorUpdatePhoto, errorMessageUpdatePhoto, errorDataUpdatePhoto])

  useEffect(() => {
    if (dataGet) {
      (Object.keys(dataGet) as Array<keyof typeof dataGet>).forEach((key) => {
        if (key !== 'id' && key !== 'profile_image_base64') {
          form.setValue(key, dataGet[key] === null ? undefined : dataGet[key])
        }
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataUpdate) {
      redirect(ROUTES.PROFILE.ROOT)
    }
  }, [dataUpdate])

  const form = useForm({
    resolver: yupResolver(validateProfileForm),
    context: {image: imagePreviewUrl ?? dataGet?.profile_image_base64},
    resetOptions: {
      keepErrors: true,
      keepTouched: true,
    },
    mode: 'onBlur'
  })

  const onSubmit = useCallback(
    (data: ProfileForm) => {
      runRequestUpdate(data)
    },
    [runRequestUpdate]
  )

  const onFileUploadClick = () => {
    hiddenFileInput.current?.click()
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length === 0) {
      return
    }
    if (event.target.files) {
      if (!(event.target.files[0].type in allowedImageTypes)) {
        notify("Please upload a photo of types: 'png', 'jpg', 'jpeg'", { type: ToastType.ERROR })
        return
      }
      if (event.target.files[0].size > 100 * 1024 * 1024) {
        notify('The image size should be less than 100 MB', { type: ToastType.ERROR })
        return
      }
      const file = event.target.files[0]
      setImagePreviewUrl(URL.createObjectURL(file))
      runRequestUpdatePhoto({profile_image: file})
    }
  }

  const { field: usernameField } = useController({
    name: 'username',
    control: form.control,
  })
  const { field: ageField } = useController({
    name: 'age',
    control: form.control,
  })
  const { field: zodiacSignField } = useController({
    name: 'zodiac_sign',
    control: form.control,
  })
  const { field: workField } = useController({
    name: 'work',
    control: form.control,
  })
  const { field: cityField } = useController({
    name: 'city',
    control: form.control,
  })
  const { field: languagesField } = useController({
    name: 'languages',
    control: form.control,
  })
  const { field: aboutField } = useController({
    name: 'about',
    control: form.control,
  })
  const { field: isLookingForLongTermField } = useController({
    name: 'is_looking_for_long_term',
    control: form.control,
  })
  const { field: isLookingForShortTermField } = useController({
    name: 'is_looking_for_short_term',
    control: form.control,
  })
  const { field: isLookingForFriendsField } = useController({
    name: 'is_looking_for_friends',
    control: form.control,
  })
  const { field: isLookingForChattingField } = useController({
    name: 'is_looking_for_chatting',
    control: form.control,
  })

  useImperativeHandle(isLookingForLongTermField.ref, () => scrollToElement.current)

  return (
    <StyledProfileEditCard.Form id={formName} onSubmit={form.handleSubmit(onSubmit)}>
      <Text color={'#000'} size={TextSize.TITLE3_20} fontWeight={600} align={TextAlign.START}>
        Profile information
      </Text>
      <Spacer space={22} />

      <Label required>Photo (1 piece)</Label>
      <Spacer space={8} />
      {isLoadingGet && (
        <StyledProfileEditCard.LoadingImageSkeleton />
      )}
      {!isLoadingGet && (
        <StyledProfileEditCard.ImageWrapper>
          <StyledProfileEditCard.Image
            src={imagePreviewUrl ??
              (dataGet?.profile_image_base64
                ? `data:image/png;base64,${dataGet?.profile_image_base64}`
                : undefined)}
            ratio={'0.55'}
            fullImageHeight={true}
          />
        </StyledProfileEditCard.ImageWrapper>
      )}
      <Spacer space={16} />

      <StyledProfileEditCard.HiddenFileInput
        type="file"
        ref={hiddenFileInput}
        onChange={handleFileChange}
      />
      <Button
        variant={
          (imagePreviewUrl ?? dataGet?.profile_image_base64)
            ? ButtonVariant.SECONDARY
            : ButtonVariant.PRIMARY
        }
        onClick={onFileUploadClick}
      >
        Upload new photo
      </Button>
      <StyledProfileEditCard.RequiredText>
        <Spacer space={4} />
        <Text size={TextSize.CAPTION1_12} color={theme.colors.base.danger}>
          {form.formState.errors.profile_image?.message as string}
        </Text>
      </StyledProfileEditCard.RequiredText>
      <Spacer space={16} />

      <Label required>Name</Label>
      <Spacer space={8} />
      <Input
        {...usernameField}
        type={InputType.TEXT}
        placeholder="John"
        isError={form.formState.errors?.username !== undefined}
        helperText={form.formState.errors.username?.message as string}
      />
      <Spacer space={16} />

      <Label>Age</Label>
      <Spacer space={8} />
      <Input
        {...ageField}
        type={InputType.NUMBER}
        placeholder="21"
        isError={form.formState.errors?.age !== undefined}
        helperText={form.formState.errors.age?.message as string}
      />
      <Spacer space={16} />

      <Label>Zodiac</Label>
      <Spacer space={8} />
      <Zodiac
        value={zodiacSignField.value + 1}
        onChange={zodiacSignField.onChange}
      />
      <Spacer space={16} />

      <Label>Occupation</Label>
      <Spacer space={8} />
      <Input
        {...workField}
        type={InputType.TEXT}
        placeholder="Doctor"
        isError={form.formState.errors?.work !== undefined}
        helperText={form.formState.errors.work?.message as string}
      />
      <Spacer space={16} />

      <Label>City</Label>
      <Spacer space={8} />
      <Input
        {...cityField}
        type={InputType.TEXT}
        placeholder="New York"
        isError={form.formState.errors?.city !== undefined}
        helperText={form.formState.errors.city?.message as string}
      />
      <Spacer space={16} />

      <Label>Languages</Label>
      <Spacer space={8} />
      <Input
        {...languagesField}
        type={InputType.TEXT}
        placeholder="English, French"
        isError={form.formState.errors?.languages !== undefined}
        helperText={form.formState.errors.languages?.message as string}
      />
      <Spacer space={16} />

      <Label required>About me</Label>
      <Spacer space={8} />
      <TextArea
        {...aboutField}
        placeholder="What would you like your potential match to know about you?"
        isError={form.formState.errors?.about !== undefined}
        helperText={form.formState.errors.about?.message as string}
      />
      <Spacer space={16} />

      <Label required>I am looking for a...</Label>
      <Spacer space={8} />
      <Checkbox
        checked={isLookingForLongTermField.value ?? false}
        label={'Long-term partner'}
        onCheckboxClick={() => {
          isLookingForLongTermField.onChange(!isLookingForLongTermField.value)
        }}
        icon={Images.HEART_WITH_ARROW}
      />
      <Spacer space={16} />
      <Checkbox
        checked={isLookingForShortTermField.value ?? false}
        label={'Short-term fun'}
        onCheckboxClick={() => {
          isLookingForShortTermField.onChange(!isLookingForShortTermField.value)
        }}
        icon={Images.FIREWORKS}
      />
      <Spacer space={16} />
      <Checkbox
        checked={isLookingForFriendsField.value ?? false}
        label={'New friends'}
        onCheckboxClick={() => {
          isLookingForFriendsField.onChange(!isLookingForFriendsField.value)
        }}
        icon={Images.WAVING_HAND}
      />
      <Spacer space={16} />
      <Checkbox
        checked={isLookingForChattingField.value ?? false}
        label={'Just chatting'}
        onCheckboxClick={() => {
          isLookingForChattingField.onChange(!isLookingForChattingField.value)
        }}
        icon={Images.SPEECH}
        ref={scrollToElement}
      />
      <StyledProfileEditCard.RequiredText>
        <Spacer space={4} />
        <Text size={TextSize.CAPTION1_12} color={theme.colors.base.danger}>
          {form.formState.errors.is_looking_for_long_term?.message as string}
        </Text>
      </StyledProfileEditCard.RequiredText>
    </StyledProfileEditCard.Form>
  )
}
