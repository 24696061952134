import React, { type FC, type PropsWithChildren, useCallback, useState } from 'react'

import { useLocalStorage } from '@hooks/useLocalStorage'
import { Images } from '@uiKit/Icon/constants/images'
import { getImage } from '@uiKit/Icon/helpers'
import { TextSize } from '@uiKit/Text/types'
import { useCollapse } from 'react-collapsed'

import { StyledCollapsingBlock } from './styled'
import { type CollapsingBlockProps } from './types'

export const CollapsingBlock: FC<PropsWithChildren<CollapsingBlockProps>> = ({
    blockName,
    headingText,
    blockWhenCollapsed,
    className,
    children,
}) => {
  const { setValue, getValue } = useLocalStorage()

  const initialIsExpanded = getValue(blockName) !== 'false'
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded)
  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded
  })
  const { getCollapseProps: getCollapsePropsWhenCollapsed } = useCollapse({
    isExpanded: !isExpanded
  })

  const onBlockClick = useCallback(() => {
    setIsExpanded((prev) => !prev)
    setValue(blockName, (!isExpanded).toString())
  }, [])

  return (
    <div className={className}>
      <StyledCollapsingBlock.Button {...getToggleProps({
        onClick: onBlockClick,
      })}
      >
        <StyledCollapsingBlock.ButtonText fontWeight={600} size={TextSize.BODY_17}>
          {headingText}
        </StyledCollapsingBlock.ButtonText>
        <StyledCollapsingBlock.Arrow>
          <img
            alt={'arrow'}
            src={
              isExpanded
                ? getImage(Images.ARROW_UP_COLLAPSE_MENU)
                : getImage(Images.ARROW_DOWN_COLLAPSE_MENU)
            }
          />
        </StyledCollapsingBlock.Arrow>
      </StyledCollapsingBlock.Button>
      <section {...getCollapseProps()}>
        {children}
      </section>
      {blockWhenCollapsed && (
        <section {...getCollapsePropsWhenCollapsed()}>
          {blockWhenCollapsed}
        </section>
      )}
    </div>
  )
}
