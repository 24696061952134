import { styled } from 'styled-components'

import { StyledLyscherColor } from '../LuscherColorCheckbox/styled'

const Fieldset = styled.fieldset`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 68px 0;
  margin: 0;

  ${StyledLyscherColor.ColorCheckbox} {
    display: inline-flex;
  }
`

export const StyledLuscherColorsFieldset = {
  Fieldset,
}
