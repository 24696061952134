import { type DebounceData } from './types'

export const debounce = <F extends (...args: any) => any>(
  // eslint-disable-next-line @typescript-eslint/ban-types
  func: F,
  delay: number,
  options?: DebounceData
) => {
  const { leading } = options ?? {}

  let timerId: NodeJS.Timeout

  return (...args: Parameters<F>) => {
    if (!timerId && leading) {
      func(...args)
    }
    clearTimeout(timerId)

    timerId = setTimeout(() => func(...args), delay)
  }
}
