import { styled } from 'styled-components'

const Reflection = styled.img`
  width: 110px;
  height: 110px;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  background-clip: content-box;
  background-color: ${({ theme }) => theme.colors.base.light};
  object-fit: cover;
`

const FusedReflection = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: ${({ theme }) => theme.radiuses.md}px;
  background-clip: content-box;
  background-color: ${({ theme }) => theme.colors.base.light};
  object-fit: cover;
`

const Arrow = styled.img`
  width: 40px;
  aspect-ratio: 1;
`

export const StyledCombinedImage = {
  Reflection,
  FusedReflection,
  Arrow,
}
