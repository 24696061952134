import React, { forwardRef } from 'react'

import { StyledButton } from './styled'
import { type ButtonProps, ButtonVariant } from './types'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
    children,
    type = 'button',
    disabled,
    variant = ButtonVariant.PRIMARY,
    fullWidth = true,
    flex,
    formName,
    textColor,
    ...props
}, ref) => {
  return (
    <StyledButton.Button
      {...props}
      $fullWidth={fullWidth}
      ref={ref}
      type={type}
      disabled={disabled}
      $variant={variant}
      $flex={flex}
      form={formName}
      $textColor={textColor}
    >
      {children}
    </StyledButton.Button>
  )
})

Button.displayName = 'Button'
