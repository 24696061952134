import { theme } from '@lib/theme'
import { IconButton, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { styled } from 'styled-components'

const Header = styled.header`
  display: flex;
  justify-content: center;
  position: relative;
  height: 97px;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  margin: 0 auto;
`

const Button = styled(IconButton)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  padding: 12px;
  border-radius: 44px;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.90) 1.15%,
    rgba(255, 255, 255, 0.79) 98.91%
  );
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

const LeftButton = styled(Button)`
  left: 16px;
`

const RightButton = styled(Button)`
  right: 16px;
`

const Heading = styled(Text).attrs({
  size: TextSize.TITLE3_20,
  fontWeight: 600,
  color: theme.colors.additional.dark_ocean,
})`
  margin: 0;
  border-radius: 47px;
  background: linear-gradient(
    181deg,
    rgba(255, 255, 255, 0.90) 1.15%,
    rgba(255, 255, 255, 0.79) 98.91%
  );
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  padding: 10px 16px;
  height: max-content;
`

export const StyledHeader = {
  Header,
  LeftButton,
  RightButton,
  Heading,
}
