import { Checkbox as CheckboxBase, Spinner as SpinnerBase } from '@uiKit'
import { styled } from 'styled-components'

const Checkbox = styled(CheckboxBase)`
  box-shadow: unset;
  padding: 8px;
`

const LoadingOverlay = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  padding: 8px 8px 8px 20px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
`

const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Spinner = styled(SpinnerBase)`
  width: 18px;
  height: 18px;
`

export const StyledMusicCard = {
  Checkbox,
  LoadingOverlay,
  CheckboxWrapper,
  Spinner,
}
