import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type CombinedImageRequest, type CombinedImageResponse } from './types'

export const combinedImage = async (
  data?: CombinedImageRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<CombinedImageResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/matching/get_fusion_v2',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}
