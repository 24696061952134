import React, { forwardRef } from 'react'

import { StyleText } from './styled'
import { type TextProps, TextSize } from './types'

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  (
    {
      size = TextSize.S3,
      fontWeight,
      color,
      align,
      children,
      ...props
    },
    ref
  ) =>
    (
      <StyleText.Main
        {...props}
        ref={ref}
        $color={color}
        $fontWeight={fontWeight}
        $size={size}
        $align={align}
      >
        {children}
      </StyleText.Main>
    )
)

Text.displayName = 'Text'
