import React, { type FC } from 'react'

import { FlexJustifyContent } from '@uiKit/Flex/types'

import { PageNumberIndicationStyled } from './styled'
import { type PageNumberIndicationProps } from './types'

export const PageNumberIndication: FC<PageNumberIndicationProps> = (
  {
    currentPage,
    totalPages,
    activeIndicatorColor,
    className,
  }) => {
  const items: number[] = []
  if (totalPages) {
    for (let i = 1; i <= totalPages; i++) {
      items.push(i)
    }
  }
  return (
    <PageNumberIndicationStyled.Main
      justifyContent={FlexJustifyContent.CENTER}
      className={className}
    >
      {items?.map((page) => (
        <PageNumberIndicationStyled.Indicator
          key={page}
          $isActive={currentPage === page}
          $activeColor={activeIndicatorColor}
        />
      ))}
    </PageNumberIndicationStyled.Main>
  )
}
