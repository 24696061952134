import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type CreateBig5TestResponse,
  type CreateBig5TestRequest,
} from './types'

export const createBig5Test = async (
  data?: CreateBig5TestRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<CreateBig5TestResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/big5_test/create_big5_test',
    baseUrl,
    method: HTTPMethods.POST,
    headers,
    data,
  })
}
