export enum ToastType {
  SUCCESS,
  WARNING,
  ERROR,
  INFO,
}

export interface UseToastOptions {
  type: ToastType
}
