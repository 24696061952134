import { styled } from 'styled-components'

import { type LogoProps } from './types'

const Image = styled.img<{
  $width?: LogoProps['width']
  $height?: LogoProps['height']
}>`
  width: ${({ $width }) => $width ?? 90}px;
  height: ${({ $height }) => $height ?? 90}px;
`

export const StyledLogo = {
  Image,
}
