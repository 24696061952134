import React, { forwardRef } from 'react'

import { StyledDivider } from './styled'
import { DividerWidth, type DividerProps } from './types'

export const Divider = forwardRef<HTMLDivElement, DividerProps>(({
  height = 1,
  width = DividerWidth.FULL_WIDTH,
  color = 'rgba(0, 0, 0, 0.25)'
}, ref) => (
    <StyledDivider.Divider
      ref={ref}
      role="separator"
      $height={height}
      $width={width}
      $color={color}
    />
  )
)

Divider.displayName = 'Divider'
