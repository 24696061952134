import React, { forwardRef } from 'react'

import { COLORS } from '@lib/theme/constants'

import { getImage } from './helpers'
import { StyledIcon } from './styled'
import { type IconProps, IconSize } from './types'

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      size = IconSize.X16,
      margin,
      color = COLORS.BASE_COLORS.BLUE_GREY,
      image,
      label,
      ...props
    },
    ref
  ) => {
    return (
      <StyledIcon.Container
        {...props}
        role="img"
        aria-label={label}
        $color={color}
        $size={size}
        $margin={margin}
        $icon={getImage(image)}
        ref={ref}
      />
    )
  }
)

Icon.displayName = 'Icon'
