import React, { type FC } from 'react'

import { Spacer, Text } from '@uiKit'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { Big5StepButtons } from '../Big5StepButtons'

import { StyledBig5Step } from './styled'
import { type Big5StepProps } from './types'

export const Big5Step: FC<Big5StepProps> = ({ onGiveAnswer, question, questionNumber }) => {
  const theme = useTheme()

  return (
    <>
      <Text align={TextAlign.CENTER} color={'rgba(0, 0, 0, 0.85)'} size={TextSize.SUBHEADLINE_15}>
        I am someone who...
      </Text>
      <Spacer space={theme.spacings.x16} />
      <StyledBig5Step.QuestionWrapper>
        <Text
          align={TextAlign.CENTER}
          color={theme.colors.base.dark}
          size={TextSize.TITLE3_20}
          fontWeight={600}
        >
          {question}
        </Text>
        </StyledBig5Step.QuestionWrapper>
      <Spacer space={theme.spacings.x24} />
      <Big5StepButtons onGiveAnswer={onGiveAnswer} />
      <Spacer space={theme.spacings.x24} />
      <Text align={TextAlign.CENTER} color={'#323232'} size={TextSize.FOOTNOTE_13} fontWeight={600}>
        {questionNumber + 1}/15
      </Text>
    </>
  )
}
