import React, {
  forwardRef,
  useEffect,
  useRef,
  useImperativeHandle,
} from 'react'

import { StyledRange } from './styled'
import { type RangeProps } from './types'

export const Range = forwardRef<HTMLInputElement, RangeProps>(
  ({
    step,
    max,
    min,
    disabled,
    isVisibleThumb = true,
    fullWidth,
    value,
    ...props
  }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useImperativeHandle(ref, () => inputRef.current!, [inputRef])

    useEffect(() => {
      const e = inputRef.current

      if (e) {
        e.style.setProperty('--value', value?.toString() ?? e.value)
        e.style.setProperty('--min', e.min === '' ? '0' : e.min)
        e.style.setProperty('--max', e.max === '' ? '100' : e.max)
      }
    }, [inputRef.current, value])

    const onInput = (e: any) => {
      if (!e.disabled) {
        e.target.style.setProperty('--value', e.target.value)
      }
    }

    return (
      <StyledRange.InputRange
        {...props}
        max={max}
        min={min}
        step={step}
        disabled={disabled}
        onInput={onInput}
        $fullWidth={fullWidth}
        $isDisabled={disabled}
        $isVisibleThumb={isVisibleThumb}
        ref={inputRef}
        type="range"
        value={value}
      />
    )
  }
)

Range.displayName = 'Range'
