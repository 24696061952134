import { useState } from 'react'

import { BUG5_QUESTIONS } from '@pages/Big5/constants'

import { type UseBig5TestData } from './types'

export const useBig5Test = ({ onSubmit }: UseBig5TestData) => {
  const [answers, setSelectedAnswers] = useState<number[]>([])
  const [question, setQuestion] = useState(BUG5_QUESTIONS[0])
  const [questionNumber, setQuestionNumber] = useState<number>(0)

  const onGiveAnswer = (answer: number) => {
    if (answers.length === BUG5_QUESTIONS.length - 1) {
      onSubmit([...answers, answer])
      return
    }
    setSelectedAnswers((prev) => [...prev, answer])
    setQuestionNumber((prev) => prev + 1)
    setQuestion(BUG5_QUESTIONS[questionNumber + 1])
  }
  return { onGiveAnswer, answers, question, questionNumber }
}
