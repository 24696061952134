import React, { type FormEvent, type FC } from 'react'

import {
  LuscherColorsFieldset,
  LuscherColorCheckbox
} from '@pages/Luscher/components'
import { Spacer } from '@uiKit'
import { TextAlign } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { useLuscherTestStep } from '../../hooks'
import { StyledLuscherTest } from '../../styled'

import { type LuscherFirstStepProps } from './types'

export const LuscherFirstStep: FC<LuscherFirstStepProps> = ({ onSubmit }) => {
  const theme = useTheme()

  const {
    shuffledColors,
    onChangeColor
  } = useLuscherTestStep({ onSubmit })

  return (
    <>
      <StyledLuscherTest.Heading>About me: My Mood in Color
      </StyledLuscherTest.Heading>
      <Spacer space={theme.spacings.x16} />
      <StyledLuscherTest.Text align={TextAlign.CENTER} color={theme.colors.additional.ocean}>
        Please describe how you feel through a color sequence.
        Begin with the first color you feel most drawn to and keep going
        until there are no more to choose.
      </StyledLuscherTest.Text>
      <Spacer space={theme.spacings.x24} />
      <LuscherColorsFieldset>
        {shuffledColors.map(({
          hex,
          value
        }) => {
          return (
            <LuscherColorCheckbox
              onChange={(e: FormEvent<HTMLDivElement>) => {
                onChangeColor(e, value)
              }}
              value={value}
              key={value}
              color={hex}
            />
          )
        })}
      </LuscherColorsFieldset>
    </>
  )
}
