import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import {
  type GetBig5TestResponse,
  type GetBig5TestRequest,
} from './types'

export const getBig5Test = async (
  data?: GetBig5TestRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetBig5TestResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/big5_test/get_big5_test_v2',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
    data,
  })
}
