import React, { type FC, type PropsWithChildren } from 'react'

import { useAuthToken } from '@hooks/useAuthToken'
import { ROUTES } from '@router/constants'
import { Navigate } from 'react-router-dom'

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessToken } = useAuthToken()

  if (!getAccessToken()) {
    return <Navigate to={ROUTES.SIGN_IN} />
  }

  return children
}
