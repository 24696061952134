export enum BubbleVerticalPosition {
  TOP = 1,
  BOTTOM,
}

export enum BubbleHorizontalPosition {
  LEFT = 1,
  CENTER,
  RIGHT,
}

export interface Big5StepButtonsProps {
  onGiveAnswer: (answer: number) => void
}
