import React, { useState, type FC } from 'react'

import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { ROUTES } from '@router/constants'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { AppLayout } from 'layouts/AppLayout'

import { TracksType } from '../types'

import { SearchMusicCard } from './components'

export const SearchTracks: FC = () => {
  const { redirect } = useRedirect()
  const [isSubsetFull, setIsSubsetFull] = useState(false)
  return (
    <AppLayout
      header={
        <Header
          title={'Character in Music'}
          showLeftButton
          leftButton={{
            icon: Images.INFO,
            onClick: () => { redirect(ROUTES.TRACKS.SEARCH.INFO) },
          }}
          showRightButton
          rightButton={isSubsetFull ? {
            icon: Images.CROSS_SEMIBOLD,
            size: IconSize.X16,
            onClick: () => {
              redirect(ROUTES.PROFILE.ROOT)
            },
          } : undefined}
        />
      }
    >
      <SearchMusicCard
        isSubsetFull={isSubsetFull}
        setIsSubsetFull={setIsSubsetFull}
        tracksType={TracksType.CHARACTER}
        title={'Please select 5 riffs you love'}
      />
    </AppLayout>
  )
}
