import { IconButton } from '@uiKit'
import { styled } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  height: 52px;
  background: ${({ theme }) => theme.colors.base.whiteGrey};
  z-index: 2;
  max-width: ${({ theme }) => theme.sizes.maxAppWidth}px;
  width: 100%;
`

const BackButton = styled(IconButton)`
  height: 44px;
  padding: 12px;
  border-radius: 47px;
  background: #EAEAEB;
`

export const StyledNavbarSave = {
  Wrapper,
  BackButton,
}
