import { Border as BorderBase } from '@component/Border'
import { Button, Icon as IconBase, Image } from '@uiKit'
import { styled } from 'styled-components'

const LogoutButton = styled(Button)`
  border-radius: 8px;
  border: 1px solid #B817C6;
  background: #FEFEFF;
  color: rgba(229, 23, 23, 0.62);
`

const IconBorder = styled.div`
  border-radius: 20px;
  padding: 6px;
  background: rgba(188, 23, 229, 0.12);
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
`

const Icon = styled(IconBase)`
  margin-top: auto;
  margin-bottom: auto;
`

const Card = styled.div`
  padding: 16px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: 16px;
`

const RiffsBackgroundWrapper = styled.div`
  position: relative;
  top: 0;
  left: 45px;
`

const RiffsBackgroundImage = styled(Image)<{
  $left: number
  $top: number
}>`
  left: ${({ $left }) => $left}px;
  top: ${({ $top }) => $top}px;
  position: absolute;
  width: 64px;
  height: 64px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.25);
  border-radius: 16px;
`

const Border = styled(BorderBase)<{ $isHorizontal: boolean }>`
  border: 1px solid #E5E5E5;
  border-radius: 16px;
  display: flex;
  flex-direction: ${({ $isHorizontal }) => $isHorizontal ? 'row' : 'column'};
  width: ${({ $isHorizontal }) => $isHorizontal ? '100%' : 'calc(50% - 30px)'};
  gap: 16px;
  border-radius: 24px;
  padding: 10px;
`

export const StyledProfileCard = {
  LogoutButton,
  IconBorder,
  HeadingContainer,
  Icon,
  Card,
  RiffsBackgroundWrapper,
  RiffsBackgroundImage,
  Border,
}
