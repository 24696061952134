import React, { type FC, useCallback } from 'react'

import big5Info from '@assets/images/Big5Info.png'
import Big5InfoPreview from '@assets/images/Big5InfoPreview.png'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { generalProfileInfo } from '@lib/api/rest/profile'
import {
  type GetGeneralProfileInfoRequest,
  type GetGeneralProfileInfoResponse
} from '@lib/api/rest/profile/generalProfileInfo/types'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Button, Image, Spacer } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledBig5Info } from './styled'

export const Big5Info: FC = () => {
  const {redirect} = useRedirect()

  const { data, isLoading } = useAPI<GetGeneralProfileInfoRequest, GetGeneralProfileInfoResponse>({
    apiService: generalProfileInfo,
  }, {
    runOnMount: true
  })

  const onStart = useCallback(() => {
    if (data?.is_big5_done) {
      redirect(ROUTES.BIG5.RESULTS)
    } else if (!isLoading) {
      redirect(ROUTES.BIG5.TEST)
    }
  }, [data])

  return (
    <AppLayout header={<Header />} contentStyles={{justifyContent: 'center', paddingBottom: 90}}>
      <Card padding={24} showBackground fullHeight>
        <StyledBig5Info.Heading>Big 5</StyledBig5Info.Heading>
        <Spacer space={SPACINGS.X16} />
        <StyledBig5Info.Text>
          If you want to optimize your chances of finding your ideal match,
          take the Big 5 Personality test.
        </StyledBig5Info.Text>
        <Spacer space={SPACINGS.X40} />
        <Image
          src={big5Info}
          srcPreview={Big5InfoPreview}
          ratio={'1'}
          alt="Big 5 test info"
        />
        <Spacer space={SPACINGS.X40} />
        <StyledBig5Info.Spacer />
        <Button
          role="link"
          onClick={onStart}
          fullWidth
          variant={ButtonVariant.PRIMARY}
          textColor={isLoading ? 'transparent' : undefined}
        >
          Continue
        </Button>
      </Card>
    </AppLayout>
  )
}
