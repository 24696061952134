import React, { forwardRef } from 'react'

import { useTheme } from 'styled-components'

import { Spacer } from '../Spacer'
import { Text } from '../Text'
import { TextSize } from '../Text/types'

import { StyledInput } from './styled'
import { type InputProps } from './types'

export const Input = forwardRef<HTMLLabelElement, InputProps>(({
    icon,
    label,
    fullWidth = true,
    type = 'text',
    placeholder,
    dir = 'ltr',
    value,
    isError = false,
    helperText,
    ...props
}, ref) => {
  const theme = useTheme()

  return (
    <StyledInput.Label ref={ref} $fullWidth={fullWidth}>
      {label && (
        <>
          <Text size={TextSize.TITLE3_20} fontWeight={500}>
            {label}
          </Text>
          <Spacer space={4} />
        </>
      )}
      <StyledInput.Wrapper $fullWidth={fullWidth}>
        {icon && <StyledInput.IconWrapper $dir={dir}>{icon}</StyledInput.IconWrapper>}
        <StyledInput.Field
          value={value === null ? undefined : value}
          $hasIcon={icon != null}
          placeholder={placeholder}
          $textSize={TextSize.SUBHEADLINE_15}
          type={type}
          $dir={dir}
          $fullWidth={fullWidth}
          $isError={isError}
          {...props}
        />
      </StyledInput.Wrapper>
      {helperText && (
        <StyledInput.HelperText>
          <Spacer space={4} />
          <Text
            size={TextSize.CAPTION1_12}
            color={isError ? theme.colors.base.danger : theme.colors.base.dark}
          >
            {helperText}
          </Text>
        </StyledInput.HelperText>
      )}
    </StyledInput.Label>
  )
})

Input.displayName = 'Input'
