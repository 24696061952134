import add from '../../../../assets/icons/add.svg'
import apple_music from '../../../../assets/icons/apple_music.svg'
import arrow_down from '../../../../assets/icons/arrow_down.svg'
import arrow_down_collapse_menu from '../../../../assets/icons/arrow_down_collapse_menu.svg'
import arrow_down_fuse from '../../../../assets/icons/arrow_down_fuse.svg'
import arrow_left_symbol from '../../../../assets/icons/arrow_left_symbol.svg'
import arrow_reload from '../../../../assets/icons/arrow_reload.svg'
import arrow_right from '../../../../assets/icons/arrow_right.svg'
import arrow_right_symbol from '../../../../assets/icons/arrow_right_symbol.svg'
import arrow_up from '../../../../assets/icons/arrow_up.svg'
import arrow_up_collapse_menu from '../../../../assets/icons/arrow_up_collapse_menu.svg'
import back from '../../../../assets/icons/back.svg'
import brain from '../../../../assets/icons/brain.svg'
import chat from '../../../../assets/icons/chat.svg'
import check_circle from '../../../../assets/icons/check_circle.svg'
import colors from '../../../../assets/icons/colors.svg'
import cross from '../../../../assets/icons/cross.svg'
import cross_bold from '../../../../assets/icons/cross_bold.svg'
import cross_semibold from '../../../../assets/icons/cross_semibold.svg'
import crossed_eye from '../../../../assets/icons/crossed_eye.svg'
import deleteIcon from '../../../../assets/icons/delete.svg'
import dislike from '../../../../assets/icons/dislike.svg'
import edit from '../../../../assets/icons/edit.svg'
import exit from '../../../../assets/icons/exit.svg'
import eye from '../../../../assets/icons/eye.svg'
import filter from '../../../../assets/icons/filter.svg'
import fireworks from '../../../../assets/icons/fireworks.svg'
import gear from '../../../../assets/icons/gear.svg'
import heart_with_arrow from '../../../../assets/icons/heart_with_arrow.svg'
import home from '../../../../assets/icons/home.svg'
import infotip from '../../../../assets/icons/infotip.svg'
import like from '../../../../assets/icons/like.svg'
import linkPage from '../../../../assets/icons/linkPage.svg'
import matching from '../../../../assets/icons/matching.svg'
import menu from '../../../../assets/icons/menu.svg'
import menu2 from '../../../../assets/icons/menu2.svg'
import next from '../../../../assets/icons/next.svg'
import note from '../../../../assets/icons/note.svg'
import pause from '../../../../assets/icons/pause.svg'
import picture from '../../../../assets/icons/picture.svg'
import play from '../../../../assets/icons/play.svg'
import profile from '../../../../assets/icons/profile.svg'
import question from '../../../../assets/icons/question.svg'
import reload from '../../../../assets/icons/reload.svg'
import search from '../../../../assets/icons/search.svg'
import search_similar_songs from '../../../../assets/icons/search_similar_songs.svg'
import settings from '../../../../assets/icons/settings.svg'
import speech from '../../../../assets/icons/speech.svg'
import spotify_music from '../../../../assets/icons/spotify_music.svg'
import spotify_music_green from '../../../../assets/icons/spotify_music_green.svg'
import squircle from '../../../../assets/icons/squircle.svg'
import swiping from '../../../../assets/icons/swiping.svg'
import track_menu from '../../../../assets/icons/track_menu.svg'
import trash from '../../../../assets/icons/trash.svg'
import users from '../../../../assets/icons/users.svg'
import warning from '../../../../assets/icons/warning.svg'
import waving_hand from '../../../../assets/icons/waving_hand.svg'
import yt_music from '../../../../assets/icons/yt_music.svg'
import aquarius from '../../../../assets/icons/zodiac/aquarius.svg'
import aries from '../../../../assets/icons/zodiac/aries.svg'
import cancer from '../../../../assets/icons/zodiac/cancer.svg'
import capricorn from '../../../../assets/icons/zodiac/capricorn.svg'
import gemini from '../../../../assets/icons/zodiac/gemini.svg'
import leo from '../../../../assets/icons/zodiac/leo.svg'
import libra from '../../../../assets/icons/zodiac/libra.svg'
import pisces from '../../../../assets/icons/zodiac/pisces.svg'
import sagittarius from '../../../../assets/icons/zodiac/sagittarius.svg'
import scorpio from '../../../../assets/icons/zodiac/scorpio.svg'
import taurus from '../../../../assets/icons/zodiac/taurus.svg'
import virgo from '../../../../assets/icons/zodiac/virgo.svg'
import { Images } from '../../constants/images'

export const getImage = (image?: Images): string | undefined => {
  switch (image) {
    case Images.SEARCH:
      return search
    case Images.ADD:
      return add
    case Images.ARROW_DOWN:
      return arrow_down
    case Images.BACK:
      return back
    case Images.CHAT:
      return chat
    case Images.NEXT:
      return next
    case Images.DELETE:
      return deleteIcon
    case Images.PAUSE:
      return pause
    case Images.PLAY:
      return play
    case Images.RELOAD:
      return reload
    case Images.SETTINGS:
      return settings
    case Images.USERS:
      return users
    case Images.DISLIKE:
      return dislike
    case Images.LIKE:
      return like
    case Images.MENU:
      return menu
    case Images.GEAR:
      return gear
    case Images.LINKPAGE:
      return linkPage
    case Images.MATCHING:
      return matching
    case Images.INFO:
      return infotip
    case Images.TRACK_MENU:
      return track_menu
    case Images.SEARCH_SIMILAR_SONGS:
      return search_similar_songs
    case Images.SPOTIFY_MUSIC:
      return spotify_music
    case Images.APPLE_MUSIC:
      return apple_music
    case Images.YT_MUSIC:
      return yt_music
    case Images.ARROW_DOWN_COLLAPSE_MENU:
      return arrow_down_collapse_menu
    case Images.ARROW_UP_COLLAPSE_MENU:
      return arrow_up_collapse_menu
    case Images.SPOTIFY_MUSIC_GREEN:
      return spotify_music_green
    case Images.CROSS:
      return cross
    case Images.ARROW_RIGHT:
      return arrow_right
    case Images.ARROW_RIGHT_SYMBOL:
      return arrow_right_symbol
    case Images.ARROW_LEFT_SYMBOL:
      return arrow_left_symbol
    case Images.SWIPING:
      return swiping
    case Images.NOTE:
      return note
    case Images.COLORS:
      return colors
    case Images.BRAIN:
      return brain
    case Images.PROFILE:
      return profile
    case Images.SQUIRCLE:
      return squircle
    case Images.EDIT:
      return edit
    case Images.MENU2:
      return menu2
    case Images.EXIT:
      return exit
    case Images.PICTURE:
      return picture
    case Images.FIREWORKS:
      return fireworks
    case Images.HEART_WITH_ARROW:
      return heart_with_arrow
    case Images.SPEECH:
      return speech
    case Images.WAVING_HAND:
      return waving_hand
    case Images.ARROW_DOWN_FUSE:
      return arrow_down_fuse
    case Images.CROSS_BOLD:
      return cross_bold
    case Images.ARROW_UP:
      return arrow_up
    case Images.WARNING:
      return warning
    case Images.EYE:
      return eye
    case Images.CROSS_SEMIBOLD:
      return cross_semibold
    case Images.ARROW_RELOAD:
      return arrow_reload
    case Images.ARIES:
      return aries
    case Images.TAURUS:
      return taurus
    case Images.GEMINI:
      return gemini
    case Images.CANCER:
      return cancer
    case Images.LEO:
      return leo
    case Images.VIRGO:
      return virgo
    case Images.LIBRA:
      return libra
    case Images.SCORPIO:
      return scorpio
    case Images.SAGITTARIUS:
      return sagittarius
    case Images.CAPRICORN:
      return capricorn
    case Images.AQUARIUS:
      return aquarius
    case Images.PISCES:
      return pisces
    case Images.QUESTION:
      return question
    case Images.CHECK_CIRCLE:
      return check_circle
    case Images.TRASH:
      return trash
    case Images.HOME:
      return home
    case Images.FILTER:
      return filter
    case Images.CROSSED_EYE:
      return crossed_eye
    default:
      return undefined
  }
}
