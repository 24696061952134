import React, { type FC } from 'react'

import chatting from '@assets/images/chatting.png'
import chattingPreview from '@assets/images/chattingPreview.png'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { AppLayout } from '@layouts/AppLayout'
import { ROUTES } from '@router/constants'
import { Button, Image, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

export const ChatMock: FC = () => {
  const theme = useTheme()
  const { redirect } = useRedirect()
  return (
    <AppLayout header={<Header />} contentStyles={{paddingBottom: 90}}>
      <Card showBackground padding={24}>
        <Text size={TextSize.TITLE1_28} fontWeight={700} color={theme.colors.base.main}>
          Chatting
        </Text>
        <Spacer space={16} />
        <Text size={TextSize.FOOTNOTE_13} fontWeight={600} color={'#9B9B9B'}>
          Here will be a standard chatting page.
        </Text>
        <Spacer space={16} />
        <Image
          alt="Chatting"
          src={chatting}
          srcPreview={chattingPreview}
          ratio={(1240 / (1712)).toString()}
        />
        <Spacer space={16} />
        <Button onClick={() => { redirect(ROUTES.CHAT.ROOT) }} variant={ButtonVariant.PRIMARY}>
          Back to chats
        </Button>
      </Card>
    </AppLayout>
  )
}
