import React, { type FC } from 'react'

import { Spacer } from '@uiKit'

import {
  AboutPerson,
  BlockCompatibilities,
  BlockReflections,
  BlockRiffs,
  BlockVibe
} from './components'
import { type CandidateDetailsProps } from './types'

export const CandidateDetails: FC<CandidateDetailsProps> = ({
    dataMatch,
    isLoadingMatch,
    dataCRT,
    isLoadingCRT,
}) => {
  return (
    <>
      {dataMatch?.candidate.profile_image_base64 && <Spacer space={60} />}
      <AboutPerson candidate={dataMatch?.candidate} isLoading={isLoadingMatch} />

      <BlockRiffs
        partnerTracks={dataMatch?.candidate_tracks}
        partnerTracksMood={dataMatch?.candidate_tracks_mood}
        isLoading={isLoadingMatch}
      />

      <BlockReflections
        matchId={dataMatch?.candidate.id}
        generatedImagePartner={dataMatch?.reflection_candidate_image_base64}
        reflectionCandidateBasedOn={dataMatch?.reflection_candidate_based_on}
        reflectionCurrentUserBasedOn={dataMatch?.reflection_current_user_based_on}
        generatedImageSelf={dataMatch?.reflection_current_user_image_base64}
        partnerName={dataMatch?.candidate.username}
        isLoading={isLoadingMatch}
      />

      <BlockCompatibilities
        compatibilities={{
          tracks: dataMatch?.compatibilities.tracks?.score,
          tracksMood: dataMatch?.compatibilities.tracks_mood?.score,
          luscher: dataMatch?.compatibilities.luscher_test?.score,
          big5: dataMatch?.compatibilities.big5?.score
        }}
        partner={{
          image: dataMatch?.candidate.profile_image_base64,
          big5Traits: dataMatch?.compatibilities.big5?.traits_user_to,
        }}
        currentUser={{
          image: dataMatch?.current_user_image_base64,
          big5Traits: dataMatch?.compatibilities.big5?.traits_user_from,
        }}
        isLoading={isLoadingMatch}
      />

      <BlockVibe
        commonRecommendedTracks={dataCRT?.common_recommended_tracks}
        commonRecommendedTracksMood={dataCRT?.common_recommended_tracks_mood}
        isLoading={isLoadingCRT || isLoadingMatch}
      />
    </>
  )
}
