import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type SearchTracksResponse, type SearchTracksRequest } from './types'

export const searchTracks = async (
  data?: SearchTracksRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<SearchTracksResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track/search/',
    baseUrl,
    method: HTTPMethods.GET,
    params: data,
    headers,
  })
}
export const searchTracksMood = async (
  data?: SearchTracksRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<SearchTracksResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/track_mood/search/',
    baseUrl,
    method: HTTPMethods.GET,
    params: data,
    headers,
  })
}
