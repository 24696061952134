import React, { type PropsWithChildren, type FC, Children } from 'react'

import { StyledLuscherColorsFieldset } from './styled'

export const LuscherColorsFieldset: FC<PropsWithChildren> = ({ children }) => {
  const filteredChildren = Children.toArray(children).filter(Boolean)

  return (
    <StyledLuscherColorsFieldset.Fieldset>
      {filteredChildren}
    </StyledLuscherColorsFieldset.Fieldset>
  )
}
