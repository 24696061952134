import { useEffect } from 'react'

import { useAuthToken, useInterval, useLogout } from '@hooks/index'
import { parseJWT } from '@lib/jwt'

export const useCheckTokenExpiration = () => {
  const { getRefreshToken } = useAuthToken()

  const { onLogout } = useLogout()

  const checkExpiration = () => {
    const token = getRefreshToken()

    if (token) {
      const { exp } = parseJWT(token)

      const isExpired = exp * 1000 < Date.now()

      if (isExpired) {
        onLogout()
      }
    }
  }

  useEffect(() => {
    checkExpiration()
  }, [])

  useInterval(() => {
    checkExpiration()
  }, 10000)
}
