import { type LandingLayoutProps } from '@layouts/LandingLayout/types'
import { theme } from '@lib/theme'
import { Icon as IconBase, Text } from '@uiKit'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const Section = styled.div<{
  $backgroundImage: LandingLayoutProps['backgroundImage']
}>`
  width: 100%;
  max-width: 100%;
  height: 100%;
  background-image: ${({ $backgroundImage }) => $backgroundImage && `url(${$backgroundImage})`};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
`

const TextBlock = styled.div<{$backgroundColor?: string}>`
  display: flex;
  position: absolute;
  left: 6px;
  bottom: 8px;
  border-radius: 19px;
  background: ${({ $backgroundColor }) => $backgroundColor && $backgroundColor};
`

const MainText = styled(Text).attrs(props => ({
  fontWeight: props.fontWeight ?? 600,
  size: props.size ?? TextSize.SUBHEADLINE_15,
}))``

const MainTextBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.radiuses.lg}px;
  background: #FFF;
  box-shadow: -2px -2px 4px 0 rgba(255, 255, 255, 0.25), 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
`

const AdditionalText = styled(Text)`
  margin: auto;
  padding: 4px 8px 4px 4px;
  font-weight: 600;
  position: relative;
`

const TagText = styled(Text)<{$backgroundColor?: string}>`
  position: absolute;
  left: 10px;
  top: 10px;
  border-radius: 35px;
  background: ${({ $backgroundColor }) => $backgroundColor && $backgroundColor};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 4px 8px;
`

const Icon = styled(IconBase)`
  margin-top: auto;
  margin-bottom: auto;
`

const Wrapper = styled.div<{
  $isDisabled?: boolean
}>`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  filter: ${({ $isDisabled }) => $isDisabled && 'grayscale(100%)'};
  aspect-ratio: 1;
`

const DisabledOverlay = styled(Text).attrs({
  size: TextSize.BODY_17,
  fontWeight: 600,
  color: theme.colors.base.light,
  align: TextAlign.CENTER,
})`
  position: absolute;
  background: #060606;
  border-radius: 8px;
  z-index: 1;
  transition: 0.3s;
  width: 70%;
  padding: 8px;
  top: calc(50% - 30px);
`

const SkeletonLoadingOverlay = styled(Skeleton)<{ $isLoading?: boolean }>`  
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  opacity: ${({ $isLoading }) => ($isLoading ? 1 : 0)};
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
`

const BackgroundOverlay = styled.div`
  position: absolute;
  border-radius: 8px;
  transition: opacity 0.3s;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const StyledProfileSection = {
  Section,
  Icon,
  TextBlock,
  MainText,
  AdditionalText,
  TagText,
  MainTextBlock,
  Wrapper,
  DisabledOverlay,
  SkeletonLoadingOverlay,
  BackgroundOverlay,
}
