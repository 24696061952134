import React, { type FC, useCallback } from 'react'

import illustration404 from '@assets/images/Illustration404.webp'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { theme } from '@lib/theme'
import { Styled404 } from '@pages/Error/pages/Page404/styled'
import { ROUTES } from '@router/constants'
import { Button, Flex, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexAlignItems, FlexDirection } from '@uiKit/Flex/types'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'

export const Page404: FC = () => {
  const { redirect } = useRedirect()
  const onPage404 = useCallback(() => {
    redirect(ROUTES.PROFILE.ROOT)
  }, [])
  return (
    <AppLayout
      header={<Header title={'Ooops'} />}
    >
      <Styled404.Image
        alt="404"
        src={illustration404}
      />
      <Card>
        <Text
          fontWeight={600}
          size={TextSize.M1}
          align={TextAlign.CENTER}
          color={theme.colors.additional.dark_ocean}
        >
          Not Found Error · 404
        </Text>
        <Spacer space={theme.spacings.x20} />
        <Flex direction={FlexDirection.COLUMN} alignItems={FlexAlignItems.CENTER}>
          <Text
            size={TextSize.S2}
            color={theme.colors.base.blueGrey}
            align={TextAlign.CENTER}
          >
            Sorry, we can&apos;t find the page you requested.
            Try again later or contact support.
          </Text>
          <Spacer space={theme.spacings.x20} />
          <Button
            role="link"
            onClick={onPage404}
            fullWidth
            variant={ButtonVariant.PRIMARY}
          >
            Go Home
          </Button>
        </Flex>
      </Card>
    </AppLayout>
  )
}
