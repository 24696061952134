import React, { type FC } from 'react'

import { Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { LikeToolbar } from '../LikeToolbar'

import { StyledCandidatePreview } from './styled'
import { type CandidatePreviewProps } from './types'

export const CandidatePreview: FC<CandidatePreviewProps> = ({
    dataMatch,

    isLoadingMatch,
    isLoadingLike,

    onDislike,
    onLike,
    getToggleProps,
    onCollapse,
    isExpanded,
}) => {
  const theme = useTheme()
  return (
    <>
      <StyledCandidatePreview.LoadingImageSkeleton
        width={'100%'}
        height={'100%'}
        $isVisible={isLoadingMatch || isLoadingLike || !dataMatch}
      />
      {!isLoadingMatch && (
        <StyledCandidatePreview.PreviewWrapper
          $image={
            dataMatch && (`data:image/png;base64,${dataMatch?.candidate.profile_image_base64}`)
          }
        >
          <StyledCandidatePreview.UserNameWrapper>
            <Text size={TextSize.LARGE_TITLE_34} color={theme.colors.base.light} fontWeight={700}>
              {dataMatch?.candidate.username}{dataMatch?.candidate.age && ', ' +
              dataMatch?.candidate.age}
            </Text>
          </StyledCandidatePreview.UserNameWrapper>
          <StyledCandidatePreview.ButtonWrapper>
            <LikeToolbar
              onDislike={onDislike}
              onLike={onLike}
              getToggleProps={getToggleProps}
              onCollapse={onCollapse}
              isExpanded={isExpanded}
            />
          </StyledCandidatePreview.ButtonWrapper>
        </StyledCandidatePreview.PreviewWrapper>
      )}
    </>
  )
}
