import { type APIFunctionOptions } from '@lib/api/types'
import { request } from '@lib/http'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type GetProfileInfoRequest, type GetProfileInfoResponse } from './types'

export const getProfileInfo = async (
  data?: GetProfileInfoRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<GetProfileInfoResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/profile/get_or_update_profile_v2',
    baseUrl,
    method: HTTPMethods.GET,
    headers,
  })
}
