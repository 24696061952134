import React, { type FC } from 'react'

import { type NavbarSaveProps } from '@pages/ProfileEdit/components/NavbarSave/types'
import { Button } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'

import { StyledNavbarSave } from './styled'

export const NavbarSave: FC<NavbarSaveProps> = ({ formName }) => {
  return (
    <StyledNavbarSave.Wrapper>
      <Button type={'submit'} formName={formName} variant={ButtonVariant.PRIMARY}>Apply</Button>
    </StyledNavbarSave.Wrapper>
  )
}
