import React, { type FC } from 'react'

import LuscherTestGratitude from '@assets/images/LuscherTestGratitude.png'
import LuscherTestGratitudePreview from '@assets/images/LuscherTestGratitudePreview.png'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { useRedirect } from '@hooks/useRedirect'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Button, Image, Spacer } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { AppLayout } from 'layouts/AppLayout'

import { StyledLuscherGratitude } from './styled'

export const LuscherGratitude: FC = () => {
  const {redirect} = useRedirect()
  return (
    <AppLayout header={<Header />} contentStyles={{justifyContent: 'center', paddingBottom: 90}}>
      <Card showBackground padding={24}>
        <StyledLuscherGratitude.Heading>Good job!</StyledLuscherGratitude.Heading>
        <Spacer space={SPACINGS.X16} />
        <Image
          alt="Luscher test. Good job!"
          src={LuscherTestGratitude}
          srcPreview={LuscherTestGratitudePreview}
          ratio={'1'}
        />
        <Spacer space={SPACINGS.X16} />
        <StyledLuscherGratitude.Text>
          You have improved your matching accuracy
        </StyledLuscherGratitude.Text>
        <Spacer space={SPACINGS.X16} />
        <Button
          role="link"
          onClick={() => { redirect(ROUTES.REFLECTION.INFO) }}
          fullWidth
          variant={ButtonVariant.PRIMARY}
        >
          Go to Reflection
        </Button>
      </Card>
    </AppLayout>
  )
}
