import React, { type FC } from 'react'

import { CollapsingBlock } from '@component/CollapsingBlock'
import { StyledMatching } from '@pages/Matching/styled'
import { Spacer, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { useTheme } from 'styled-components'

import { CombinedImage } from './components'
import { type BlockReflectionsProps } from './types'

export const BlockReflections: FC<BlockReflectionsProps> = ({
    matchId,
    generatedImagePartner,
    generatedImageSelf,
    reflectionCandidateBasedOn,
    reflectionCurrentUserBasedOn,
    partnerName,
    isLoading,
}) => {
  const theme = useTheme()
  return (
    <StyledMatching.Block>
      <CollapsingBlock headingText={'Reflections'} blockName={'reflections'}>
        <Spacer space={theme.spacings.x8} />
        <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
          AI portrait of You & {partnerName}.
        </Text>
        <Spacer space={theme.spacings.x8} />
        {generatedImagePartner && generatedImageSelf ? (
          <>
            <CombinedImage
              matchId={matchId}
              partnerImage={generatedImagePartner}
              selfImage={generatedImageSelf}
              isLoading={isLoading}
            />
            {reflectionCandidateBasedOn && reflectionCurrentUserBasedOn && (
              <>
                <Spacer space={12} />
                <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.CAPTION2_11}>
                  Reflections are based on:<br />
                  {partnerName}: {reflectionCandidateBasedOn}<br />
                  You: {reflectionCurrentUserBasedOn}
                </Text>
              </>
            )}
          </>
        ) : (
          <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
            <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
            Both you and your partner need to have Reflections to see this section.
          </Text>
        )}
      </CollapsingBlock>
    </StyledMatching.Block>
  )
}
