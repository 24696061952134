import { styled } from 'styled-components'

const CollapsingSection = styled.section<{ $isExpanded: boolean }>`
  display: ${({ $isExpanded }) => $isExpanded ? 'block' : 'flex'};
  flex: 1;
  position: relative;
`

export const StyledMatchingCard = {
  CollapsingSection,
}
