import { ClientImage, Text as TextBase } from '@uiKit'
import Skeleton from 'react-loading-skeleton'
import { styled } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 48px;
  background-color: #ffffff;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  padding: 8px;
  margin: 5px;
`

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`

const Image = styled(ClientImage)`
  box-shadow: 2px 2px 4px rgba(0,0,0,0.12);
  min-width: 48px;
`

const Spacer = styled.div`
  flex: 1;
`

const UserWrapperSkeleton = styled(Skeleton)`
  height: 64px;
  border-radius: 48px;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
`

const Text = styled(TextBase)`
  text-overflow: ellipsis;
  overflow: hidden;
`

export const StyledChatSection = {
  Wrapper,
  UserWrapper,
  ActionButtonWrapper,
  Image,
  Spacer,
  UserWrapperSkeleton,
  Text,
}
