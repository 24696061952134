import React, { type FC, useEffect, useState } from 'react'

import { Big5Sliders } from '@component/Big5Sliders'
import { Card } from '@component/Card'
import { Header } from '@component/Header'
import { PopupInner } from '@component/Popup'
import { StyledPopup } from '@component/Popup/styled'
import { useAPI } from '@hooks/useAPI'
import { useRedirect } from '@hooks/useRedirect'
import { getBig5Test } from '@lib/api/rest/big5/get'
import { type GetBig5TestRequest, type GetBig5TestResponse } from '@lib/api/rest/big5/get/types'
import { SPACINGS } from '@lib/theme/constants'
import { ROUTES } from '@router/constants'
import { Button, Divider, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { DividerWidth } from '@uiKit/Divider/types'
import { Images } from '@uiKit/Icon/constants/images'
import { TextAlign, TextSize } from '@uiKit/Text/types'
import { AppLayout } from 'layouts/AppLayout'
import { useTheme } from 'styled-components'

export const Big5Results: FC = () => {
  const {redirect} = useRedirect()
  const [open, setOpen] = useState(false)
  const closeModal = () => { setOpen(false) }
  const theme = useTheme()

  const {
    data,
    isError,
    errorMessage,
  } = useAPI<GetBig5TestRequest, GetBig5TestResponse>(
    {
      apiService: getBig5Test,
    },
    { runOnMount: true }
  )

  useEffect(() => {
    if (isError && errorMessage === 'User did not pass Big 5 test yet.') {
      redirect(ROUTES.BIG5.INFO)
    }
  }, [isError, errorMessage])

  return (
    <AppLayout
      contentStyles={{justifyContent: 'center', paddingBottom: 90}}
      header={
        <Header
          title={'Big 5'}
          showLeftButton
          leftButton={{
            icon: Images.INFO,
            onClick: () => { redirect(ROUTES.BIG5.INFO) }
          }}
          showRightButton
          rightButton={{
            icon: Images.ARROW_RELOAD,
            onClick: () => { setOpen(prev => !prev) }
          }}
        />
      }
    >
      <StyledPopup.Popup
        open={open}
        closeOnDocumentClick
        onClose={closeModal}
        position="right center"
      >
        <PopupInner
          title="Reset the test?"
          closeModal={closeModal}
          onButtonClick={() => { redirect(ROUTES.BIG5.TEST) }}
          showButton
          buttonText="Reset"
        >
          <Spacer space={16} />
          <Text size={TextSize.SUBHEADLINE_15} color={'#5D5D5D'}>
            This action will reset the progress of the test.
          </Text>
          <Spacer space={16} />
        </PopupInner>
      </StyledPopup.Popup>
      <Card showBackground padding={24}>
        <Text
          size={TextSize.TITLE1_28}
          fontWeight={700}
          color={theme.colors.base.main}
          align={TextAlign.CENTER}
        >
          Good job!
        </Text>
        <Spacer space={SPACINGS.X12} />
        <Text size={TextSize.SUBHEADLINE_15} color={'rgba(0, 0, 0, 0.85)'} align={TextAlign.CENTER}>
          You have improved your matching accuracy.
        </Text>
        <Spacer space={SPACINGS.X16} />
        <Divider width={DividerWidth.SMALL} />
        <Spacer space={SPACINGS.X16} />
        <Text size={TextSize.TITLE3_20} fontWeight={600} align={TextAlign.CENTER}>
          Here are your test results
        </Text>
        <Spacer space={SPACINGS.X16} />
        <Big5Sliders
          currentUser={data && {
            big5Traits: data.traits,
            image: data.profile_image_base64,
          }}
        />
        <Spacer space={SPACINGS.X16} />
        <Button
          role="link"
          onClick={() => { redirect(ROUTES.REFLECTION.INFO) }}
          fullWidth
          variant={ButtonVariant.PRIMARY}
        >
          Go to Reflection
        </Button>
      </Card>
    </AppLayout>
  )
}
