import { Text } from '@uiKit'
import { styled } from 'styled-components'

const Button = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonText = styled(Text)`
  margin: auto 0;
  color: ${({theme}) => theme.colors.base.main};
`

const Arrow = styled.div`
  background-color: rgba(92, 157, 255, 0.12);
  padding: 6px;
  border-radius: ${({theme}) => theme.radiuses.lg}px;
  height: 14px;
  width: 14px;
`

export const StyledCollapsingBlock = {
  Button,
  Arrow,
  ButtonText,
}
