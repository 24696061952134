import React, { type FC } from 'react'

import { Icon, Spacer, Text } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { Images } from '@uiKit/Icon/constants/images'
import { IconSize } from '@uiKit/Icon/types'
import { TextSize } from '@uiKit/Text/types'

import { StyledLikeToolbar } from './styled'
import { type LikeToolbarProps } from './types'

export const LikeToolbar: FC<LikeToolbarProps> = ({
    onDislike,
    onLike,
    getToggleProps,
    onCollapse,
    isExpanded,
    dislikeButtonText = 'Skip',
    showCollapseButton = true,
}) => {
  return (
    <StyledLikeToolbar.Wrapper>
      <StyledLikeToolbar.Button
        onClick={onDislike}
        flex="1"
        variant={ButtonVariant.SECONDARY}
        $padding={'0 0 0 48px'}
      >
        <Text size={TextSize.SUBHEADLINE_15} color={'rgba(0, 0, 0, 75%)'}>
          {dislikeButtonText}
        </Text>
      </StyledLikeToolbar.Button>
      {(showCollapseButton && getToggleProps) ? (
        <StyledLikeToolbar.Button
          {...getToggleProps({
            onClick: onCollapse,
          })}
          flex="1"
          variant={ButtonVariant.SECONDARY}
        >
          <Icon
            color={'rgba(0, 0, 0, 75%)'}
            image={isExpanded ? Images.CROSSED_EYE : Images.EYE}
            size={IconSize.X24}
          />
        </StyledLikeToolbar.Button>
      ) : <Spacer space={52} /> }
      <StyledLikeToolbar.Button
        onClick={onLike}
        flex="1"
        variant={ButtonVariant.SECONDARY}
        $padding={'0 48px 0 0'}
      >
        <StyledLikeToolbar.LikeText size={TextSize.SUBHEADLINE_15}>
          Like
        </StyledLikeToolbar.LikeText>
      </StyledLikeToolbar.Button>
    </StyledLikeToolbar.Wrapper>
  )
}
