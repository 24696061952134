import React, { type PropsWithChildren, type FC } from 'react'

import { type HeadingProps } from '@uiKit/Heading/types'

import { StyledTextOVerflow } from './styled'
import { type HeadingOverflowProps } from './types'

export const HeadingOverflow: FC<
  PropsWithChildren<HeadingOverflowProps & HeadingProps>
> = ({ children, width, ...props }) => {
  return (
    <StyledTextOVerflow.Container $width={width} {...props}>
      {children}
    </StyledTextOVerflow.Container>
  )
}
