import React, { type FC, type PropsWithChildren, useCallback } from 'react'

import background_gradient from '@assets/images/background_gradient.png'
import logoRifflectLanding from '@assets/images/logoRifflectLanding.svg'
import { LandingLayout } from '@layouts/LandingLayout/LandingLayout'
import { SPACINGS } from '@lib/theme/constants'
import { SectionIndication } from '@pages/Landing/components/SectionIndication'
import { Flex, Spacer } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { FlexJustifyContent } from '@uiKit/Flex/types'

import { StyledLanding } from './styled'
import { getPageAndSection, LandingPages, type LandingProps } from './types'

export const Landing: FC<PropsWithChildren<LandingProps>> = ({
  children,
  setStep,
  currentStep,
}) => {
  const onStepBack = useCallback(() => {
    setStep((prev: number) => prev - 1)
  }, [])
  const onStepNext = useCallback(() => {
    setStep((prev: number) => prev + 1)
  }, [])
  const onStepFirst = useCallback(() => {
    setStep(LandingPages.MAIN)
  }, [])
  return (
    <LandingLayout
      backgroundImage={background_gradient}
      footer={
      <Flex fullWidth={true} justifyContent={FlexJustifyContent.CENTER}>
        <StyledLanding.ButtonBack
          variant={ButtonVariant.PRIMARY}
          onClick={onStepBack}
          disabled={currentStep <= LandingPages.MAIN}
        >
          Back
        </StyledLanding.ButtonBack>
        <Spacer space={SPACINGS.X16} />
        <StyledLanding.ButtonNext
          variant={ButtonVariant.PRIMARY}
          onClick={currentStep < LandingPages.RESEARCH ? onStepNext : onStepFirst}
        >
          {currentStep < LandingPages.RESEARCH ? 'Next' : 'To Main'}
        </StyledLanding.ButtonNext>
      </Flex>
    }
    >
      <StyledLanding.Header justifyContent={FlexJustifyContent.SPACE_BETWEEN}>
        <StyledLanding.Logo
          src={logoRifflectLanding}
          $center={currentStep === LandingPages.MAIN || currentStep === LandingPages.FULL_RESEARCH}
        />
        <SectionIndication currentSection={getPageAndSection(currentStep)?.section} />
      </StyledLanding.Header>
      {children}
    </LandingLayout>
  )
}
