import { Menu as MenuExternal, MenuButton as MenuButtonExternal } from '@szhsin/react-menu'
import { menuItemSelector, menuSelector, menuDividerSelector } from '@szhsin/react-menu/style-utils'
import { styled } from 'styled-components'

const Menu = styled(MenuExternal)`
  ${menuSelector.name} {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    z-index: 100;
    list-style: none;
    user-select: none;
    padding: 0;
    font-family: sans-serif;
    font-size: 0.925rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    min-width: max-content;
  }
  
  ${menuItemSelector.name} {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-radius: 8px;
    width: auto;
    padding: 8px 16px;
    align-items: center;
  }
  
  ${menuDividerSelector.name}
  {
    height: 1px;
    margin: 4px 0;
    background-color: rgba(0, 0, 0, 0.12);
  }
`

const MenuButton = styled(MenuButtonExternal)`
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 9px;
`

export const StyledAudioCardDropDownMenu = {
  Menu,
  MenuButton
}
