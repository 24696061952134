import { request } from '@http'
import { type APIFunctionOptions } from '@lib/api/types'
import { HTTPMethods } from '@lib/http/constants'
import { type AxiosResponse } from 'axios'

import { type MatchRequest, type MatchResponse } from './types'

export const match = async (
  data?: MatchRequest,
  options?: APIFunctionOptions
): Promise<AxiosResponse<MatchResponse>> => {
  const { baseUrl = '', headers } = options ?? {}

  return await request({
    url: '/api/app/frontend/matching/compare_with_candidate_v2',
    baseUrl,
    method: HTTPMethods.POST,
    data,
    headers,
  })
}
