import React, { type FC } from 'react'

import rifflect from '@assets/icons/rifflect.svg'
import { Logo } from '@component/Logo'
import { Icon } from '@uiKit'
import { IconSize } from '@uiKit/Icon/types'

import { StyledLogoHeader } from './styled'
import { type LogoHeaderProps } from './types'

export const LogoHeader: FC<LogoHeaderProps> = ({
  showSecondButton = false,
  secondButton,
}) => {
  return (
    <StyledLogoHeader.Header $showSecondButton={showSecondButton}>
      <Logo logoPath={rifflect} width={142} height={32} alternativeText={'Rifflect'} />
      {showSecondButton && (
        <StyledLogoHeader.SecondButton
          onClick={secondButton?.onClick}
          label="Second button"
          ref={secondButton?.innerRef}
        >
          <Icon
            aria-hidden={true}
            color={secondButton?.color ?? '#565656'}
            image={secondButton?.icon ?? undefined}
            size={secondButton?.size ?? IconSize.X20}
          />
        </StyledLogoHeader.SecondButton>
      )}
    </StyledLogoHeader.Header>
  )
}
