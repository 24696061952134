import React, { type FC } from 'react'

import { Button, Input, Spacer } from '@uiKit'
import { ButtonVariant } from '@uiKit/Button/types'
import { InputType } from '@uiKit/Input/types'
import { useController } from 'react-hook-form'
import { useTheme } from 'styled-components'

import { type SignInFormProps } from './types'

export const SignInForm: FC<SignInFormProps> = ({ form, onSubmit }) => {
  const theme = useTheme()

  const { field: emailField } = useController({
    name: 'email',
    control: form.control,
  })
  const { field: passwordField } = useController({
    name: 'password',
    control: form.control,
  })

  const errors = form.formState.errors

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Input
        {...emailField}
        type={InputType.EMAIL}
        fullWidth
        placeholder="Email"
        isError={errors?.email !== undefined}
        helperText={errors.email?.message as string}
      />
      <Spacer space={theme.spacings.x16} />
      <Input
        {...passwordField}
        type={InputType.PASSWORD}
        fullWidth
        placeholder="Password"
        isError={errors?.password !== undefined}
        helperText={errors.password?.message as string}
      />
      <Spacer space={theme.spacings.x16} />
      <Button type="submit" fullWidth variant={ButtonVariant.PRIMARY}>
        Sign in
      </Button>
    </form>
  )
}
