import { useCallback, useEffect } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useAPI, useAuthToken, useRedirect } from '@hooks/index'
import { signIn } from '@lib/api'
import {
  type SignInRequest,
  type SignInResponse,
} from '@lib/api/rest/auth/signIn/types'
import { parseJWT } from '@lib/jwt'
import { initialSignInFormValues } from '@pages/SignIn/constants/initialSignInFormValues'
import { validateSignInForm } from '@pages/SignIn/helpers/validateSignInForm'
import { ROUTES } from '@router/constants'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'

export const useSignInForm = () => {
  const { addAccessToken, addRefreshToken } = useAuthToken()
  const { redirect } = useRedirect()

  const form = useForm<{ email: string; password: string }>({
    resolver: yupResolver(validateSignInForm),
    defaultValues: initialSignInFormValues,
    resetOptions: {
      keepErrors: true,
      keepTouched: true,
    },
    mode: 'onBlur',
  })

  const {
    runRequest,
    isError,
    data,
    isLoading
  } = useAPI<SignInRequest, SignInResponse>({
    apiService: signIn,
  })

  const onSubmit = useCallback(
    (values: { email: string; password: string }) => {
      runRequest(values)
    },
    [runRequest]
  )

  useEffect(() => {
    if (!isLoading && !isError && data?.access && data?.refresh) {
      addAccessToken(data.access)
      addRefreshToken(data.refresh)

      Sentry.setUser({
        id: parseJWT(data.access).user_id,
      })

      redirect(ROUTES.PROFILE.ROOT)
    }
  }, [data, isLoading, isError])

  return { form, onSubmit }
}
