import React, {
  type ComponentPropsWithoutRef,
  type ElementType,
  type PropsWithChildren
} from 'react'

import { COLORS } from '@lib/theme/constants'

import { StyledLink } from './styled'
import { LinkDirection, type LinkProps, LinkSize } from './types'

export const Link = <K extends ElementType = 'a'> (
  {
    children,
    as,
    color = COLORS.BASE_COLORS.GREY[750],
    showBorder = true,
    direction = LinkDirection.COLUMN,
    size = LinkSize.DF,
    ...props
  }: LinkProps<K> & PropsWithChildren<ComponentPropsWithoutRef<K>>) => {
  return (
    <StyledLink.Link
      {...props}
      as={as as ElementType}
      $color={color}
      $size={size}
      $direction={direction}
      $showBorder={showBorder}
    >
      {children}
    </StyledLink.Link>
  )
}
