import { type JSX, type HTMLAttributes } from 'react'

export enum InputType {
  EMAIL = 'email',
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
}

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  icon?: JSX.Element
  fullWidth?: boolean
  label?: string
  type?: InputType
  placeholder?: string
  value?: string | number | null
  isError?: boolean
  helperText?: string
  required?: boolean
  maxLength?: number
}
