import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  height: 8px;
  width: 100%;
  background: ${({ theme }) => `
    linear-gradient(
      to right,
      ${theme.colors.base.primary},
      ${theme.colors.base.secondary}
    )`
  };
  margin: 5px 0;
  border-radius: 8px;
`

const Cover = styled.div<{
  $left?: number
  $width?: number
}>`
  position: absolute;
  height: 8px;
  width: ${({ $width }) => ($width && $width)}%;
  background: #B7B7B7;
  border-radius: 8px;
  left: ${({ $left }) => ($left && $left)}%;
`

const Point = styled.img<{
  $left?: number
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: calc(${({ $left }) => ($left && $left)}% - 8px);
  transform: translate3d(0, -50%, 0);
  z-index: 2;
  object-fit: cover;
`

export const StyledBig5Slider = {
  Wrapper,
  Point,
  Cover,
}
