import { styled } from 'styled-components'
import { Swiper as SwiperBase, SwiperSlide as SwiperSlideBase } from 'swiper/react'

const MainCard = styled.div`
  border-radius: 0 0 ${({ theme }) => theme.radiuses.md}px ${({ theme }) => theme.radiuses.md}px;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Block = styled.div`
  position: relative;
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows.evenShadow8};
  padding: 16px;
  background: linear-gradient(
    181.35deg,
    rgba(255, 255, 255, 0.9) 1.15%,
    rgba(255, 255, 255, 0.792)
    98.91%
  );
`

const Emoji = styled.span`
  font-family: 'Golos Text', Arial, sans-serif;
`

const Swiper = styled(SwiperBase)`
  padding: 8px
`

const SwiperSlide = styled(SwiperSlideBase)`
  height: auto;
`

export const StyledMatching = {
  MainCard,
  Block,
  Emoji,
  Swiper,
  SwiperSlide,
}
