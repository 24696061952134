import React, { useState, type FC, useRef } from 'react'

import { AudioCardSkeleton } from '@component/AudioCardSkeleton'
import { CollapsingBlock } from '@component/CollapsingBlock'
import { MusicCard } from '@component/MusicCard'
import { MusicCardList } from '@component/MusicCardList'
import { SectionNav } from '@component/SectionNav'
import { StyledMatching } from '@pages/Matching/styled'
import { Spacer, Text } from '@uiKit'
import { TextSize } from '@uiKit/Text/types'
import { type SwiperRef } from 'swiper/react'

import { type BlockVibeProps } from './types'

export const BlockVibe: FC<BlockVibeProps> = ({
    commonRecommendedTracks,
    commonRecommendedTracksMood,
    isLoading,
}) => {
  const [section, setSection] = useState(0)
  const swiperRef = useRef<SwiperRef>(null)
  const onStepChange = (step: number) => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(step)
    }
  }
  return (
    <StyledMatching.Block>
      <CollapsingBlock headingText={'Vibe & listen together'} blockName={'vibe'}>
        <Spacer space={16} />
        <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
          Reveal suggestions for listening together
        </Text>
        <Spacer space={8} />
        <SectionNav
          section={section}
          onStepChange={onStepChange}
          buttonsData={[
            { label: 'Character', index: 0 },
            { label: 'Mood', index: 1 },
          ]}
        />
        <Spacer space={8} />
        <StyledMatching.Swiper
          spaceBetween={16}
          ref={swiperRef}
          onSlideChange={(slider) => { setSection(slider.activeIndex) }}
        >
          <StyledMatching.SwiperSlide>
            {isLoading ? (
              <AudioCardSkeleton count={5} spacing={16} />
            ) : commonRecommendedTracks && commonRecommendedTracks.length > 0 ? (
              <MusicCardList>
                {commonRecommendedTracks?.map(
                  ({
                    track: {
                      artist_name: author,
                      name,
                      spotify_preview_link: src,
                      spotify_id: id,
                      spotify_label64_link: musicImageSrc,
                      spotify_link: spotifyLink,
                    },
                  }) => (
                    <MusicCard
                      key={id}
                      musicImageSrc={musicImageSrc}
                      title={name}
                      author={author}
                      src={src}
                      spotifyLink={spotifyLink}
                      spotifyId={id}
                    />
                  )
                )}
              </MusicCardList>
            ) : (
              <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
                <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
                Both you and your partner need to have 5 Character riffs to see this section.
              </Text>
            )}
          </StyledMatching.SwiperSlide>
          <StyledMatching.SwiperSlide>
            {isLoading ? (
              <AudioCardSkeleton count={3} spacing={16} />
            ) : commonRecommendedTracksMood && commonRecommendedTracksMood.length > 0 ? (
              <MusicCardList>
                {commonRecommendedTracksMood?.map(
                  ({
                    track: {
                      artist_name: author,
                      name,
                      spotify_preview_link: src,
                      spotify_id: id,
                      spotify_label64_link: musicImageSrc,
                      spotify_link: spotifyLink,
                    },
                  }) => (
                    <MusicCard
                      key={id}
                      musicImageSrc={musicImageSrc}
                      title={name}
                      author={author}
                      src={src}
                      spotifyLink={spotifyLink}
                      spotifyId={id}
                    />
                  )
                )}
              </MusicCardList>
            ) : (
              <Text color={'rgba(0, 0, 0, 0.85)'} size={TextSize.FOOTNOTE_13}>
                <StyledMatching.Emoji>⚠️</StyledMatching.Emoji>
                Both you and your partner need to have 3 Mood riffs to see this section.
              </Text>
            )}
          </StyledMatching.SwiperSlide>
        </StyledMatching.Swiper>
      </CollapsingBlock>
    </StyledMatching.Block>
  )
}
